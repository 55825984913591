<template>
    <b-modal size="lg" id="serviceProvidersModal" hide-footer title="Manage Service Providers" style="height: 100%;">
            <Table
                v-if="show_table"
                :columns="columns"
                :uid="table_uid"
                :ref="table_uid"
                :data="serviceProviders"
                :table_mode="table_view_mode"
                :is_loading="is_loading"
                :context_menu_items="['View', 'Edit', 'Delete']"
                prevent_first_row_select
                :validation="table_validation_rules"
                @saveTable="saveTable"
                @updateViewMode="updateViewMode"
                @onDataUpdate="onChangesMade"
                @deleteRows="deleteRow"
                />
    </b-modal>
</template>

<script>
import Swal from 'sweetalert2'
import Table from '@/components/widgets/table/table.vue';
import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods } from '@/state/helpers';
export default {
    data:() => ({
        show_table: false,
        table_view_mode: 'view',
        is_loading: false,
        delete_array:[],
        changes_made_flag: false,
        table_uid: 'serviceProvidersTable',
        service_provider_names: [],
        used_service_provider_options: [],
        search_text: '',
    }),
    watch:{
        search_text(text){
            window.webix.$$('serviceProvidersTable').filter("#name#", text);
        }
    },
    components:{
        Table,
    },
    computed:{
        ...levelComputed,
        ...activityComputed,
        ...profileComputed,
        serviceProviders(){
            if(this.teams.client_service_providers){
                return this.teams.client_service_providers;
            }
            else{
                return [];
            }
        },
        columns(){
            //var el = this;
            let columns = [
                { id: 'provider_no', header: 'Provider Number', editor: 'text', fillspace: 1,
                    template:function(obj){
                    if(obj.provider_no == -1 || obj.provider_no == undefined){
                        return ''
                    } else {
                        return obj.provider_no
                    }
                    } 
                },
                { id: 'name', header: 'Name', fillspace: 1, editor: 'text' },
                { id: 'type', header: 'Product/Service', fillspace: 1, editor: 'text' },
                { id: 'contact_person', header: 'Contact Person', fillspace: 1, editor: 'text' },
                { id: 'contact_no', header: 'Number', fillspace: 1, editor: 'text' },
                { id: 'email', header: 'Email', fillspace: 1, editor: 'text' },
            ]
            return columns;
        },
        table_validation_rules(){
            let el = this
            return {
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                                let data = window.webix.$$(el.table_uid).serialize(null, true);
                                let value = false
                                data.forEach(row => {
                                    console.log(obj.name)
                                    if(row.name == obj.name && row != obj) {
                                        console.log("HITTING HERE")
                                        value = true
                                    }
                                })
                                if(value) {
                                    return false
                                } else {
                                    return true
                                }
                             }
                            
                        //}
                    }
                },
                "type": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "contact_person": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "contact_no": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            if(!obj.contact_no.match(/[a-z]/i)) {
                                return true;
                            } else {
                                return false
                            }
                           
                        }
                    }
                },
                "email": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false 
                        }
                        else{
                            if(el.validateEmail(obj.email)) {
                                return true;
                            } else {
                                return false
                            }
                        }
                    }
                }
            };
        },
    },
    methods:{
        ...levelMethods,
        ...activityMethods,
        ...profileMethods,
        validateEmail(email) {
           return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        updateViewMode(type){
            this.table_view_mode = type;
        },
        saveTable(){
            window.webix.$$(this.table_uid).editStop();
            this.$nextTick(()=>{
                let data = window.webix.$$(this.table_uid).serialize(null, true);
                let new_rows = data.filter( item => !item.is_empty);
                new_rows.forEach(element => {
                    if(element.is_new){
                        element.id = -1;
                    }
                });

                

                let service_providers = new_rows.map( row => {
                    console.log(JSON.parse(JSON.stringify(row)));
                    if(row.provider_no == undefined) {
                        row.provider_no = 1
                    }
                    return { id: row.is_new ? 0 : row.id, name: row.name, type: row.type, email: row.email, contact_person: row.contact_person, contact_no: row.contact_no, provider_no: row.provider_no }
                })
                let params = {
                    service_providers: service_providers,
                }
                console.log(params)
                this.is_loading = true;
                
                this.saveClientServiceProviders(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.$emit('saved');
                        this.check_names();
                        this.$refs[this.table_uid].table_mode = 'view'
                    })
                })
            })
        },
        showDeletePrompt(){
            Swal.fire({
                    title: "Delete selected Units?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                    console.log(result)
                    if (result.value) {
                        console.log("running here")
                        //* If user selects yes
                        this.deleteRow()
                    }
                });
        },
        deleteRow(data){
            let promises = [];
            let used = false;
            this.teams.service_providers.forEach(mainItem => {
                    data.forEach( item => {
                        if(Number(mainItem.name) == item.id) {
                           used = true
                        }
                })
               
            })
            if(!used) {
                data.forEach( item => {
                    promises.push(this.deleteClientServiceProviders({id: item.id}));
                })
                Promise.all(promises)
                .then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.$emit('saved');
                        this.check_names();
                        this.$refs[this.table_uid].table_mode = 'view';
                    })
                })
            } else {
                Swal.fire({
                    title: "Service Provider in Use",
                    text: "Unable to delete service provider!",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                })
            }
           
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        check_names() {
            this.service_provider_names = [];
            let data_array = []
            let data = this.teams.client_service_providers;
            if(data) {
                data.forEach(item => {
                    data_array.push(item.name)
                })
                this.service_provider_options = data_array
            }
        },
        used_names() {
            this.service_provider_names = [];
            let data_array = []
            let data = this.teams.service_providers;
            if(data) {
                data.forEach(item => {
                    data_array.push(item.name)
                })
                this.used_service_provider_options = data_array
            }
        }
    },
    mounted(){
        this.check_names();
        this.used_names();
        this.$nextTick(()=>{
            this.show_table = true;
        })
    },
}
</script>

<style>

</style>