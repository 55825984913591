<template>
    <b-modal id="targetsModal" hide-footer :title="modalTitle" style="height: 100%;">
        <template #modal-header>
            <div class="w-100 d-flex">
                <h6>{{modalTitle}}</h6>
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('targetsModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>

            </div>
        </template>
        <div v-show="!show_add_target && !show_add_unit_types">
            <div class="d-flex">
                 <div class="modal-title">
                    Linked Level: <span style="font-weight: bold" class="text-primary">{{objectiveName}}</span>
                 </div>
            </div>
            <div class="d-flex mb-1" style="justify-content: space-between; align-items: center;">
                <div class="d-flex">
                    <div class="modal-title">
                        Selected Target: <span style="font-weight: bold" class="text-primary">{{targetName}}</span>
                    </div>
                </div>
                <b-button @click="show_add_target = !show_add_target" variant="primary" size="sm" class="mt-1 mb-1" style="float: right; white-space: nowrap; height: 24px; justify-content: flex-end;">
                    Add Target
                </b-button>
            </div>
            
            <b-overlay :show="is_loading">
                <!-- <div class="d-flex">
                    <b-form-input v-model.lazy="filter_input" placeholder="Search Targets"></b-form-input>
                    <b-button class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                </div> -->
                <vue-typeahead-bootstrap
                        ref="targetSearch"
                        :data="targetsList"
                        v-model="query"
                        placeholder="Search target"
                        showOnFocus
                        @hit="selectedItemEvent"
                        @input="search"
                        prepend="Targets:"
                    ></vue-typeahead-bootstrap>
                <webix-ui v-if="linkable_targets.length > 0" style="height: 100%;" :config="ui" v-model="level_data" ></webix-ui>
            </b-overlay>
            <div class='d-flex'>
                <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
            </div>
            <b-alert show v-show="is_loading_all_linkable_targets">
                Loading Targets
            </b-alert>
        </div>
        <div v-show="show_add_target && !show_add_unit_types">
            <div class="d-flex mb-1" style="justify-content: space-between;">
                <div class="modal-title">
                    Linked Level: <span style="font-weight: bold" class="text-primary">{{objectiveName}}</span>
                 </div>
                 <div>
                    <b-button :disabled="initialTargets.length == 0 ? true : false" @click="show_add_target = !show_add_target" variant="primary" size="sm" class="mt-1" style="float: right; white-space: nowrap; height: 24px; justify-content: flex-end;">
                       View Targets
                    </b-button>
                 </div>
                
            </div>
            
            <div class="mt-0">
                <div>
                    <div>
                        <b-form @submit="onSubmit">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm p-0">
                                    <b-form-group class="label" id="input-group-1" label="Graph Name:" label-for="input-1" size="sm">
                                        <b-form-input
                                        id="input-1"
                                        size="sm"
                                        v-model="form.graph_name"
                                        placeholder="Enter Graph Name"
                                        required
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                
                            </div>
                        </div>

                        <div class="container">
                            <div class="row">
                                <div class="col-sm p-0">
                                    <b-form-group id="input-group-2" label="User:" label-for="input-2" size="sm" >
                                        <vue-typeahead-bootstrap
                                            ref="userSearch"
                                            :data="users"
                                            size="sm"
                                            v-model="owner_query"
                                            placeholder="Search name/email"
                                            @hit="selectedOwner"
                                            showOnFocus
                                        ></vue-typeahead-bootstrap>
                                        
                                    </b-form-group>
                                </div>
                                <div class="col-sm p-0" style="margin-left: 4px;">
                                    <b-form-group id="input-group-3" label="Unit type:" label-for="input-3" size="sm" >
                                        <vue-typeahead-bootstrap
                                            ref="unitTypeSearch"
                                            :data="unitTypes"
                                            size="sm"
                                            v-model="unit_query"
                                            placeholder="Search Unit Types"
                                            @hit="selectedUnitType"
                                            showOnFocus
                                        ></vue-typeahead-bootstrap>
                                        
                                    </b-form-group>
                                    <b-button @click="show_add_unit_types = !show_add_unit_types" variant="primary" size="sm" class="mt-1" style="float: right; white-space: nowrap; height: 24px; justify-content: flex-end;">
                                            Add Unit Types
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <b-button type="submit" class='ms-auto mt-2' style="float: right" variant='success'>Add New Target</b-button>
                        </b-form>
                        
                    </div>
                </div>
           </div>
        </div>
        <div v-show="show_add_unit_types">
            <b-button :disabled="keyResultUnit.length == 0 ? true : false" @click="show_add_unit_types = !show_add_unit_types" variant="primary" size="sm" class="mt-1" style="white-space: nowrap; height: 24px; justify-content: flex-end;">
                Back to add Target
            </b-button>
            <Table
                v-if="show_table"
                :columns="columns"
                :uid="table_uid"
                :ref="table_uid"
                :data="units"
                :table_mode="table_view_mode"
                :is_loading="is_loading_table"
                :context_menu_items="['View', 'Edit', 'Delete']"
                prevent_first_row_select
                @saveTable="saveTable"
                @updateViewMode="updateViewMode"
                @onDataUpdate="onChangesMade"
                @deleteRows="deleteRow"
                />         
        </div>
        <HierarchyModal @updatedLevels="updateLevelIds" :current_row="modal_payload" v-if="show_hierarchy_modal" />
    </b-modal>
</template>

<script>
//eslint-disable-next-line

import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods } from '@/state/helpers'
import { cloneDeep, debounce } from 'lodash';
import Swal from 'sweetalert2'
import Table from '@/components/widgets/table/table.vue';
import HierarchyModal from '@/components/widgets/linkingModals/levels.vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import _ from 'lodash'
export default {
components: {
    VueTypeaheadBootstrap,
    HierarchyModal,
    Table
},
props:{
    payload:{
        required: true,
    },
    // not_objective:{
    //     type: Boolean,
    //     default: false,
    // }
},
data:() => ({
    show_table: false,
    table_view_mode: 'view',
    is_loading: false,
    is_loading_table: false,
    delete_array:[],
    changes_made_flag: false,
    table_uid: 'unitTypesTable',
    targetsList: [],
    openTargetsList: [],
    initialTargets: [],
    is_loading_all_linkable_targets: false,
    linkable_targets: [],
    show_all_linkable_targets: false,
    filter_input: '',
    combined_targets_array: [],
    users: [],
    unitTypes: [],
    query: '',
    unit_query: '',
    owner_query: '',
    selectedTargetId: null,
    targetName: '',
    objectiveName: '',
    show_hierarchy_modal: false,
    show_add_target: false,
    show_add_unit_types: false,
    form: {
        graph_name: '',
        owner_user_id: 0,
        unit_type_id: 0,
        graph_type: 'target'
    },
    assigned_level_id: -1,
    level_ids: [],
    level_to_load: 0,
}),
watch:{
    show_all_linkable_targets(){
        //this.is_loading = true;
        this.linkable_targets = [];
        this.initModal();
    },
    show_add_unit_types() {
        if(this.show_add_unit_types) {
            this.$nextTick(()=>{
            this.show_table = true;
            })
        }
    },
    show_add_target() {
        if(this.show_add_target == false && this.initialTargets.length == 0) {
            this.is_loading = true
        //this.combined_targets_array = []
        this.getKeyResultUnitAPI().then(()=> {
            this.unitTypesList()
        })
        
        console.log(JSON.parse(JSON.stringify(this.payload)))

        let level = _.find(this.levels, { id: Number(this.payload.level_id) });
        this.objectiveName = level.name
        console.log(this.payload.target_graph_name)
        this.targetName = this.payload.target_graph_name
        this.initModal();
        this.setTreeData();
        setTimeout(()=> {
        
            if(this.payload.target_graph_id) {
                this.selectedTargetId = `${this.payload.level_id}|target-${this.payload.target_graph_id}`
            } else {
                window.webix.$$('levelsTargetsTree').open(this.selected_level.id)
                this.is_loading = false
            }   
        }, 1500)
        }
    },
    filter_input(newValue){
        let tree = window.webix.$$('levelsTargetsTree');
        if(tree){
            tree.filter("#name#", newValue);
        }
    },
    selectedTargetId:{
        //immediate: true,
        handler(newValue, oldValue){
            console.log(this.selectedTargetId)
            if(this.selectedTargetId != null){
                let mainTree = window.webix.$$('levelsTargetsTree');
                if(mainTree){//* will be und
                    if(mainTree.exists(newValue)){
                        mainTree.select(newValue);
                        mainTree.addCss(newValue, 'selected_style');
                    }
                }
                if(oldValue && mainTree.exists(oldValue)){
                    mainTree.removeCss(oldValue, 'selected_style')
                }
            }
        }
    },
    level_to_load:{
        //immediate: true,
        handler(){
            this.loadPerformanceTargets(this.level_to_load).then(()=>{
                this.buildTree();
                this.populateTargetList()
                this.show_add_target = false;
            })
        }
    },
    searchItems:{
        //immediate: true,
        handler(){
            if(this.searchItems != undefined) {
                let searchableTargets = []
                this.searchItems.forEach(item => {
                    searchableTargets.push(item.name)
                })
                console.log(searchableTargets)
                this.targetsList = searchableTargets
            }
        }
    }
    
},
computed:{
    ...levelComputed,
    ...activityComputed,
    ...profileComputed,
    units(){
            console.log(this.keyResultUnit);
            if(this.keyResultUnit){
                let data = [];
                this.keyResultUnit.forEach( (elem) => { 
                    data.push({ 'id': elem.id, 'name': elem.name })
                });
                return data;
            }
            else{
                return [];
            }
    },
    columns(){
        //var el = this;
        let columns = [
            { id: 'name', header: 'Unit Type', editor: 'text', fillspace: 1}
        ]
        return columns;
    },
    ui(){
            let el = this;
            return{
                id: 'levelsTargetsTree',
                view: 'tree',
                css: 'levelTree',
                scroll: true,
                drag: false,
                select: true,
                // //data: this.level_data,
                template: (obj, common) => {
                    let id = obj.id
                    if(isNaN(id)) {
                        return common.icon(obj, common) + `<span class="badge-item badge-item-target mr-1"></span><span class="normal-text tree_styles"> ${obj.name}</span>`;
                    } else {
                        return common.icon(obj, common) + `<span class="normal-text tree_styles">${obj.name}</span>`;
                    }
                    
   
                },
                //maxHeight: 500,
                filterMode:{
                    showSubItems:false,
                },
                ready(){
                        let targets_array = cloneDeep(el.targets);
                        let combined_targets = []
                        targets_array.forEach(( target )=>{ 
                            let branch_id = target.level_id
                            if(this.exists(branch_id)){
                                let mutated_target = cloneDeep(target);
                                mutated_target.id = `${branch_id}|target-${mutated_target.id}`
                                console.log(mutated_target.id);
                                combined_targets.push(target)
                                this.add(mutated_target, 0,branch_id);
                            }
                        })
                        if(el.combined_targets_array.length == 0) {
                            let value = el.combined_targets_array
                            el.combined_targets_array = value.concat(combined_targets);
                            console.log(el.combined_targets_array)
                        }
                        //el.populateTargetList()
                },
                on:{
                    onItemClick(id){
                        //let el = this.$scope.$parent;
                        if(id.includes('target')) {
                            console.log(id);
                            let name = ''
                            let mainId = id.split('|')[1];
                            let level_id = id.split('|')[0];
                            let target_id = mainId.split('-')[1]
                            console.log(target_id);
                            //set to combined array
                            el.combined_targets_array.forEach(item => {
                                if(item.id == target_id) {
                                    name = item.name
                                }
                            })
                            console.log(name)
                            el.targetName = name
                            el.selectedTargetId = `${level_id}|target-${target_id}`
                            //el.clickItemEvent(name)
                        }
                    },
                    onBeforeOpen(id) {
                        let num = Number(id)
                        if(num != isNaN) {
                           console.log(id)
                           el.level_to_load = Number(id)
                        }
                    }
                },
            }
        },
    modalTitle(){
        let title = ''
        if(this.show_add_unit_types) {
            title = `Add Unit Types`;
        } else if(this.show_add_target){
            title = `Add Target Graph`;
        } else {
            title = `Update Selected Target`; 
        }
        return title;
    },
},
methods:{
    ...activityMethods,
    ...profileMethods,
    ...levelMethods,
    updateViewMode(type){
            this.table_view_mode = type;
    },
    saveTable(){
        window.webix.$$(this.table_uid).editStop();
        this.$nextTick(()=>{
            let data = window.webix.$$(this.table_uid).serialize(null, true);
            let new_rows = data.filter( item => !item.is_empty);
            new_rows.forEach(element => {
                if(element.is_new){
                    element.id = -1;
                }
            });

            let units = new_rows.map( row => {
                return { id: row.id, unit: row.name }
            })
            let params = {
                units: units,
            }
            console.log(params)
            this.is_loading = true;
            this.saveUnitTypes(params).then(()=>{
                this.getKeyResultUnitAPI()
                .then(()=>{
                    this.is_loading = false;
                    this.unitTypesList()
                    this.$refs[this.table_uid].table_mode = 'view';
                })
            })
        })
    },
    showDeletePrompt(){
        Swal.fire({
                title: "Delete selected Units?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
    },
    deleteRow(data){
        let params = [];
        this.is_loading = true;
        data.forEach( item => { params.push(item.id)})
        this.deleteUnitTypes(params)
        .then( async ()=>{
            this.getKeyResultUnitAPI()
            .then(()=>{
                this.is_loading = false;
                this.table_view_mode = 'view'
                this.unitTypesList()
                this.$refs[this.table_uid].table_mode = 'view';
            })
        })
        .catch( async (error) => {
            console.log('ERROR in ux',error)
            const keys = Object.keys(error);
            for(let i = 0; i< keys.length; i++){
                let id = keys[i]; // id used as object's key
                let unit = this.units.find( u => u.id == id);
                if(unit){
                    switch(error[id]){
                        case 'has_kpis':{
                            Swal.fire(`Cant delete '${unit.name}', it is linked to one or more Key Performance Indicators`);
                            break;
                        }
                        case 'has_operational_metrics':{
                            Swal.fire(`Cant delete '${unit.name}', it is linked to one or more Operational Metrics`);
                            break;
                        }
                        case 'has_key_results':{
                            Swal.fire(`Cant delete '${unit.name}', it is linked to one or more Operational Metrics`);
                            break;
                        }
                    }
                }
            }
        })
    },
    onChangesMade(value){
        //* update flag when the user makes changes to the datatable
        this.changes_made_flag = value;
    },
    onDeleteArrayUpdate(array){
        //* update array of items that are selected for delete
        this.delete_array = array;
    },
    search: debounce(function() {
        if(this.query != '') {
            this.searchItem({type: 'target', search_name: this.query}).then(()=>{})
        }
      }, 500),
    selectedOwner(item) {
        let email = item.split('|')[1];
        let email_string = email.trim();
        let user = this.all_users.find( item => {
            return item.email == email_string
        });
        this.form.user = user.id
    },
    selectedUnitType(item) {
        let id = 0
        this.keyResultUnit.forEach( unit =>{
            if(unit.name == item) {
               id = unit.id
            }
        });
        this.form.unit_type_id = id
    },
    onSubmit(event) {
        event.preventDefault()
        let data = [ this.form ]
        let rows_to_save = [];
            data.forEach(item => {
                console.log(JSON.parse(JSON.stringify(item)))
                let params = {
                    level_id: this.payload.level_id,
                    graph_name: item.graph_name,
                    unit_type_id: item.unit_type_id,
                    owner_user_id: Number(item.user),
                    graph_type: item.graph_type
                };
                rows_to_save.push(params);
            });

            let save_params = {
                rows_to_save
            }
            console.log(save_params)
            this.createGraph(rows_to_save[0]).then(() => {
                this.loadPerformanceTargets(this.selected_level.id).then(()=>{
                    this.show_add_target = !this.show_add_target;
                    this.buildTree();
                })
                
            })
    },
    setTreeData(){
        let temp_level_data = _.cloneDeep(this.levels);
        //temp_level_data = _.orderBy(temp_level_data, ['item_order']);
        this.level_data = this.processLevels(temp_level_data);
    },
    processLevels(levels){
            this.show = false;
            var flat_array = levels != null ? _.cloneDeep(levels) : [];
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            if(flat_array.length == 0){
                this.show = true;
                return [];
            }
            pre_processed_array = _.uniqBy(flat_array, 'id');
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = item;
                // give element the required fields
                new_item["parent"] = new_item.parent_id; //used for webix related stuff
                new_item["value"] = new_item.name; //used for webix related stuff
                new_item['data'] = []; // array of children for the node
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            this.show = true;
            return nestedArray;
    },
    processTree(data_array){
        console.log(data_array);
        var data = _.cloneDeep(data_array);
        console.log('CHECK ME DATA', data)
        data.forEach((element) => {
                  // give element the required fields
                  element["parent"] = element.$parent; //used for webix related stuff
                  element["value"] = element.name; //used for webix related stuff
                  element["data"] = []; // array of children for the node
                  element['people'] = this.levelEmployeesData

                  if (element['mainID']) {
                    element['mainID'] = null;
                  }

                  if (element.id == this.current_level_id) {
                    element['mainID'] = this.current_level_id;
                  }

                //   if (element.name == this.payload.objective_plan_name) {
                //     element['selected'] = true
                //   } else {
                //     element['selected'] = false
                //   }
              });
              data.forEach((ele) => {   
                  let parent_id = ele.parent_id;
                  console.log(parent_id);
                  if (parent_id == 0) {
                      //* Parent item -- do nothing
                  }
                  else {
                      //* If element is a child element, push to children array.
                      data.forEach((d) => {
                        // console.log(d.parent_id)
                          if (d.id === parent_id) {
                              let childArray = d.data;
                              if (!childArray) {
                                  childArray = [];
                              }
                              childArray.push(ele);
                              d.children = childArray;
                          }
                      });
                  }
              });
        this.linkable_targets = data;
    },
    populateTargetList(){
        let targetsList = [];
        if(this.combined_targets_array.length > 0) {
            this.combined_targets_array.forEach( target =>{
               targetsList.push(`${target.name}`)
            });
        } else {
            this.targets.forEach( target =>{
               targetsList.push(`${target.name}`)
            });
        }

        this.openTargetsList = targetsList;
    },
    populateList(){
        let users = [];
        this.all_users.forEach( user =>{
            users.push(`${user.name} | ${user.email}`)
        });
        this.users = users;
    },
    unitTypesList() {
        let unitTypes = [];
        this.keyResultUnit.forEach( unit =>{
            unitTypes.push(`${unit.name}`)
        });
        this.unitTypes = unitTypes;
    },
    selectedItemEvent(item){
        
        let targetLevelId = null;
        let targetId = null;
        //let value = []
        if(this.combined_targets_array.length > 0) {
            console.log(1)
            this.combined_targets_array.forEach( target =>{
                if(target.name == item) {
                    targetLevelId = target.level_id
                    targetId = target.id
                    this.targetName = target.name
                }
            });
        } else {
            console.log(2)
            this.searchItems.forEach( target =>{
                if(target.name == item) {
                    targetLevelId = target.level_id
                    targetId = target.id
                    this.targetName = target.name
                }
            });
        }
        console.log(targetId)
        console.log(item)
        if(targetId == null && item != 'No Target Selected'){
            
            let mainTarget = null
            this.searchItems.forEach((target) => { 
                console.log(target.name);
                console.log(item)
                if(target.name == item) {
                    console.log(target)
                    mainTarget = target
                }
                    
            })
            console.log("@@@@");
            this.loadPerformanceTargets(mainTarget.level_id).then(()=>{
                this.buildTree();
                this.populateTargetList()
                this.show_add_target = false;
                let matches = [];
                let targetLevelId = mainTarget.level_id;
                let targetId = mainTarget.id;
                this.targetName = mainTarget.name
                matches.push(`${targetLevelId}|target-${targetId}`);
                if(matches.length > 0){
                    let tree = window.webix.$$('levelsTargetsTree');
                        matches.forEach( match => {
                            if(tree.exists(match)) {
                                let promises = [ this.loadDataFromParents(matches) ];
                                Promise.all(promises)
                                .then(()=>{
                                    setTimeout(() => {
                                        this.openTreeNode(matches, true);
                                        this.resetTypeahead();
                                    }, 1500) 
                                    
                                })
                            }
                        })
                    
                }
                this.selectedTargetId = `${targetLevelId}|target-${targetId}`
            })
        } else {
            this.is_loading = true
            console.log('getting in here 3')
            let matches = [];
            matches.push(`${targetLevelId}|target-${targetId}`);
            if(matches.length > 0){
                let tree = window.webix.$$('levelsTargetsTree');
                    matches.forEach( match => {
                        if(tree.exists(match)) { 
                            let promises = [ this.loadDataFromParents(matches) ];
                                Promise.all(promises)
                                .then(()=>{
                                    
                                    setTimeout(() => {
                                        this.openTreeNode(matches, true);
                                        this.resetTypeahead();
                                    }, 1500) 
                                    
                                })                   
                            // this.openTreeNode(matches, false);
                            // this.resetTypeahead();
                        }
                    })
                
            }
            this.selectedTargetId = `${targetLevelId}|target-${targetId}`     
        }
    },
    loadDataFromParents(id_array) {
        let tree = window.webix.$$('levelsTargetsTree');
        let data = []
        if(tree){
            let item = tree.getItem(id_array[0]);
            while(item.$parent != 0){
                data.push(item.$parent);
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
        }
        console.log(data)
        data.forEach(itemID => {
            this.loadPerformanceTargets(itemID).then(()=>{
                this.buildTree();
                this.populateTargetList()
                this.show_add_target = false;
            })
        })
    },
    openTreeNode(id_array, searched){
        let tree = window.webix.$$('levelsTargetsTree');
        if(tree){
            let item = tree.getItem(id_array[0]);
            tree.closeAll()
            while(item.$parent != 0){
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
            this.is_loading = false
            if(searched) {
                tree.showItem(id_array[0]);
            }
        }
    },
    resetTypeahead(){
        this.$nextTick(()=>{
            this.query = '';
            let input = this.$refs['targetSearch'].$refs['input'];
            input.blur();
        })
    },
    initModal(){
        let values = this.targets;
        if(values != undefined) {
            console.log("PROCESSING")
            //this.buildTree()
            this.populateList();
            this.populateTargetList()
            this.processTree(values);
            
            
        }
        setTimeout(() => {
            this.selectedItemEvent(this.payload.target_graph_name);
        }, 500)
    },
    save() {
        this.$bvModal.hide('targetsModal');
        let mainId = this.selectedTargetId.split('|')[1];
        let target_id = mainId.split('-')[1];
        let level_id = this.selectedTargetId.split('|')[0];
        let item = { target_id: target_id, level_id: level_id}
        
        this.$emit('selectedNewID', item);
    },
    addTarget() {
        this.show_add_target
    },
    buildTree() {
        let targets_array = cloneDeep(this.targets)
        let combined_targets = []
        targets_array.forEach(( target )=>{ 
            let branch_id = target.level_id
            if(!window.webix.$$('levelsTargetsTree').exists(`${branch_id}|target-${target.id}`)){
                let mutated_target = cloneDeep(target);
                mutated_target.id = `${branch_id}|target-${mutated_target.id}`
                combined_targets.push(target)
                window.webix.$$('levelsTargetsTree').add(mutated_target, 0,branch_id);
                window.webix.$$('levelsTargetsTree').refresh()
            }
        })
        let value = this.combined_targets_array
        this.combined_targets_array = value.concat(combined_targets);
    }
},
beforeDestroy() {
    this.loadPerformanceTargets(this.selected_level.id).then(()=>{
        this.getKeyResultUnitAPI().then(()=> {
        })
    })
},
mounted(){
    this.is_loading = true
    let value = this.targets
    this.initialTargets = value
    //this.combined_targets_array = []
    this.getKeyResultUnitAPI().then(()=> {
        this.unitTypesList()
    })

    let level = _.find(this.levels, { id: Number(this.payload.level_id) });
    this.objectiveName = level.name
    console.log(this.payload.target_graph_name)
    this.targetName = this.payload.target_graph_name
    this.initModal();
    this.setTreeData();
    setTimeout(()=> {
        
       
        if(this.payload.target_graph_id) {
            this.selectedTargetId = `${this.payload.level_id}|target-${this.payload.target_graph_id}`
        } else {
            if(this.keyResultUnit.length == 0) {
                this.$swal.fire({
                    title: "Please add unit types before adding targets",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                })
               this.show_add_unit_types = true
            }
            if(this.targets.length == 0) {
                this.show_add_target = true
            }
            window.webix.$$('levelsTargetsTree').open(this.selected_level.id)
            this.is_loading = false
        }
       
        
    }, 1500)
    

},
}
</script>
<style>
.hierarchyModalAlert{
    padding: 0.4rem;
}
.hierarchyModalAlert .close{
        padding: 0;
        font-size: 20px;
    }
.webixModal .webix_selected{
    background-color: #daecff !important;
    text-decoration: underline black ;
    color:dodgerblue;
    font-weight: bold;
}
/* .show_objective_modal{
    cursor: pointer;
    color: transparent;
} */
.selected_style  {
    border: 2px black;
    font-weight: bold;
}
.group_style {
    width: 100%;
    word-wrap: normal;
    word-break: break-word;
    white-space: normal;
    display: block;
    height: auto;
    text-indent: -5.4%;
    margin-left: 1.4em;
    margin-left: 0 auto
}

.levelTree {
    max-height: 300px
}

.group_style:hover {
    color:dodgerblue;
}

</style>