<template>
    <b-modal id="systemsModal" hide-footer :title="modalTitle" size="lg" style="height: 100%;">
        <template #modal-header>
            <div class="w-100 d-flex">
                <h6>{{modalTitle}}</h6>
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('systemsModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>

            </div>
        </template>
        <div v-show="!show_add_system">
            <div class="d-flex">
                 <div class="modal-title">
                    Linked Level: <span style="font-weight: bold" class="text-primary">{{objectiveName}}</span>
                 </div>
            </div>
            <div class="d-flex mb-1" style="justify-content: space-between; align-items: center;">
                <div class="d-flex">
                    <div class="modal-title">
                        Selected Systems: <span style="font-weight: bold" class="text-primary">{{systemName}}</span>
                    </div>     
                </div>
                <b-button @click="show_add_system = !show_add_system" variant="primary" size="sm" class="mt-1 mb-1" style="float: right; white-space: nowrap; height: 24px; justify-content: flex-end;">
                       Add System
                </b-button>
            </div>
            
            <b-overlay :show="is_loading">
                <vue-typeahead-bootstrap
                        ref="systemSearch"
                        :data="systemsList"
                        v-model="query"
                        placeholder="Search system"
                        showOnFocus
                        @hit="selectedItemEvent"
                        prepend="Systems:"
                    ></vue-typeahead-bootstrap>
                <webix-ui v-if="linkable_systems.length > 0" style="height: 100%;" :config="ui" v-model="level_data" ></webix-ui>
            </b-overlay>
            <div class='d-flex'>
                <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
            </div>
            <b-alert show v-show="is_loading_all_linkable_systems">
                Loading Systems
            </b-alert>
        </div>
        <div v-show="show_add_system">
            <b-button :disabled="teams.systems.length == 0 ? true : false" @click="show_add_system = !show_add_system" variant="primary" size="sm" class="mt-1" style="white-space: nowrap; height: 24px; justify-content: flex-end;">
                View Systems
            </b-button>
            <Table
                v-if="show_table"
                :columns="columns"
                :uid="table_uid"
                :ref="table_uid"
                :data="systems_data"
                :is_loading="is_loading"
                :pager_config=10
                :default_values="default_values"
                @saveTable="saveTable"
                :validation="table_validation_rules"
                @updateViewMode="updateViewMode"
                @deleteRows="deleteRow"
                :edit_permissions="{manager:'assets-systems'}"
                :required_permissions="['assets-systems']"
            />
        </div>
    </b-modal>
</template>

<script>
//eslint-disable-next-line

import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods } from '@/state/helpers'
import Table from '@/components/widgets/table/table.vue'
import dateMixin from '@/mixins/dateMixin.js'
import Swal from 'sweetalert2'
import { format, parseISO } from 'date-fns';
import { cloneDeep } from 'lodash';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import _ from 'lodash'
export default {
mixins:[ dateMixin ],
components: {
    VueTypeaheadBootstrap,
    Table
},
props:{
    payload:{
        required: true,
    },
    // not_objective:{
    //     type: Boolean,
    //     default: false,
    // }
},
data:() => ({
    systemsList: [],
    table_uid: 'systems',
    table_view_mode: 'view',
    show_table: false,
    is_loading: false,
    //* active_id should be a computed property that returns the currently selected item from your vuex store
    active_id: -1,
    changes_made_flag: false,
    delete_array:[],
    is_loading_all_linkable_systems: false,
    linkable_systems: [],
    show_all_linkable_systems: false,
    show_add_system: false,
    filter_input: '',
    users: [],
    unitTypes: [],
    query: '',
    unit_query: '',
    owner_query: '',
    selectedSystemId: null,
    systemName: '',
    objectiveName: '',
    show_hierarchy_modal: false,
    form: {
        graph_name: '',
        owner_user_id: 0,
        unit_type_id: 0,
        graph_type: 'system'
    },
    assigned_level_id: -1,
    level_ids: [],
}),
watch:{
    show_all_linkable_systems(){
        //this.is_loading = true;
        this.linkable_systems = [];
        this.initModal();
    },
    show_add_system() {
        if(this.show_add_system) {
            this.$nextTick(()=>{
            this.show_table = true;
            })
        }
    },
    filter_input(newValue){
        let tree = window.webix.$$('treeModal');
        if(tree){
            tree.filter("#name#", newValue);
        }
    },
    selectedSystemId:{
        handler(newValue, oldValue){
            if(this.selectedSystemId != null){
                let tree = window.webix.$$('levelsSystemsTree');
                if(tree){//* will be und
                    if(tree.exists(newValue)){
                        tree.addCss(newValue, 'selected_style');
                    }
                }
                if(oldValue && tree.exists(oldValue)){
                    tree.removeCss(oldValue, 'selected_style')
                }
                /*if(window.webix.$$('userLevelsTree').exists)
                window.webix.$$('userLevelsTree').addCss(newValue, 'selected_tree_item')
                if(oldValue != undefined){
                    window.webix.$$('userLevelsTree').removeCss(oldValue, 'selected_tree_item')
                }*/
            }
        }
    }
    
},
computed:{
    ...levelComputed,
    ...activityComputed,
    ...profileComputed,
    systems_data(){
        if(this.teams.systems == undefined){
            return [];
        }
        else{
            return this.teams.systems;
        }
    },
    default_values(){
        let el = this;
        return [
            { id : "level_id", value: el.selected_level.id },
        ]
    },
    //* table computed methods
    columns(){
        //* get ref of current scope
        var el = this;
        let systems_columns = [
            { id: 'vendor_name', header: 'Vendor Name', fillspace: 1, editor: 'text' },
            { id: 'name', header: 'System Name', fillspace: 1, editor: 'text' },
            { id: 'level_id', header: 'Linked Level', fillspace: 2,
                template:function(obj){
                    if(obj.level_id != undefined){
                        let index = _.findIndex(el.levels, {id: obj.level_id });
                        let level_name = '';
                        if(index != -1){
                            level_name = el.levels[index].name;
                        }
                        //if(el.table_view_mode === 'edit'){
                        //    //* we only need to show the pencil icon in edit mode
                        //    return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                        //}
                        //else{
                            return `<span>${level_name}</span>`
                        //}
                    }
                    else{
                        return ''
                    }
                },
            },
            { id: 'functionality', header: 'Functionality', fillspace: 1, editor: 'text' },
            { id: 'active_date', header: 'Active Date', fillspace: 1, editor: 'date',
                //* example on how to format date without mutating the origional data
                format:function(value){
                    let date = value;
                    if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                        return format(date, 'dd-MMM-yyyy');
                    }
                    else{
                        return '';
                    }
                }
            },
            { id: 'expiry_date', header: 'Expiry Date', fillspace: 1, editor: 'date',
                //* example on how to format date without mutating the origional data
                format:function(value){
                    let date = value;
                    if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                        return format(date, 'dd-MMM-yyyy');
                    }
                    else{
                        return '';
                    }
                }
            },
        ];
        return systems_columns;
    },
    table_validation_rules(){
        let el = this;
        return {
            "vendor_name": function(item, obj){
                if(obj.is_empty){
                    return true;
                }
                else{
                    if(item === '' || item == undefined){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
            },
            "name": function(item, obj){
                if(obj.is_empty){
                    return true;
                }
                else{
                    if(item === '' || item == undefined){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
            },
            "functionality": function(item, obj){
                if(obj.is_empty){
                    return true;
                }
                else{
                    if(item === '' || item == undefined){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
            },
            "active_date": function(item, obj){
                if(obj.is_empty){
                    return true;
                }
                else{
                    if(item == '' || item == undefined){
                        return false;
                    }
                    else{
                        if(obj.active_date && obj.expiry_date){
                            return el.startDateCheck(obj.active_date, obj.expiry_date)
                        }
                        return true;
                    }
                }
            },
            "expiry_date": function(item, obj){
                if(obj.is_empty){
                    return true;
                }
                else{
                    if(item == '' || item == undefined){
                        return false;
                    }
                    else{
                        if(obj.active_date && obj.expiry_date){
                            return el.endDateCheck(obj.expiry_date, obj.active_date)
                        }
                        return true;
                    }
                }
            },
        };
    },
    ui(){
            let el = this;
            return{
                id: 'levelsSystemsTree',
                view: 'tree',
                css: 'levelTree',
                scroll: true,
                drag: false,
                select: true,
                // //data: this.level_data,
                template: (obj, common) => {
                    let id = obj.id
                    if(isNaN(id)) {
                        return common.icon(obj, common) + `<span class="badge-item badge-item-system mr-1"></span><span class="normal-text tree_styles"> ${obj.name}</span>`;
                    } else {
                        return common.icon(obj, common) + `<span class="normal-text tree_styles">${obj.name}</span>`;
                    }  
                },
                //maxHeight: 500,
                filterMode:{
                    showSubItems:false,
                },
                ready(){
                        let systems_array = cloneDeep(el.teams.systems)
                        systems_array.forEach(( system )=>{ 
                            let branch_id = system.level_id
                            if(this.exists(branch_id)){
                                let mutated_system = cloneDeep(system);
                                mutated_system.id = `${branch_id}|system-${mutated_system.id}`
                                console.log(mutated_system.id)
                                this.add(mutated_system, 0,branch_id);
                            }
                        })
                },
                on:{
                    onItemClick(id){
                        //let el = this.$scope.$parent;
                        if(id.includes('system')) {
                            console.log(id);
                            let name = ''
                            let mainId = id.split('|')[1];
                            let system_id = mainId.split('-')[1]
                            console.log(system_id);
                            el.teams.systems.forEach(item => {
                                if(item.id == system_id) {
                                    name = item.name
                                }
                            })
                            console.log(name)
                            el.systemName = name
                            el.selectedItemEvent(name)
                        }
                    },
                },
            }
        },
    modalTitle(){
        let title = `Update Selected Systems`;
        return title;
    },
},
methods:{
    ...activityMethods,
    ...profileMethods,
    ...levelMethods,
    //* table event handlers
    updateViewMode(mode){
        this.table_view_mode = mode;
    },
    selectedRowEvent(row){
        //* set selected row id  in your vuex store
        this.active_id = row.id;
    },
    saveTable(data){
        this.is_loading = true;
        let level_systems = [];
        data.forEach(( row ) =>{
            let save_row = {
                id: row.is_new ? -1 : row.id,
                level_id: row.level_id,
                vendor_name: row.vendor_name,
                name: row.name,
                functionality: row.functionality,
                active_date: row.active_date,
                expiry_date: row.expiry_date,
            }
            level_systems.push(save_row);
        })
        this.saveSystems({ level_systems : level_systems })
        .then(()=>{
            this.loadTeams(this.selected_level.id)
            .then(()=>{
                this.$refs[this.table_uid].table_mode = 'view';
                this.show_add_system = !this.show_add_system
                this.is_loading = false;
                this.initModal();
                this.setTreeData();
            })
        })
        .catch(()=>{
            this.is_loading = false;
        })
    },
    deleteRow(data) {
        let promises = [];
        this.is_loading = true;
        data.forEach( item => {
            promises.push(this.deleteSystems({id: item.id}));
        })
        Promise.all(promises)
        .then(()=>{
            this.loadTeams(this.selected_level.id)
            .then(()=>{
                this.$refs[this.table_uid].table_mode = 'view';
                this.is_loading = false;
            })
        })
        .catch(()=>{
            this.is_loading = false;
        })
    },
    showDiscardPrompt(type){
        //* type is the view mode the user is trying to switch to
        Swal.fire({
            title: "Clear unsaved changes?",
            text: "Warning, changes will be deleted.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.value) {
                //* If user selects yes
                this.table_view_mode = type;
            }
        });
    },
    showDeletePrompt(){
        Swal.fire({
            title: "Delete selected milestones?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                //* If user selects yes
                //* example code (the deleteMilestone() is a method from the activity store)
            }
        });
    },
    setTreeData(){
        let temp_level_data = _.cloneDeep(this.levels);
        //temp_level_data = _.orderBy(temp_level_data, ['item_order']);
        this.level_data = this.processLevels(temp_level_data);
    },
    processLevels(levels){
            this.show = false;
            var flat_array = levels != null ? _.cloneDeep(levels) : [];
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            if(flat_array.length == 0){
                this.show = true;
                return [];
            }
            pre_processed_array = _.uniqBy(flat_array, 'id');
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = item;
                // give element the required fields
                new_item["parent"] = new_item.parent_id; //used for webix related stuff
                new_item["value"] = new_item.name; //used for webix related stuff
                new_item['data'] = []; // array of children for the node
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            this.show = true;
            return nestedArray;
    },
    processTree(data_array){
        console.log(data_array);
        var data = _.cloneDeep(data_array);
        console.log('CHECK ME DATA', data)
        data.forEach((element) => {
                  // give element the required fields
                  element["parent"] = element.$parent; //used for webix related stuff
                  element["value"] = element.name; //used for webix related stuff
                  element["data"] = []; // array of children for the node
                  element['people'] = this.levelEmployeesData

                  if (element['mainID']) {
                    element['mainID'] = null;
                  }

                  if (element.id == this.current_level_id) {
                    element['mainID'] = this.current_level_id;
                  }

                //   if (element.name == this.payload.objective_plan_name) {
                //     element['selected'] = true
                //   } else {
                //     element['selected'] = false
                //   }
              });
              data.forEach((ele) => {   
                  let parent_id = ele.parent_id;
                  console.log(parent_id);
                  if (parent_id == 0) {
                      //* Parent item -- do nothing
                  }
                  else {
                      //* If element is a child element, push to children array.
                      data.forEach((d) => {
                        // console.log(d.parent_id)
                          if (d.id === parent_id) {
                              let childArray = d.data;
                              if (!childArray) {
                                  childArray = [];
                              }
                              childArray.push(ele);
                              d.children = childArray;
                          }
                      });
                  }
              });
        this.linkable_systems = data;
    },
    populateSystemsList(){
        let systemsList = [];
        this.teams.systems.forEach( system =>{
            systemsList.push(`${system.name}`)
        });
        this.systemsList = systemsList;
    },
    selectedItemEvent(item){
        console.log(item)
        
            let systemLevelId = null;
            let systemId = null;
            this.teams.systems.forEach( system =>{
                if(system.name == item) {
                    systemLevelId = system.level_id
                    systemId = system.id
                    this.systemName = system.name
                }
            });
            let matches = [];
            matches.push(`${systemLevelId}|system-${systemId}`);
            if(matches.length > 0){
                console.log(matches)
                let tree = window.webix.$$('levelsSystemsTree');
                    matches.forEach( match => {
                        if(tree.exists(match)) {
                            this.openTreeNode(matches);
                            this.resetTypeahead();
                        }
                    })
                    console.log(matches)
               
            }
            this.selectedSystemId = `${systemLevelId}|system-${systemId}`

            
    },
    openTreeNode(id_array){
        let tree = window.webix.$$('levelsSystemsTree');
        if(tree){
            id_array.forEach( id => {
                let item = tree.getItem(id);
                if(item != undefined){
                    tree.open(item.$parent);
                    item = tree.getItem(item.$parent);
                    console.log(item)
                    
                }
            })
        }
    },
    resetTypeahead(){
        this.$nextTick(()=>{
            this.query = '';
            let input = this.$refs['systemSearch'].$refs['input'];
            input.blur();
        })
    },
    initModal(){
        let values = this.teams.systems;
        if(this.teams.systems != undefined) {
            this.processTree(values);      
        }
        setTimeout(() => {
            this.selectedItemEvent(this.payload.system_name);
        }, 500)
    },
    save() {
        this.$bvModal.hide('systemsModal');
        let mainId = this.selectedSystemId.split('|')[1];
        let system_id = mainId.split('-')[1]
        this.$emit('selectedNewID', system_id);
    },
},
mounted(){
    console.log(this.payload);
    
    let level = _.find(this.levels, { id: Number(this.payload.level_id) });
    this.objectiveName = level.name
    this.systemName = this.payload.system_name
    if(this.teams.systems.length == 0) {
        this.show_add_system = !this.show_add_system
    } else {
        this.initModal();
        this.setTreeData();
    }
    
    setTimeout(() => {
        this.selectedSystemId = `${this.payload.level_id}|system-${this.payload.system_id}`
    }, 1000)
},
}
</script>
<style>
.hierarchyModalAlert{
    padding: 0.4rem;
}
.hierarchyModalAlert .close{
        padding: 0;
        font-size: 20px;
    }
.webixModal .webix_selected{
    /*background-color: #daecff !important;*/
    text-decoration: underline black ;
    color:dodgerblue;
    font-weight: bold;
}
/* .show_objective_modal{
    cursor: pointer;
    color: transparent;
} */
.selected_style  {
    border: 2px black;
    font-weight: bold;
}
.group_style {
    width: 100%;
    word-wrap: normal;
    word-break: break-word;
    white-space: normal;
    display: block;
    height: auto;
    text-indent: -5.4%;
    margin-left: 1.4em;
    margin-left: 0 auto
}

.levelTree {
    max-height: 300px
}

.group_style:hover {
    color:dodgerblue;
}

.webix_popup{
    z-index: 100000 !important;
}

</style>