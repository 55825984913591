<template>
    <b-modal id="linkedObjectiveModal" hide-footer size="md" :title="modalTitle" style="height: 100%;">
        <template #modal-header>
            <div class="w-100 d-flex">
                <h6>{{modalTitle}}</h6>
                <!-- <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                    <span>
                        <input class="form-check-input" style="cursor:pointer;" v-model="show_all_objectives" type="checkbox" id="allObjectivesToggle"/>
                        <label class="form-check-label" for="allObjectivesToggle">
                            Show All Objectives
                        </label>
                    </span>
                </span>
                <font-awesome-icon :class="$hasPerm('Power User') ? '' : 'ms-auto'" @click="$bvModal.hide('linkedObjectiveModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon> -->

                </div>
            </template>
            <div class="modal-title mb-1 d-flex">
                <span style="min-width: 27%;">
                    Selected Objective:
                </span> <span style="font-weight: bold;" class="text-primary">{{payload.name}}</span>
            </div>
            <div class="mb-1 d-flex" v-if="parent_level != null">
                <span style="min-width: 27%;">Parent Level:</span> <span style="font-weight: bold; margin-right: 3px;" class="text-primary">{{parent_level}}</span><b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: rgb(107, 168, 203) !important" class="bg-primary bg-soft text-light">&nbsp;
                </b-badge>&nbsp;
            </div>
            <div class="mb-1 d-flex" v-if="company_level != null">
                <span style="min-width: 27%;">Parent:</span> <span style="font-weight: bold; margin-right: 3px;" class="text-primary">{{company_level}}</span><b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: rgb(107, 168, 203) !important" class="bg-primary bg-soft text-light">&nbsp;
                </b-badge>&nbsp;
            </div>
            <div class="mb-1 d-flex" v-if="child_Level != null">
                <span style="min-width: 27%;">
                    Selected Level:
                </span>
                <span>
                     <span style="font-weight: bold; margin-right: 3px;" class="text-primary">{{child_Level}}</span><b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: #f1b44c !important" class="bg-primary bg-soft text-light">&nbsp;
                    </b-badge>&nbsp;
                </span>
            </div>
            <!-- <div class="mb-1" v-show="hasChildObjectives">
                Child Levels: <span style="font-weight: bold; margin-right: 3px;" class="text-primary"></span><b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: #f46a6a !important" class="bg-primary bg-soft text-light">&nbsp;
                </b-badge>&nbsp;
            </div> -->
            <b-alert :show="payload.parent_objective_id == null">
                This objective is not linked to any objective
            </b-alert>
            <b-overlay :show="is_loading">
                <div class="d-flex">
                    <b-form-input size="sm" v-model.lazy="filter_input" placeholder="Search Objective"></b-form-input>
                    <b-button size="sm" variant="warning" @click="filter_input = ''" class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                </div>
                <webix-ui v-if="objective_data.length > 0" style="height: 100%;" :config="ui" v-model="objective_data" ></webix-ui>
            </b-overlay>
            <b-alert show v-show="is_loading_all_objectives">
                Loading all objectives
            </b-alert>
        </b-modal>
</template>

<script>
//eslint-disable-next-line
import Swal from 'sweetalert2';
import { levelComputed, activityComputed, activityMethods, performanceComputed, performanceMethods, profileComputed, profileMethods } from '@/state/helpers'
import _ from 'lodash'
export default {
    props:{
        payload:{
            required: true,
        },
        not_objective:{
            type: Boolean,
            default: false,
        },
        objective_type: {
            default: false,
        }

    },
    data:() => ({
        is_loading: false,
        is_loading_all_objectives: false,
        objective_data: [],
        show_all_objectives: false,
        filter_input: '',
        usedLevels: [],
    }),
    watch:{
        show_all_objectives(){
            //this.is_loading = true;
            this.objective_data = [];
            this.initModal();
        },
        filter_input(newValue){
            let tree = window.webix.$$('treeModal');
            if(tree){
                tree.filter("#name#", newValue);
            }
        },
    },
    computed:{
        ...levelComputed,
        ...activityComputed,
        ...performanceComputed,
        ...profileComputed,
        company_level() {
            if(this.selected_level.id == this.company_id) {
                //let level = this.levels.find(e => {return e.id == this.selected_level.id})
                return 'Goal Objectives' + '(' + this.goal_objectives.length + ')'
            } else {
                return null
            }
        },
        parent_level() {
            if(this.selected_level.id == this.company_id) {
                return null
            }
            if(this.selected_level.id == this.company_id) {
                if(this.goal_objectives.length == 0) {
                    return null
                } else { 
                    let level = this.levels.find(e => {return e.id == this.goal_objectives[0].level_id})
                    return level.name
                }
            } else {
                if(this.parent_objectives_list.length == 0) {
                    return null
                } else {
                    let level = this.levels.find(e => {return e.id == this.parent_objectives_list[0].level_id})
                    return level.name
                }
            }          
        },
        child_Level() {
            if(this.selected_level && this.milestone_goals.length != 0) {
                    let level = this.levels.find(e => {return e.id == this.selected_level.id})
                    return level.name
                } else {
                    return null
                }         
        },
        // hasChildObjectives() {
        //     let hasChilderen = false
        //     //let data = this.all_objectives
        //     this.objective_data.forEach(obj => {
        //         let level = this.levels.find(e => {return e.id == obj.level_id})
        //         if(level.name != this.child_level && level.name != this.parent_level){
        //             hasChilderen = true
        //         }
        //     })
        //     if(hasChilderen) {
        //         return true
        //     } else {
        //         return false
        //     }
        // },
        is_company_level(){
            return this.current_level_id == this.company_id;
        },
        ui(){
            let el = this;
            //eslint-disable-next-line
            let current_id = this.payload.level_id
            return {
                id: 'treeModal',
                css: "webixModal",
                view: 'tree',
                autoheight: true,
                tooltip:function(obj){
                    let level = el.levels.find(e => {return e.id == obj.level_id})
                    return level.name;
                },
                //template:"{common.icon()} #name#",
                template: (obj, common) => {
                    console.log(JSON.parse(JSON.stringify(obj)))
                   let badge = ''
                   if(el.objective_type) {
                      badge = `<span class="badge-item badge-item-objective mr-1"></span>`
                   }
                   let level = el.levels.find(e => {return e.id == obj.level_id})
                   if(el.company_level != null) {
                        if(obj.objective_type == 'milestone') {
                            return `<span class="groupStyles">` + common.icon(obj, common) + `<b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: rgb(107, 168, 203) !important;" class="bg-primary bg-soft text-light groupStyles">
                                &nbsp&nbsp;&nbsp;</b-badge>&nbsp;<span class="badge-item badge-item-objective-goal mr-1"></span><div class="normal-text custom-tree_styles"> ${obj.name}</div>`
                        } else if(level.id == el.company_id) {
                            return `<span class="groupStyles">` + common.icon(obj, common) + `<b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: #f1b44c !important" class="bg-primary bg-soft text-light groupStyles">
                                &nbsp&nbsp;&nbsp;</b-badge>&nbsp;${badge}</span><div class="normal-text custom-tree_styles"> ${obj.name}</div>`
                        } else {
                            el.hasChildObjectives = true
                            return `<span class="groupStyles">` + common.icon(obj, common) + `<b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: #f46a6a !important" class="bg-primary bg-soft text-light groupStyles">
                                &nbsp&nbsp;&nbsp;</b-badge>&nbsp;${badge}</span><div class="normal-text custom-tree_styles"> ${obj.name}</div>`
                        }
                   } else {
                        if(obj.objective_type == 'milestone') { 
                            return `<span class="groupStyles">` + common.icon(obj, common) + `<b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: rgb(107, 168, 203) !important;" class="bg-primary bg-soft text-light groupStyles">
                                &nbsp&nbsp;&nbsp;</b-badge>&nbsp;<span class="badge-item badge-item-objective-goal mr-1"></span></span><div class="normal-text custom-tree_styles"> ${obj.name}</div>`
                        } else if(level.name == el.parent_level) {
                            return `<span class="groupStyles">` + common.icon(obj, common) + `<b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: rgb(107, 168, 203) !important;" class="bg-primary bg-soft text-light groupStyles">
                                &nbsp&nbsp;&nbsp;</b-badge>&nbsp;${badge}</span><div class="normal-text custom-tree_styles"> ${obj.name}</div>`
                        } else if(level.name == el.child_Level) {
                            return `<span class="groupStyles">` + common.icon(obj, common) + `<b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: #f1b44c !important" class="bg-primary bg-soft text-light groupStyles">
                                &nbsp&nbsp;&nbsp;</b-badge>&nbsp;${badge}</span><div class="normal-text custom-tree_styles"> ${obj.name}</div>`
                        } else {
                            el.hasChildObjectives = true
                            return `<span class="groupStyles">` + common.icon(obj, common) + `<b-badge variant="primary" style="border-radius: 0.2rem; font-weight: normal; font-size: 11px; background-color: #f46a6a !important" class="bg-primary bg-soft text-light groupStyles">
                                &nbsp&nbsp;&nbsp;</b-badge>&nbsp;${badge}</span><div class="normal-text custom-tree_styles"> ${obj.name}</div>`
                        }
                   }
                    
                },
                minHeight: 500,
                maxHeight: 700,
                ready:function(){
                    setTimeout(()=>{
                        const tree = this;
                        let itemId = el.payload.parent_objective_id
                        var parentIds = [];
                        // Find the parent IDs from the child item up to the root node
                        while (itemId) {
                            var item = tree.getItem(itemId);
                            if (!item) break; // Stop if item not found
                            parentIds.unshift(itemId); // Add the parent ID to the beginning of the array
                            itemId = item.$parent; // Move to the next parent
                        }

                        // Expand each parent node
                        parentIds.forEach(function (parentId) {
                            tree.open(parentId);
                        });
                        this.addCss(el.payload.id, "invalid_selectable_row");
                        if(el.payload.parent_objective_id != null){
                            if(this.exists(el.payload.parent_objective_id)){
                                this.select(el.payload.parent_objective_id);
                            }
                        }
                    }, 50)
                },
                on:{
                    //eslint-disable-next-line
                    onItemClick(id){
                        let selected_item = this.getItem(id);
                        //el.payload.is_new == undefined || 
                        if(selected_item.id == el.payload.id || el.isValidID(el.payload.id, selected_item.id) == false){
                            Swal.fire({
                                icon:'error',
                                title:"Invalid selection",
                            })
                        }
                        else{
                            if(el.not_objective){
                                //* no need for the popup 
                                
                                if(el.payload.is_new){
                                    el.$emit('updateNewID', id)
                                }
                                else{
                                    el.$emit('selectedNewID', id);
                                }
                            }
                            else{
                                //if(el.payload.is_new){
                                    el.$emit('updateNewID', id)
                                //}
                                // else{
                                //     Swal.fire({
                                //         title: "Please note:",
                                //         text: "All sub-objectives under this Objective will have their parent objective reset. Please review all its sub-objective positions.",
                                //         icon: "warning",
                                //         showCancelButton: true,
                                //         confirmButtonColor: "#34c38f",
                                //         cancelButtonColor: "#f46a6a",
                                //         confirmButtonText: "Yes",
                                //     }).then((result) => {
                                //         if (result.value) {
                                //             el.$emit('selectedNewID', id);
                                //         }
                                //     });
                                // }
                                
                            }
                        }
                    },
                }
            }
        },
        all_objectives(){
            //* return a flat array of all objectives
            let objectives = [];
            if(this.activity_data != null){
                //* combine parent objectives and objectives array
                objectives = objectives.concat(this.activity_data.parent_objectives, this.activity_data.objectives)
            }
            console.log('OBJECTIVES FOR TREE', objectives)
            return objectives;
        },
        modalTitle(){
            let title = 'Invalid Title'
            if(this.payload.modal_type == "frictionAndRisks") {
                title = `Link Objective Hierarchy`;
            } else {
                title = `Update ${this.payload.modal_type} Hierarchy`;
            }     

            return title;
        },
        getAvailableObjectives(){
            if(this.objective_type == false){
                return [...this.milestone_goals, ...this.goal_objectives]
            }
            console.log(this.objective_type)
            switch(this.objective_type){
                case 'goal':{
                    if(this.is_company_level){
                        let objectives = _.cloneDeep(this.goal_objectives);
                        objectives.forEach( item => {
                            item.data = [];
                        });
                        let milestone_goals = []
                        milestone_goals = _.cloneDeep(this.milestone_goals);
                        milestone_goals.forEach( item => {
                            let index = objectives.findIndex( (objective_item) => {
                                return item.parent_objective_id == objective_item.id;
                            })
                            console.log(index)
                            if(index != -1){
                                objectives[index].data.push(item)
                            }
                        });
                        return objectives
                        
                    }
                    else{
                        let objectives = this.parent_objectives_list.map( item => {
                            item['data'] = [];
                            return item;
                        });
                        let milestone_goals = []
                        milestone_goals = _.cloneDeep(this.milestone_goals);
                        milestone_goals.forEach( item => {
                            let index = objectives.findIndex( objective => {
                                return item.parent_objective_id == objective.id;
                            })
                            if(index != -1){
                                objectives[index].data.push(item);
                            }
                            else{
                                objectives.push(item);
                            }
                        })
                        return objectives;


                        // let objectives = _.cloneDeep(this.milestone_goals);
                        // let parent_ids = [];
                        // if(objectives.length > 0){
                        //     objectives.forEach( item => {
                        //         if(item.data == undefined){
                        //             item.data = [];
                        //         }

                        //         if(parent_ids.includes(item.parent_objective_id) == false){
                        //             let parent_index = _.findIndex(this.parent_objectives_list, {
                        //                 id:item.parent_objective_id,
                        //                 //objective_type: 'level'
                        //             });
                        //             if(parent_index != -1){
                        //                 console.log('Look Here', this.parent_objectives_list[parent_index].name);
                        //                 parent_ids.push(item.parent_objective_id)
                        //             }
                        //         }
                        //     });
                        // }
                        // let parent_objectives_tree = [];
                        // parent_ids.forEach( id => {
                        //     let parent_index = _.findIndex(this.parent_objectives_list, {
                        //         id:id,
                        //     });
                        //     if(parent_index != -1){
                        //         parent_objectives_tree.push(this.parent_objectives_list[parent_index])
                        //     }
                        // })
                        // if(parent_objectives_tree.length == 0){
                        //     return [...objectives, ...this.parent_objectives_list];
                        // }
                        // else{
                        //     parent_objectives_tree.forEach( parent => {
                        //         if(parent.data == undefined){
                        //             parent.data = []
                        //         }
                        //         objectives.forEach( objective => {
                        //             if(objective.parent_objective_id == parent.id){
                        //                 parent.data.push(objective);
                        //             }
                        //         })
                        //     });
                        //     return parent_objectives_tree;
                        // }
                        
                    }
                    
                }
                case 'frictionAndRisks':{
                    if(this.teams.linkable_objectives.length > 0) {
                        let objectives = _.cloneDeep(this.teams.linkable_objectives)
                        objectives.forEach( item => {
                            item.data = [];
                        });
                        let linkableObjectives = []
                        linkableObjectives = _.cloneDeep(this.teams.linkable_objectives);
                        linkableObjectives.forEach( item => {
                            let index = objectives.findIndex( (objective_item) => {
                                return item.parent_objective_id == objective_item.id;
                            })
                            console.log(index)
                            if(index != -1){
                                objectives[index].data.push(item)
                            }
                        });
                        return objectives
                    } else {
                        return []
                    }
                }
                case 'milestone':{
                    return []
                }
                case 'personal':{
                    if(this.is_company_level){
                        let objectives = _.cloneDeep(this.goal_objectives);
                        objectives.forEach( item => {
                            item.data = [];
                        });

                        this.milestone_goals.forEach( item => {
                            let index = objectives.findIndex( (objective_item) => {
                                return item.parent_objective_id == objective_item.id;
                            })
                            console.log(index)
                            if(index != -1){
                                objectives[index].data.push(item)
                            }
                        });
                        return objectives;
                    }
                    else{
                        let objectives = this.parent_objectives_list.map( item => {
                            item['data'] = [];
                            return item;
                        });
                        this.milestone_goals.forEach( item => {
                            let index = objectives.findIndex( objective => {
                                return item.parent_objective_id == objective.id;
                            })
                            if(index != -1){
                                objectives[index].data.push(item);
                            }
                            else{
                                objectives.push(item);
                            }
                        })
                        return objectives;
                   }
                }
                case 'level':{
                    //let temp = _.cloneDeep(this.goal_objectives_tree);
                    const flatten = (members) => {
                        let data = [];
                        return members.map(m => {
                            if (m.data && m.data.length) {
                                data = [...data, ...m.data];
                            }
                            return m;
                        }).concat(data.length ? flatten(data) : data);
                    };
                    let all_objectives = flatten(this.goal_objectives_tree);
                    all_objectives.push(...this.parent_objectives_list);

                    return this.buildCombindedObjectivesTree(all_objectives)
                }
                default:{
                    return [];
                }

            }
        },
    },
    methods:{
        ...activityMethods,
        ...performanceMethods,
        ...profileMethods,
        async buildCombindedObjectivesTree(flat_array){
        const map = {};
        const nestedArray = [];
        let pre_processed_array = [];
        return new Promise( (resolve) => {
            if(flat_array.length == 0){
                resolve();
            }
            //* get unique by id
            pre_processed_array = _.uniqBy(flat_array, 'id');
            //* set to correct order
            //pre_processed_array = _.orderBy(pre_processed_array, ['item_order']);
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = {
                    parent_milestone_name: item.parent_milestone_name, 
                    parent_objective_name: item.parent_objective_name,
                    level_milestone_id: item.level_milestone_id,
                    parent_objective_id: item.parent_objective_id,
                    level_id: item.level_id,
                    id: item.id,
                    progress: item.progress,
                    year: item.year,
                    quarter: item.quarter,
                    month: item.month,
                    name: item.name,
                    parent_id: item.parent_objective_id,
                    start_date: item.start_date,
                    deadline: item.deadline,
                    owner_name: item.owner_name,
                    item_order: item.item_order, // no longer needed
                    owner_user_id: item.owner_user_id,
                    status: item.status,
                    change: item.change,
                    data : item.data == undefined ? [] : item.data, // array of children
                    type: "folder",
                    notes: item.notes,
                }
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            
            resolve(nestedArray);
        })
    },
        isValidSelection(clicked_level, parent_level){
            if(this.payload.parent_id == null){
                return true;
            }
            if(clicked_level == parent_level){
                return true;
            }
            else if(clicked_level > parent_level && clicked_level - parent_level == 1){
                return true;
            }
            else{
                return false;
            }
        },
        isValidID(item_id, parent_id){
            if(this.payload.is_new == undefined && this.payload.modal_type != 'personal'){
                let table = window.webix.$$('treeModal');
                let current_objective = table.getItem(item_id);
                let new_parent_objective = table.getItem(parent_id);
                if(this.not_objective){
                    //* no need to check validation item if the parent component is not the objectives table
                    return true;
                }
                if(Number(current_objective.id) != Number(new_parent_objective.$parent)){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return true;
            }
            
        },
    //     getAvailableParentObjectives(){
    //         console.log(this.payload)
    //         //* used when a user is selected
    //         let level_id = this.payload.level_id != undefined ? this.payload.level_id : this.selected_level.id
    //         this.linkableObjectiveAPI(level_id)
    //         .then( (response) =>{
    //             //let filtered_response = response.filter( item => item.parent_level_objective == true );
    //             //console.log("Linkable objectives response",response)
    //             let data = response.concat(this.activity_data.objectives);
    //             //data = _.uniqBy(data, 'id');
    //             console.log(data);
    //             this.processTree(data);
    //         })

    // },
    // getAvailableObjectives(){
    //     //* used when no user is selected
    //     let top_level_id = null;
    //     console.log('DEBUG PAYLOAD',this.payload)

    //     if(this.payload.parent_objective_id == null && this.payload.is_new == undefined){
    //         //alert(1)
    //         top_level_id = this.payload.level_id;
    //         let index = _.findIndex(this.all_objectives, {id:this.payload.id});
    //         if(index != -1){
    //             top_level_id = this.all_objectives[index].level_id;
    //             console.log('DEBUG OBJECTIVES', this.all_objectives[index])
    //         }
    //     }
    //     else{
    //         //alert(2)
    //         let index = _.findIndex(this.all_objectives, {id:this.payload.parent_objective_id});
    //         if(index != -1){
    //             top_level_id = this.all_objectives[index].level_id;
    //             //console.log('DEBUG OBJECTIVES', this.all_objectives[index])
    //         }
    //     }
    //     if(top_level_id != null){
    //         //alert('A')
    //         //* only pass through the required objectives to the procrssTree method
    //         //let available_objects = [];
    //         //* get all objectives that belong to the same level as the current item's parent level_id*/

    //         //*get all children of the top_level_id and find their linked objectives
    //         let peer_levels = _.filter(this.levels, {parent_id: top_level_id});
    //         let peer_objectives = [];
    //         peer_levels.forEach( level => {
    //             let peers = _.filter(this.activity_data.objectives, {level_id: level.id});
    //             peer_objectives = peer_objectives.concat(peers);
    //         });

    //         //available_objects = available_objects.concat(parent_level_objectives, peer_objectives);
    //         let parent_levels = _.filter(this.all_objectives, {level_id: top_level_id});
    //         //console.log('DEBUG OBJECTIVES',top_level_id)
    //         //let data = parent_levels;
    //         let data = parent_levels.concat(peer_objectives);
    //         //let data = parent_levels.concat(this.activity_data.objectives);
    //         this.processTree(data);


    //     }
    //     else if(this.payload.is_new){
    //         //alert('B')
    //         console.log("ACTIVITY DATA",this.activity_data)
    //         let data = this.activity_data.objectives.concat(this.activity_data.parent_objectives);
    //         this.processTree(data);
    //     }
    //     else{
    //         //alert('C')
    //         //alert('Hmm....')
    //         this.processTree(this.all_objectives);
    //     }

    // },
    processTree(data_array){
        var data = _.cloneDeep(data_array);
        console.log('CHECK ME DATA', data)
        data.forEach((element, idx) => {
            if(element == undefined){
                console.log('Found it element', idx)
            }
            element["parent"] = element.parent_objective_id;
            element["text"] = element.name;
        });
        data.forEach((ele) => {
            let parent_objective_id = ele.parent_objective_id;
            if (parent_objective_id == null) {
                //* Parent item -- do nothing
            } 
            else {
                //* If element is a child element, push to children (data) array.
                data.forEach((d) => {
                    if (d.id === parent_objective_id) {
                        //* flagged items will be ignored - prevent duplicate items causing the treetable to act as a datatable...
                        ele['flagged'] = true;

                        let childArray = d.data;
                        if (!childArray) {
                            childArray = [];
                        }
                        childArray.push(ele);
                        d.data = childArray;
                    }
                    else{
                        //* hmm
                        //TODO - verify this fixed duplicates not being removed
                        let index = _.findIndex(data, {id: d.parent_objective_id});
                        if(index == -1){
                            d.parent_objective_id = null ;
                        }

                    }
                });
            }
        });
        if(this.isParentLevel){
            //* Remove duplicate elements
            data = data.filter((ele) => ele.parent_objective_id == null);
        }
        else{
            //* Remove duplicate elements
            //! ***removed friday 11 nov - fixed issue with parent objectives
            //data = data.filter((ele) => ele.data != undefined || ele.parent_objective_id == null);
            //* fix for activity-other objectives not showing children as a tree
            data = data.filter( (ele) => ele.flagged == undefined );
        }
        console.log('Should be a tree', data)
        this.objective_data = data;
    },
    isParentLevel(){
        let valid = false;
        if(this.selected_level != null){
            valid = this.selected_level.parent_id == null
        }
        return valid
    },
    async initModal (){
        this.is_loading = true;
        this.objective_data = await this.getAvailableObjectives;
        this.$nextTick(()=>{
            this.is_loading = false;
        })

    }
},
mounted(){
    console.log(JSON.parse(JSON.stringify(this.payload)))
    this.initModal();
    //else{
        // load parent objectives for linking
        //this.linkableObjectiveAPI(this.selected_level.id)
    //}
    
    //this.linkableObjectiveAPI(this.selected_level.id)
},
}
</script>
<style>
.hierarchyModalAlert{
    padding: 0.4rem;
}
.hierarchyModalAlert .close{
        padding: 0;
        font-size: 20px;
    }
.webixModal .webix_selected{
    /*background-color: #daecff !important;*/
    text-decoration: underline black ;
    color:dodgerblue;
    font-weight: bold;
}
.webix_tree_item {
    height: auto;
}
.groupStyles {
    position: relative !important;
    vertical-align: top !important;
}

.custom-tree_styles {
    width: 100%;
    word-wrap: normal;
    word-break: break-word;
    white-space: normal;
    display:inline-block;
    height: auto;
    /* text-align: unset;  */
    /* text-indent: -1.3%; */
    /* padding-left: 2em !important; */
    /* margin-left: 0 auto */
}
/* .show_objective_modal{
    cursor: pointer;
    color: transparent;
} */

</style>