<template>
    <b-row>
        <b-col cols="12" style="margin-right: 5px !important; padding-right: 5px !important;">
            <b-card class="process-card">
                <Table
                    :columns="columns"
                    :simple_columns="simple_columns"
                    :uid="table_uid"
                    :id="table_uid"
                    :ref="table_uid"
                    :data="process_steps_data"
                    :is_loading="is_loading"
                    :pager_config=5
                    :default_values="default_values"
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    only_editable_columns
                    prevent_first_row_select
                    :button_methods="button_methods"
                    :active_id="selected_process_map_process_steps"
                    :validation="table_validation_rules"
                    :toggle_color="hidden_color"
                    @selectedRowEvent="selectedRowEvent"
                    @deleteRows="deleteProcessStepsTable"
                    @onDataUpdate="onChangesMade"  
                    :hide_action_buttons="!isCompanyLevel"              
                    :hide_clear_column="true" 
                    :toggle_search="toggle_search"
                    @onDeleteArrayUpdate="onDeleteArrayUpdate"
                >
                <template #title>
                    <div style="display: flex;justify-content: space-between;">
                        <span class="item-title" v-bind:class="{grey:hidden_color}">
                            Process Steps
                        </span> 
                        <span v-show="processSteps_view_mode == 'view'" class="item-title close-table-icon cursor-pointer" v-bind:class="{grey:hidden_color}">
                            <font-awesome-icon title="Close Table" :icon="['fas', 'xmark']" @click="closeTable" />
                        </span> 
                    </div>
                </template>
                </Table>
                <kpisModal
                    v-if="show_kpis_modal"
                    :payload="modal_payload"
                    @selectedNewID="handlePlanChangedEvent"
                />
               
            </b-card>
        </b-col>
        <b-col cols="12">
            
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/processMapTable.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods, activityComputed, activityMethods, webixTableState, adminComputed, adminMethods } from '@/state/helpers'
import dateMixin from '@/mixins/dateMixin.js'
import kpisModal from '../../objectives/components/misc/structureModals/kpisModal.vue'
import _ from 'lodash'
import Swal from 'sweetalert2'
export default {
    mixins:[ dateMixin ],
    name: 'processSteps',
    components: {
        Table,
        kpisModal
    },
    props:{
        processSteps_view_mode: {
            default: 'view'
        },
        process_steps_delete: {
            default: false
        },
        process_steps_save: {
            default: false
        },
        columns_config: {
            default: false
        },
        toggle_search: {
            default: false
        },
        hidden_color: {
            default: false
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'processSteps',
        table_view_mode: 'view',
        
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        disableEdit: false,
        show_kpis_modal: false,
        unitTypes:[],
        step_order: 0
    }),
    watch: {
        processSteps_view_mode() {
            if(this.processSteps_view_mode == 'edit'){
                this.$refs[this.table_uid].table_mode = 'edit'
            }
            if(this.processSteps_view_mode == 'view'){
                this.$refs[this.table_uid].table_mode = 'view'
            }
            if(this.processSteps_view_mode == 'delete'){
                this.$refs[this.table_uid].table_mode = 'delete'
            }
        },
        process_steps_delete() {
            this.deleteProcessStepsTable(this.delete_array)
        },
        process_steps_save() {
            let data = window.webix.$$('processSteps').serialize(null, true);
            this.saveProcessStepsTable(data)
        },
        canShowHeaderFilters() {
            this.$nextTick(() => {
                //* check if ref is valid (should be)
                if (this.$refs[this.table_uid]) {
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                    
                }
            })
        },
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        ...activityComputed,
        ...webixTableState,
        ...adminComputed,
        //* table computed methods
        columns(){
            let el = this;
            //* get ref of current scope
            let steps_columns = [
                { id: 'step_order', header: 'Order', editor: 'text', fillspace: 0.1, visibility: ['edit'], template:function(item) {
                    if(item.step_order == undefined) {
                        return ''
                    } else {
                        el.step_order = item.step_order
                        return item.step_order
                    }
                }},
                { id: 'name', editor: 'text', fillspace: 0.1, searchable: true,  header: el.canShowHeaderFilters ? ["Process Step", { content: "textFilter" }] : ["Process Step"]},
                { id: "owner_user_id", editor: "combo", minWidth: 120, options: this.userList,
                    header: 'Owner',
                    tooltip: '',
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                { id: 'kpi_name', header: 'KPI Title', fillspace: 0.1, editor: true,
                    template: function (obj) {
                        let options = el.kpis;                  
                        let index = _.findIndex(options, {id: Number(obj.kpi_id)});
                        console.log(index)
                            let name = '' 
                            if(index != -1){
                               name = options[index].name;
                            } else if (obj.kpis) {
                                name = obj.kpi.name
                            }

                            if(name == '' && !obj.is_empty) {
                              name = "No KPI Selected"
                            }
                        if (el.processSteps_view_mode == 'edit' && !obj.is_empty) {
                            return `
                                    <span class="show_hierarchy_text">
                                        <i class="me-1 show_kpis_modal fas fa-pen cursor-pointer"></i>
                                        ${name}
                                    </span>`
                        }
                        else {
                            return `
                                    <span>
                                        ${name}
                                    </span>`
                        }
                    }
                },
                { id: 'kpi_unit_type_id', header: 'KPI Unit Type', editor: 'text', fillspace: 0.1, visibility: ['view'],
                    template: function(obj) {
                        let options = el.keyResultUnit;                  
                        let index = _.findIndex(options, {id: Number(obj.kpi_unit_type_id)});
                        console.log(index)
                            let name = '' 
                            if(index != -1){
                               name = options[index].name;
                            }

                            if(name == '' && !obj.is_empty) {
                              name = "No Unit Type"
                            }
                            return `<span>${name}</span>`
                
                }},
            ];
            return steps_columns;
        },
        simple_columns(){
            let el = this;
            //* get ref of current scope
            let steps_columns = [
                { id: 'name', editor: 'text', fillspace: 0.1, searchable: true,  header: el.canShowHeaderFilters ? ["Process Step", { content: "textFilter" }] : ["Process Step"]},
                { id: "owner_user_id", editor: "combo", minWidth: 120, options: this.userList,
                    header: 'Owner',
                    tooltip: '',
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                { id: 'kpi_name', header: 'KPI Title', fillspace: 0.1, editor: true,
                    template: function (obj) {
                        let options = el.kpis;                  
                        let index = _.findIndex(options, {id: Number(obj.kpi_id)});
                        console.log(index)
                            let name = '' 
                            if(index != -1){
                               name = options[index].name;
                            } else if (obj.kpis) {
                                name = obj.kpi.name
                            }

                            if(name == '' && !obj.is_empty) {
                              name = "No KPI Selected"
                            }
                        if (el.processSteps_view_mode == 'edit' && !obj.is_empty) {
                            return `
                                    <span class="show_hierarchy_text">
                                        <i class="me-1 show_kpis_modal fas fa-pen cursor-pointer"></i>
                                        ${name}
                                    </span>`
                        }
                        else {
                            return `
                                    <span>
                                        ${name}
                                    </span>`
                        }
                    }
                },
                { id: 'kpi_unit_type_id', header: 'KPI Unit Type', editor: 'text', fillspace: 0.1, visibility: ['view'],
                    template: function(obj) {
                        let options = el.keyResultUnit;                  
                        let index = _.findIndex(options, {id: Number(obj.kpi_unit_type_id)});
                        console.log(index)
                            let name = '' 
                            if(index != -1){
                               name = options[index].name;
                            }

                            if(name == '' && !obj.is_empty) {
                              name = "No Unit Type"
                            }
                            return `<span>${name}</span>`
                
                }},
            ];
            return steps_columns;
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_kpis_modal':function(e, id){
                    let item = this.getItem(id);
                    el.showKPIsModal(item);
                },
            }
            return onClick;
        },
        isCompanyLevel(){
           return this.company_id == this.selected_level.id;
        },
        default_values(){
            let el = this;
            return [    
                { id : "step_order", value: el.step_order + 1 },
            ]
        },
        process_steps_data(){
            if(this.processMapData.length != 0 && this.processMapData != undefined) {
                let steps = []
                this.processMapData.forEach(item => {
                    if(item.id == this.selected_process_map_process) {
                        steps = item.steps
                    }
                })
                steps = _.orderBy(steps, ['step_order'], ['asc', 'desc']);
                return steps
            } else {
                return []
            }
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        table_validation_rules(){
            //let el = this
            return {
                "step_order": function(item, obj){
                    
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "name": function(item, obj){
                    
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "owner_user_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
        canShowHeaderFilters() {
            console.log(JSON.parse(JSON.stringify(this.table_states)))
            if (this.table_states['processSteps'] == undefined) {
                return false;
            }
            else {
                return this.table_states['processSteps'].show_datatable_filters;
            }
        },
    },
    methods:{
        ...profileMethods,
        ...activityMethods,
        ...adminMethods,
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.setProcessMapProcessSteps(row.id).then(() => {
                    this.$nextTick(() => {
                        window.webix.$$(this.table_uid).refresh()
                    })
                });
            } else {
                this.setProcessMapProcessSteps(-1).then(() => {
                    this.$nextTick(() => {
                        window.webix.$$(this.table_uid).refresh()
                    })
                });
            }
        },
        handlePlanChangedEvent(newID){
            console.log(newID)
            this.searchItem({type: 'kpi', search_name: 'null'})
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            console.log(item);
            //item['target_graph_unit_type_id'] = Number(newID);
            item['kpi_id'] = Number(newID);
            console.log(this.modal_payload.id);
            console.log(item);
            table.updateItem(this.modal_payload.id, item);
            this.$bvModal.hide('kpisModal')
        },
        showKPIsModal(payload){
                this.modal_payload = _.cloneDeep(payload);
                console.log(payload);
                if(payload.kpi_id) {
                    let options = this.searchKPI;
                    let index = _.findIndex(options, {id: Number(payload.kpi_id)});
                    console.log(index)
                    if(index != -1){
                        let kpi = options[index];
                        this.modal_payload['name'] = kpi.name
                        this.modal_payload['level_id'] = kpi.level_id
                    } else {
                        this.modal_payload['name'] = 'No KPI Selected'
                        this.modal_payload['level_id'] = this.selected_level.id
                    }
                    this.modal_payload['modal_type'] = 'KPIS'
                    this.show_kpis_modal = true;
                    this.openModal() 
                } else {
                    this.modal_payload['name'] = 'No KPI Selected'
                    this.modal_payload['level_id'] = this.selected_level.id

                    this.modal_payload['modal_type'] = 'KPIS'
                    this.show_kpis_modal = true;
                    this.openModal()
                }   
        },
        openModal() {
            this.$nextTick(()=>{
                this.$bvModal.show('kpisModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "kpisModal") {
                        this.show_kpis_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        handleTableModeUpdate(new_mode){
            console.log(new_mode) 
            if(new_mode == 'edit') {
                this.setProcessMapViewMode(1);
            }
            //this.$refs.items.setMode('edit');
            this.table_view_mode = new_mode
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
            if(value) {
                this.$emit('changes')
            } 
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        unitTypesList() {
            let unitTypes = [];
            this.keyResultUnit.forEach( unit =>{
                unitTypes.push(`${unit.name}`)
            });
            this.unitTypes = unitTypes;
        },
        deleteProcessStepsTable(data) {
            let promises = [];
            data.forEach(item => {
                promises.push(this.deleteProcessSteps({ step_id: item.id }));
            })
            this.is_loading = true;
            Promise.all(promises)
                .then(() => {
                    this.$emit('deleted')
                    this.is_loading = false;
                })
                .catch(() => {
                    this.is_loading = false;
                })
        },
        saveProcessStepsTable(data){
            //window.webix.$$(this.table_uid).editStop();
            let result = window.webix.$$(this.table_uid).validate();
            console.log(result)
            if(result != true){
                Swal.fire("Invalid rows found.", "", "warning")
            } else {
            this.is_loading = true;
            let rows_to_save = [];
            data.forEach( row => {
                
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        step_order: Number(row.step_order),
                        name: row.name,
                        owner_user_id: row.owner_user_id,
                        kpi_id: row.kpi_id

                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                process_id: this.selected_process_map_process,
                steps: rows_to_save,
            }
            this.is_loading = true;
            this.saveProcessSteps(params).then(()=>{
                    let params = {
                        user_id: this.selected_user_id, 
                        level_output_id: this.selected_process_map_item_id
                    }
                    this.loadProcessMapData(params).then(() => {
                        this.is_loading = false;
                            window.webix.$$('processSteps').table_mode = 'view'
                            this.$emit('saved')
                            this.changes_made_flag = false
                    })                    
                })
            }
        },
        closeTable() {
            this.setProcessMapProcess(-1);
        }
    },
    // watch: {
    //     table_view_mode: {
    //         handler() {
    //             console.log("!!!!!!!!!!!!!");
    //             console.log(this.process_map_mode)
    //             if(this.table_view_mode == 'edit') {
    //                 console.log("@@@@")
    //                 this.$refs.processSteps.setMode('edit');
    //                 this.$refs.items.setMode('edit');
    //             }
    //         }
    //     }
    // },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
    },

    mounted(){
        this.searchItem({type: 'kpi', search_name: 'null'})
        //this.$refs[this.table_uid].table_mode = 'edit'
        this.getKeyResultUnitAPI().then(()=> {
           this.unitTypesList()
        })
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
/* .process-card .card-body {
    margin-left: 0 !important;
    padding-left: 0 !important;
} */
.process-card .card-header-text {
    min-height: 24px
}

.process-card .grey {
    color: grey
}
/* .process-card .tableEditMode .webix_dtable {
    box-shadow: 1.8px 0px 3px 1px dodgerblue !important;
} */

/* .process-card .tableViewMode {
    box-shadow: 1.8px 0px 1px 1px #afafaf !important;
} */
</style>