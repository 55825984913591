<template>
    <b-modal id="toolsModal" hide-footer title="Manage Tools" style="height: 100%;">
            <Table
                v-if="show_table"
                :columns="columns"
                :uid="table_uid"
                :ref="table_uid"
                :data="tools"
                :table_mode="table_view_mode"
                :is_loading="is_loading"
                :context_menu_items="['View', 'Edit', 'Delete']"
                prevent_first_row_select
                :validation="table_validation_rules"
                @saveTable="saveTable"
                @updateViewMode="updateViewMode"
                @onDataUpdate="onChangesMade"
                @deleteRows="deleteRow"
                />
    </b-modal>
</template>

<script>
import Swal from 'sweetalert2'
import Table from '@/components/widgets/table/table.vue';
import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods } from '@/state/helpers';
export default {
    data:() => ({
        show_table: false,
        table_view_mode: 'view',
        is_loading: false,
        delete_array:[],
        changes_made_flag: false,
        table_uid: 'toolTypesTable',

        search_text: '',
    }),
    watch:{
        search_text(text){
            window.webix.$$('toolTypesTable').filter("#name#", text);
        }
    },
    components:{
        Table,
    },
    computed:{
        ...levelComputed,
        ...activityComputed,
        ...profileComputed,
        tools(){
            console.log(this.teams);
            if(this.teams.client_tools){
                return this.teams.client_tools;
            }
            else{
                return [];
            }
        },
        columns(){
            //var el = this;
            let columns = [
                { id: 'name', header: 'Tools Name', editor: 'text', fillspace: 1,},
                { id: 'vendor_name', header: 'Vendor', fillspace: 1, editor: 'text' },
                { id: 'functionality', header: 'Functionality', fillspace: 1.5, editor: 'text' },
            ]
            return columns;
        },
        table_validation_rules(){
            let el = this;
            return {
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            let data = window.webix.$$(el.table_uid).serialize(null, true);
                            let value = false
                            data.forEach(row => {
                                console.log(obj.name)
                                if(row.name == obj.name && row != obj) {
                                    console.log("HITTING HERE")
                                    value = true
                                }
                            })
                            if(value) {
                                return false
                            } else {
                                return true
                            }
                        }
                    }
                },
                "vendor_name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "functionality": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
    },
    methods:{
        ...levelMethods,
        ...activityMethods,
        ...profileMethods,
        updateViewMode(type){
            this.table_view_mode = type;
        },
        saveTable(){
            window.webix.$$(this.table_uid).editStop();
            this.$nextTick(()=>{
                let data = window.webix.$$(this.table_uid).serialize(null, true);
                let new_rows = data.filter( item => !item.is_empty);
                new_rows.forEach(element => {
                    if(element.is_new){
                        element.id = -1;
                    }
                });

                let tools = new_rows.map( row => {
                    return { id: row.is_new ? 0 : row.id, name: row.name, vendor_name: row.vendor_name, functionality: row.functionality }
                })
                let params = {
                    tools: tools,
                }
                console.log(params)
                this.is_loading = true;
                
                this.saveClientTools(params).then(()=>{
                    
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.$emit('saved');
                        this.$refs[this.table_uid].table_mode = 'view'
                    })
                })
            })
        },
        // showDeletePrompt(){
        //     Swal.fire({
        //             title: "Delete selected Units?",
        //             text: "You won't be able to revert this!",
        //             icon: "warning",
        //             showCancelButton: true,
        //             confirmButtonColor: "#34c38f",
        //             cancelButtonColor: "#f46a6a",
        //             confirmButtonText: "Yes, delete it!",
        //         }).then((result) => {
        //             console.log(result)
        //             if (result.value) {
        //                 console.log("running here")
        //                 //* If user selects yes
        //                 this.deleteRow()
        //             }
        //         });
        // },
        deleteRow(data){
            let promises = [];
            let used = false
            console.log(JSON.parse(JSON.stringify(data)));
            console.log(JSON.parse(JSON.stringify(this.teams.communication_tools)))
            this.teams.communication_tools.forEach(mainItem => {
                    data.forEach( item => {
                        if(Number(mainItem.vendor_name) == item.id) {
                        used = true
                       }
                })
               
            })
            console.log(used)
            if(!used) {
                data.forEach( item => {
                    promises.push(this.deleteClientTools({id: item.id}));
                })
                Promise.all(promises)
                .then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.$emit('saved');
                        this.$refs[this.table_uid].table_mode = 'view';
                    })
                })
            } else {
                Swal.fire({
                    title: "Tool in Use",
                    text: "Unable to delete Tool!",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                }).then(() => {
                    this.$refs[this.table_uid].table_mode = 'view'
                })
            }
           
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
    },
    mounted(){
        this.$nextTick(()=>{
            this.show_table = true;
        })
    },
}
</script>

<style>

</style>