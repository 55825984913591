<template>
    <div>
        <b-row>
                <b-col cols="6" class="mb-1">
                <JobsTable :company_level_id="company_level_id"/>
                </b-col>
                <b-col v-if="hasJobSelected" cols="6" class="mb-1">
                    <SkillsTable/>
                </b-col> 
        </b-row>
        <hr>
        <b-row v-if="hasJobSelected">
            <b-col cols="6" class="mb-1">
                <OnboardingTable/>
            </b-col>
            <b-col cols="6" class="mb-1">
                <OffboardingTable/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import JobsTable from './components/jobs/jobsTable.vue'
import SkillsTable from './components/jobs/skillsTable.vue'
import OnboardingTable from './components/jobs/onboardingTable.vue'
import OffboardingTable from './components/jobs/offboardingTable.vue'
import { peopleComputed, peopleMethods, levelComputed, profileComputed, profileMethods } from '@/state/helpers'
export default {
    components:{
        JobsTable,
        SkillsTable,
        OnboardingTable,
        OffboardingTable,
    },
    data:() => ({
        company_level_id: null,
        is_loading: false,
    }),
    watch:{
        // company_level_id:{
        //     immediate: true,
        //     handler(new_id){
        //         if(new_id != null){
        //             this.loadCompanyJobs(this.company_level_id);
        //         }
        //     }
        // },
        current_level_id(){
            // this.setCompanyLevelId();
            this.company_level_id = this.company_id
        },
        selected_level() {
            this.company_level_id = this.company_id
        },
    },
    computed:{
        ...peopleComputed,
        ...levelComputed,
        ...profileComputed,
        hasJobSelected(){
            //* only show the skills and off/onboarding tables if there is a job selected
            if(this.selectedJobId != -1){
                return true;
            }
            else{
                return false;
            }
        }
    },
    methods:{
        ...peopleMethods,
        ...profileMethods
    },
    beforeDestroy(){
        //* set selected job to null before switching tabs
        this.loadCompanyJobs(this.company_id);
        this.setActiveJobId(-1);
        this.setSelectedJobId(-1);
    },
    mounted(){
        if(this.selected_level != null){
            //this.setCompanyLevelId(); 
            this.company_level_id = this.company_id  
        }
    }
}
</script>

<style>

</style>