<template>
    <b-row>
        <b-col cols="12" style="">
            <b-card class="process-card" id="proc-card">
                <Table
                    :columns="columns"
                    :simple_columns="simple_columns"
                    :uid="table_uid"
                    :id="table_uid"
                    :ref="table_uid"
                    :data="process_data"
                    :is_loading="is_loading"
                    :pager_config=5
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    only_editable_columns
                    prevent_first_row_select
                    enforce_structure_checks
                    :toggle_color="hidden_color"
                    :active_id="selected_process_map_process"
                    :validation="table_validation_rules"
                    :toggle_search="toggle_search"
                    @selectedRowEvent="selectedRowEvent"
                    @deleteRows="deleteProcessTable"
                    @onDataUpdate="onChangesMade" 
                    :hide_action_buttons="!isCompanyLevel"
                    :hide_clear_column="true" 
                    @onDeleteArrayUpdate="onDeleteArrayUpdate"
                >
                    <template #title >
                       <div style="display: flex;justify-content: space-between;">
                            <span class="item-title" v-bind:class="{grey:hidden_color}">
                                Process
                            </span> 
                            <span v-show="process_view_mode == 'view'" class="item-title close-table-icon cursor-pointer" v-bind:class="{grey:hidden_color}">
                                <font-awesome-icon title="Close Table" :icon="['fas', 'xmark']" @click="closeTable" />
                            </span> 
                        </div>
                    </template>
                </Table>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/processMapTable.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods, webixTableState,  activityComputed, activityMethods, adminComputed, adminMethods } from '@/state/helpers'
import dateMixin from '@/mixins/dateMixin.js'
import Swal from 'sweetalert2'
import _ from 'lodash'
export default {
    mixins:[ dateMixin ],
    name: 'process',
    components: {
        Table,
    },
    props:{
        process_view_mode:{
            default: 'view',
        },
        process_delete:{
            default: false
        },
        process_save: {
            default: false
        },
        toggle_search: {
            default: false
        },
        hidden_color: {
            default: false
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'process',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        disableEdit: false,
    }),
    watch: {
        process_view_mode() {
            console.log("THIS IS HEARING THE CHANGE");
            if(this.process_view_mode == 'edit'){
                this.$refs[this.table_uid].table_mode = 'edit'
            }
            if(this.process_view_mode == 'view'){
                this.$refs[this.table_uid].table_mode = 'view'
            }
            if(this.process_view_mode == 'delete'){
                this.$refs[this.table_uid].table_mode = 'delete'
            }
        },
        process_delete() {
            this.deleteProcessTable(this.delete_array)
        },
        process_save() {
            let data = window.webix.$$('process').serialize(null, true);
            this.saveProcessTable(data)
        },
        canShowHeaderFilters() {
            this.$nextTick(() => {
                //* check if ref is valid (should be)
                if (this.$refs[this.table_uid]) {
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                    
                }
            })
        },
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...activityComputed,
        ...adminComputed,
        ...profileComputed,
        ...webixTableState,
        //* table computed methods
        columns(){
            let el = this;
            //* get ref of current scope
            let process_columns = [
                { id: 'name', editor: 'text', fillspace: 2.5, searchable: true,  header: el.canShowHeaderFilters ? ["Process Name", { content: "textFilter" }] : ["Process Name"]},
                { id: "owner_user_id", editor: "combo", options: this.userList, visibility: ['view','edit'], fillspace: 0.8,
                    header: 'Owner',
                    tooltip: '',
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
            ];
            return process_columns;
        },
        simple_columns(){
            let el = this;
            //* get ref of current scope
            let process_columns = [
                { id: 'name', editor: 'text', fillspace: 2.5, searchable: true,  header: el.canShowHeaderFilters ? ["Process Name", { content: "textFilter" }] : ["Process Name"]},
                { id: "owner_user_id", editor: "combo", options: this.userList, visibility: ['view','edit'], fillspace: 0.8,
                    header: 'Owner',
                    tooltip: '',
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
            ];
            return process_columns;
        },
        isCompanyLevel(){
           return this.company_id == this.selected_level.id;
        },
        process_data(){
            if(this.processMapData.length != 0 && this.processMapData != undefined) {
                console.log(this.processMapData)
                let processes = this.processMapData
                let data = _.orderBy(processes, ['name'], ['asc', 'desc']);
                return data
            } else {
                return []
            }
        },
        button_methods() {
            return null
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        table_validation_rules(){
            return {
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "owner_user_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
        canShowHeaderFilters() {
            if (this.table_states['process'] == undefined) {
                return false;
            }
            else {
                return this.table_states['process'].show_datatable_filters;
            }
        },
    },
    methods:{
        ...profileMethods,
        ...activityMethods,
        ...adminMethods,
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                //this.active_id = row.id;
                console.log(row.id);
                this.setProcessMapProcess(row.id).then(() => {
                    this.$nextTick(() => {
                        window.webix.$$(this.table_uid).refresh()
                    })
                });
            } else {
                this.setProcessMapProcess(-1).then(() => {
                    this.$nextTick(() => {
                        window.webix.$$(this.table_uid).refresh()
                    })
                });
            }
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
            if(value) {
                this.$emit('changes')
            } 
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        deleteProcessTable(data) {
            let promises = [];
            data.forEach(item => {
                promises.push(this.deleteProcesses({ process_id: item.id }));
            })
            this.is_loading = true;
            Promise.all(promises)
                .then(() => {
                    this.$emit('deleted');
                    this.is_loading = false;
                })
                .catch(() => {
                    this.is_loading = false;
                })
        },
        saveProcessTable(data){
            let result = window.webix.$$(this.table_uid).validate();
            console.log(result)
            if(result != true){
                Swal.fire("Invalid rows found.", "", "warning")
            } else {
            this.is_loading = true;
            let rows_to_save = [];
            data.forEach( row => {
                
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        name: row.name,
                        owner_user_id: row.owner_user_id,
                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                level_output_id: this.selected_process_map_item_id,
                processes: rows_to_save,
            }
            this.is_loading = true;

            console.log(params);
            this.saveProcesses(params).then(()=>{
                    let params = {
                        user_id: this.selected_user_id, 
                        level_output_id: this.selected_process_map_item_id
                    }
                    this.loadProcessMapData(params).then(() => {
                        this.is_loading = false;
                            window.webix.$$('process').table_mode = 'view'
                            this.$emit('saved')
                            this.changes_made_flag = false
                    })                    
                })
            }
        },
        closeTable() {
            this.setProcessMapProcessSteps(-1);
            this.setProcessMapProcess(-1);
            this.setProcessMapID(-1).then(() =>{
                this.$nextTick(() => {
                    if(window.webix.$$('items')) {
                        window.webix.$$('items').refresh();
                    }
                })
            });
        }
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
       
    },
    mounted(){
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
/* .systems-card .card-body {
    margin-left: 0 !important;
    padding-left: 0 !important;
} */
.process-card .card-header-text {
    min-height: 24px
}

.process-card .grey {
    color: grey
}
/* .systems-card .tableEditMode .webix_dtable {
    box-shadow: 1.8px 0px 3px 1px dodgerblue !important;
} */
</style>