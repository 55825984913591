<template>
    <b-row>
        <b-col cols="12" style="margin-right: 5px !important; padding-right: 5px !important;">
            <b-card class="item-card">
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :id="table_uid"
                    :ref="table_uid"
                    :data="items_data"
                    :is_loading="is_loading"
                    :pager_config=5
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    only_editable_columns
                    prevent_first_row_select
                    :default_values="default_values"
                    :active_id="selected_process_map_item_id"
                    :table_mode="table_view_mode"
                    :validation="table_validation_rules"
                    :button_methods="button_methods"
                    :simple_columns="simple_columns"
                    :hide_action_buttons="selected_process_map_item_id !== -1"
                    :hide_clear_column="true"
                    :column_autoheight_key="column_autoheight_key"
                    :toggle_color="hidden_color"
                    has_header_filters
                    :toggle_search="toggle_search"
                    @saveTable="saveTable"
                    @deleteRows="deleteItemsTable"
                    @updateViewMode="table_view_mode = $event"
                    @selectedRowEvent="selectedRowEvent"
                    @onDataUpdate="onChangesMade"
                    @onDeleteArrayUpdate="onDeleteArrayUpdate"
                >
                    <template #title>
                        <span class="item-title" v-bind:class="{grey:hidden_color}">
                            Products & Services
                        </span> 
                    </template>
                </Table>
                <targetsModal
                    v-if="show_targets_modal"
                    :payload="modal_payload"
                    @selectedNewID="handlePlanChangedEvent"
                />
                <HierarchyModal v-if="show_level_linking_modal" show_all_levels :payload="modal_payload" @selectedNewID="handleRowLevelsUpdate" @updatedNewID="handleRowLevelsUpdate"/>
            </b-card>
        </b-col>
        <b-col cols="12">
            
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/processMapTable.vue'
import { levelComputed, levelMethods, peopleComputed, profileComputed, profileMethods, webixTableState, activityComputed, activityMethods, performanceComputed, performanceMethods } from '@/state/helpers'
import targetsModal from '../../objectives/components/misc/structureModals/targetsModal.vue'
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
import dateMixin from '@/mixins/dateMixin.js'
import Swal from 'sweetalert2'
import _ from 'lodash'
export default {
    mixins:[ dateMixin ],
    name: 'items',
    components: {
        Table,
        targetsModal,
        HierarchyModal,
    },
    props:{
        items_view_mode: {
            default: 'view'
        },
        item_delete: {
            default: false
        },
        item_save: {
            default: false
        },
        toggle_search: {
            default: false
        },
        hidden_color: {
            default: false
        },
        column_config: {
            false: false
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'items',
        table_view_mode: 'view',
        new_mode: 0,
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        disableEdit: false,
        selectedRow: false,
        show_targets_modal: false,
        show_level_linking_modal: false,
        show_units_modal: false,
        hideCol: false,
        column_autoheight_key: "details"
    }),
    watch: {
        items_view_mode() {
            if(this.items_view_mode == 'edit'){
                this.table_view_mode = 'edit'
                this.$refs[this.table_uid].table_mode = 'edit'
                window.webix.$$(this.table_uid).refresh()              
            }
            if(this.items_view_mode == 'view'){
                this.table_view_mode = 'view'
                this.$refs[this.table_uid].table_mode = 'view'
                window.webix.$$(this.table_uid).refresh()
            }
            if(this.items_view_mode == 'delete'){
                this.table_view_mode = 'delete'
                this.$refs[this.table_uid].table_mode = 'delete'
                window.webix.$$(this.table_uid).refresh()
            }
        },
        item_delete() {
            this.deleteItemsTable(this.delete_array)
        },
        item_save() {
            let data = window.webix.$$('items').serialize(null, true);
            this.saveItemsTable(data)
        },
        canShowHeaderFilters() {
            this.$nextTick(() => {
                //* check if ref is valid (should be)
                if (this.$refs[this.table_uid]) {
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                    
                }
            })
        },
        selected_process_map_item_id() {
            if(this.selected_process_map_item_id == -1) {
                this.column_autoheight_key = 'details'
            } else {
                this.column_autoheight_key = 'name'
            }
        },
        column_config() {
            this.hideCol = !this.hideCol
            this.$nextTick(() => {
                console.log(this.hideCol)
                window.webix.$$(this.table_uid).refresh();
                this.$refs[this.table_uid].refreshVisibleColumns()
            })
        }
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        ...activityComputed,
        ...performanceComputed,
        ...webixTableState,
        //* table computed methods
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_targets_modal':function(e, id){
                    console.log('no issues');
                    let item = this.getItem(id);
                    console.log(item);
                    el.showTargetsModal(item);
                },
                'show_hierarchy_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showHierarchyModal(item);
                },
            }
            return onClick;
        },
        hide_columns() {
            if(this.selected_process_map_item_id !== -1) {
                return true
            } else {
                return false
            }
        },   
        columns(){
            let el = this;
            //* get ref of current scope
            let items_columns = [
                { id: 'name', editor: 'text', fillspace: 2.5, searchable: true,  header: el.canShowHeaderFilters ? ["Name", { content: "textFilter" }] : ["Name"],},
                { id: 'level_id', header: 'Linked Level', fillspace: 1, editor: true,
                    template:function(obj){
                        console.log(obj);
                        if(obj.level_id != undefined){
                            let index = _.findIndex(el.levels, {id: obj.level_id });
                            let level_name = '';
                            if(index != -1){
                                level_name = el.levels[index].name;
                            }
                            if(el.items_view_mode  === 'edit' && !obj.is_empty){
                               //* we only need to show the pencil icon in edit mode
                               return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                            }
                            else{
                                return `<span>${level_name}</span>`
                            }
                        }
                        else{
                            if((el.items_view_mode == 'edit' && obj.is_new) || !obj.is_empty) {
                                return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>Select Level</span>`
                            } else {
                                return ''
                            }
                            
                        }
                    },
                },
                { id: 'type', header: 'Type', fillspace: 0.8, editor: 'combo', options: ['Product', 'Service', 'Outcome'],
                    template: function(obj) {
                        if(obj.type) {
                            return obj.type.charAt(0).toUpperCase() + obj.type.slice(1)
                        } else {
                            if((el.items_view_mode == 'edit' && obj.is_new) || !obj.is_empty) {
                                return 'No Type Selected'
                            } else {
                                return ''
                            }
                            
                        }
                    }
                },
                { id: 'target_graph_id', header: 'Target', fillspace: 1, editor: true, hidden: this.hideCol,
                    template: function (obj) {
                        let options = el.targets;                  
                        let index = _.findIndex(options, {id: Number(obj.target_graph_id)});
                            let name = '' 
                            if(index != -1){
                               name = options[index].name;
                            } else if (obj.target_graph && !obj.is_empty) {
                                name = obj.target_graph.name
                            }

                            if(name == '' && !obj.is_empty) {
                              name = "No Target Selected"
                            }
                        if (el.items_view_mode == 'edit' && !obj.is_empty) {
                            return `
                                    <span class="show_hierarchy_text">
                                        <i class="me-1 show_targets_modal fas fa-pen cursor-pointer"></i>
                                        ${name}
                                    </span>`
                        }
                        else {
                            return `
                                    <span>
                                        ${name}
                                    </span>`
                        }
                    }
                },
                { id: 'target_graph_owner_name', header: 'Owner', editor: 'text', fillspace: 1, visibility: ['view'], hidden: el.hideCol,
                    template: function(obj) {
                        if(obj.target_graph_id) {
                            let options = el.targets;
                            console.log(obj);
                            let index = _.findIndex(options, {id: Number(obj.target_graph_id)})
                            let name = '' 
                            if(index != -1){
                               name = options[index].owner_name;
                            } else if(obj.target_graph) {
                                name = obj.target_graph.owner_name
                            }

                            if(name == '' && !obj.is_empty) {
                                name = "No Owner"
                            } else {
                                return name
                            } 
                        } else {
                            if((el.table_view_mode == 'edit' && obj.is_new) || !obj.is_empty){
                                return 'No Owner'
                            } else {
                                return ''
                            }
                            
                        }
                    }
                },
                { id: 'details', header: 'Details', fillspace: 2, editor: 'text', hidden: el.hideCol },
            ];
            return items_columns;
        },
        simple_columns(){
            let el = this;
            //* get ref of current scope
            let items_columns = [
                { id: 'name', editor: 'text', fillspace: 2.5, searchable: true,  header: el.canShowHeaderFilters ? ["Name", { content: "textFilter" }] : ["Name"],},
                { id: 'type', header: 'Type', fillspace: 1, editor: 'combo', options: ['Product', 'Service', 'Outcome'],
                    template: function(obj) {
                        if(obj.type) {
                            return obj.type.charAt(0).toUpperCase() + obj.type.slice(1)
                        } else {
                            if((el.items_view_mode == 'edit' && obj.is_new) || !obj.is_empty) {
                                return 'No Type Selected'
                            } else {
                                return ''
                            }
                            
                        }
                    }
                },
                { id: 'level_id', header: 'Linked Level', fillspace: 0.8, editor: true, hidden: el.hideCol,
                    template:function(obj){
                        console.log(obj);
                        if(obj.level_id != undefined){
                            let index = _.findIndex(el.levels, {id: obj.level_id });
                            let level_name = '';
                            if(index != -1){
                                level_name = el.levels[index].name;
                            }
                            if(el.items_view_mode  === 'edit' && !obj.is_empty){
                               //* we only need to show the pencil icon in edit mode
                               return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                            }
                            else{
                                return `<span>${level_name}</span>`
                            }
                        }
                        else{
                            if((el.items_view_mode == 'edit' && obj.is_new) || !obj.is_empty) {
                                return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>Select Level</span>`
                            } else {
                                return ''
                            }
                            
                        }
                    },
                },
                { id: 'target_graph_id', header: 'Target', fillspace: 1, editor: true, hidden: this.hideCol,
                    template: function (obj) {
                        let options = el.targets;                  
                        let index = _.findIndex(options, {id: Number(obj.target_graph_id)});
                            let name = '' 
                            if(index != -1){
                               name = options[index].name;
                            } else if (obj.target_graph && !obj.is_empty) {
                                name = obj.target_graph.name
                            }

                            if(name == '' && !obj.is_empty) {
                              name = "No Target Selected"
                            } else {
                                return name
                            }
                        if (el.items_view_mode == 'edit' && !obj.is_empty) {
                            return `
                                    <span class="show_hierarchy_text">
                                        <i class="me-1 show_targets_modal fas fa-pen cursor-pointer"></i>
                                        ${name}
                                    </span>`
                        }
                        else {
                            return `
                                    <span>
                                        ${name}
                                    </span>`
                        }
                    }
                },
                { id: 'target_graph_owner_name', header: 'Owner', editor: 'text', fillspace: 1, visibility: ['view'], hidden: el.hideCol,
                    template: function(obj) {
                        if(obj.target_graph_id) {
                            let options = el.targets;
                            console.log(obj);
                            let index = _.findIndex(options, {id: Number(obj.target_graph_id)})
                            let name = '' 
                            if(index != -1){
                               name = options[index].owner_name;
                            } else if(obj.target_graph) {
                                name = obj.target_graph.owner_name
                            }

                            if(name == '' && !obj.is_empty) {
                                name = "No Owner"
                            } else {
                                return name
                            } 
                        } else {
                            if((el.table_view_mode == 'edit' && obj.is_new) || !obj.is_empty){
                                return 'No Owner'
                            } else {
                                return ''
                            }
                            
                        }
                    }
                },
                { id: 'details', header: 'Details', fillspace: 2, editor: 'text', hidden: el.hideCol },
            ];
            return items_columns;
        },
        isCompanyLevel(){
           return this.company_id == this.selected_level.id;
        },
        default_values(){
            let el = this;
            return [    
                { id : "level_id", value: el.selected_level.id },
            ]
        },
        items_data(){
            if(this.teams.outputs == undefined){
                return [];
            }
            else{
                let outputs = this.teams.outputs
                let data = _.orderBy(outputs, ['name'], ['asc', 'desc']);
                return data
            }
        },
        table_validation_rules(){
            return {
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "level_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "type": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "details": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
        canShowHeaderFilters() {
            console.log(JSON.parse(JSON.stringify(this.table_states)))
            if (this.table_states['items'] == undefined) {
                return false;
            }
            else {
                return this.table_states['items'].show_datatable_filters;
            }
        },
    },
    methods:{
        ...profileMethods,
        ...activityMethods,
        ...performanceMethods,
        ...levelMethods,
        //* table event handlers
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null && this.$refs[this.table_uid].table_mode == 'view'){
                this.selectedRow = true
                this.setProcessMapID(row.id).then(() => {
                    this.$nextTick(() => {
                        this.active_id = this.selected_process_map_item_id
                        window.webix.$$(this.table_uid).refresh()
                        this.active_id = this.selected_process_map_item_id
                    })
                });
               
                //this.setSelectedProcessMapItemId(1)
            } else {
                this.selectedRow = false
                this.setProcessMapID(-1).then(() => {
                    this.$nextTick(() => {
                        this.active_id = -1
                        window.webix.$$(this.table_uid).refresh()
                        this.active_id = -1
                    })
                });
            }
        },
        handlePlanChangedEvent(object){
            this.searchItem({type: 'target', search_name: 'null'})
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['target_graph_id'] = Number(object.target_id);
            table.updateItem(this.modal_payload.id, item);
            this.$bvModal.hide('targetsModal')
        },
        showHierarchyModal(payload){
            console.log(payload)
            let level_id = 0
            if(payload.level_id == undefined) {
                level_id = this.selected_level.id
            } else {
                level_id = payload.level_id
            }
            let level = this.levels.find((lvl) => lvl.id == level_id);
            console.log(level)
            this.modal_payload = { id: payload.level_id, parent_id: level.parent_id, item_id: payload.id, level_id: payload.level_id};
           
            // if(this.changes_made_flag){
            //     Swal.fire('Please save new rows before making hierarchy changes')
            // }
            // else{
            //     if(this.items_view_mode === 'edit'){
                    // this.modal_payload = _.cloneDeep(payload);
                    // this.modal_payload['modal_type'] = 'Objective'
                    this.show_level_linking_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('hierarchyModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                                this.show_level_linking_modal = false;
                                this.modal_payload = null;
                            }
                        });
                    })
                //}
            //}
        },
        handleRowLevelsUpdate(payload){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.item_id);
            item['level_id'] = Number(payload);
            table.updateItem(this.modal_payload.item_id, item)
            this.$bvModal.hide('hierarchyModal');
        },
        showTargetsModal(payload){
                this.modal_payload = _.cloneDeep(payload);
                console.log(payload);
                if(payload.target_graph_id) {
                    let options = this.targets;
                    let index = _.findIndex(options, {id: Number(payload.target_graph_id)});
                    console.log(index)
                    if(index != -1){
                        let target = options[index];
                        console.log(target.name)
                        this.modal_payload['target_graph_name'] = target.name
                        this.modal_payload['level_id'] = target.level_id
                    } else {
                        this.modal_payload['target_graph_name'] = 'No Target Selected'
                        this.modal_payload['level_id'] = this.selected_level.id
                    }
                    this.modal_payload['modal_type'] = 'Targets'
                    //this.modal_payload['parent_id'] = payload.target_id;
                    this.show_targets_modal = true;
                    this.openModal()     
                } else {
                    this.modal_payload['target_graph_name'] = 'No Target Selected'
                    this.modal_payload['level_id'] = this.selected_level.id

                    this.modal_payload['modal_type'] = 'Targets'
                    //this.modal_payload['parent_id'] = payload.target_id;
                    this.show_targets_modal = true;
                    this.openModal()
                }   
        },
        openUnitTypesModal() {
            this.$nextTick(()=>{
                this.$bvModal.show('unitTypesModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "unitTypesModal") {
                        this.show_units_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        openModal() {
            this.$nextTick(()=>{
                this.$bvModal.show('targetsModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "targetsModal") {
                        this.show_targets_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        handleTableModeUpdate(new_mode){ 
            this.table_view_mode = new_mode
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
            if(value) {
                this.$emit('changes')
            }     
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        saveTable(data){
                this.is_loading = true;
                let rows_to_save = [];
                data.forEach( row => {
                    
                    if(!row.is_empty){
                        let newRow = row.is_new;
                        var params = {
                            id: newRow ? -1 : row.id,
                            level_id: this.selected_level.id,
                            value: row.value,
                            how: row.how,
                        };
                        rows_to_save.push(params);
                    }
                })

                let params = {
                    level_values: rows_to_save,
                }
                this.is_loading = true;
                this.saveValues(params).then(()=>{
                        this.loadTeams(this.selected_level.id)
                        .then(()=>{
                            this.is_loading = false;
                            this.$refs[this.table_uid].table_mode = 'view'
                        })
                    })
        },
        deleteItemsTable(data) {
            let promises = [];
            data.forEach(item => {
                promises.push(this.deleteProductsAndServices({ id: item.id }));
            })
            this.is_loading = true;
            Promise.all(promises)
                .then(() => {
                    this.$emit('deleted');
                    this.is_loading = false;
                })
                .catch(() => {
                    this.is_loading = false;
                })
        },
        saveItemsTable(data){
            window.webix.$$(this.table_uid).editStop();
            let result = window.webix.$$(this.table_uid).validate();
            console.log(result)
            if(result != true){
                Swal.fire("Invalid rows found.", "", "warning")
                this.$emit('saved')
                this.changes_made_flag = false
            } else {
            this.is_loading = true;
            let rows_to_save = [];
            data.forEach( row => {
                
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        level_id: row.level_id,
                        name: row.name,
                        type: row.type.toLowerCase(),
                        target_id: row.target_graph_id,
                        details: row.details,
                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                level_outputs: rows_to_save,
            }
            this.is_loading = true;
            this.saveProductsAndServices(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        window.webix.$$('items').table_mode = 'view'
                        this.$emit('saved')
                        this.changes_made_flag = false       
                    })   
                })
            }
        },
    },
    mounted(){
        this.searchItem({type: 'target', search_name: 'null'})
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
/* .item-card .card-body {
    margin-right: 0 !important;
    padding-right: 0 !important;
} */
.item-card .card-header-text {
    height: 24px
}

.close-table-icon {
    margin-right: 0.2rem
}

.item-card .grey {
    color: grey
}
/* .item-card .tableEditMode .webix_dtable {
    clip-path: inset(-5px 0px -5px -5px) !important;
} */

/* .item-card .tableEditMode .webix_dtable {
    box-shadow: -1.8px 0px 3px 1px dodgerblue !important;
} */

/* .item-card .tableViewMode {
    box-shadow: -1.5px 0px 1px 1px #afafaf !important;
} */
</style>