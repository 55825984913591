<template>
    <b-row>
        <b-col cols="12" v-if="has_mounted && this.selected_team_id != -1">
            <b-card>
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :ref="table_uid"
                    :data="workGroupMembers_data"
                    :is_loading="is_loading"
                    :pager_config=10
                    :prevent_edit_ids="prevent_edit_ids_list"
                    :context_menu_items="['View', 'Edit']"
                    :table_modes="['view', 'edit']"
                    :hide_action_buttons="noEdit && this.selectedEdit"
                    @updateViewMode="handleTableModeUpdate"
                    @saveTable="saveTable"
                    :edit_permissions="{manager:'group-members'}"
                    :required_permissions="['group-members']"
                    guideline_key="work_group_members_table"
                >
                    <template #title>
                    {{ titleName }}
                    &nbsp;>&nbsp;
                    <span class="text-primary">{{ headerName }}</span>
                    </template>
                </Table>
            </b-card>
        </b-col>
        <b-col cols="12">
        </b-col>

    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed ,peopleComputed, peopleMethods, profileComputed , profileMethods } from '@/state/helpers'
import Swal from 'sweetalert2'
import _ from 'lodash'

import { format, parseISO } from 'date-fns';
export default {
    name: 'workGroupMembers',
    components: {
        Table,
    },
    props:{
        initial_table_mode:{
            default: 'view',
        },
        noEdit:{
            boolean: true,
        },
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'workGroupMembers',
        table_view_mode: 'view',
        is_loading: true,
        selectedEdit: true,
        active_id: -1,
        new_view_mode: '',
        headerName: '',
        prevent_edit_ids_list: [],
        changes_made_flag: false,
        delete_array:[],
        array_length: [],
        workGroupMembers_data: [],
        ids: [],
        has_mounted: false,
    }),
    watch: {
        selected_team_id: {
            handler() {
                if(this.selected_team_id == -1) {
                   this.allUsers();
                } else {
                    this.ids = [];
                    if(this.new_view_mode == 'edit') {
                        this.setTableMode();
                    } else {
                        this.loadMembers(this.teams.teams, this.levelEmployeesData);
                    }
                }
                if(this.selected_team_id && this.selected_team_id != -1) {
                    this.hideEdit()
                }
            },
        },
        selected_level: {
            handler() {
                this.headerName = this.selected_level.name
            }
        },
        new_view_mode: {
            handler() {
                if(this.new_view_mode != '' && this.selected_team_id !== -1) {
                    if(this.new_view_mode == 'edit') {
                        this.processTableData(this.levelEmployeesData);
                    } else {
                        this.loadMembers(this.teams.teams, this.levelEmployeesData);
                    }
                }
                    
            }
        }
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        item_length: function () {
            return this.workGroupMembers_data.length;
        },
        titleName() {
            return 'Work Group Members'
        },

        //* table computed methods
        columns(){
            let el = this
            //* get ref of current scope
            let workGroupMembers_columns = [
                { id: 'selected', checkValue:true, uncheckValue: null, template:function(obj, common){
                    if(el.$refs[el.table_uid].table_mode == 'edit'){
                        return common.checkbox(obj,common, obj.selected, {checkValue:true, uncheckValue: false})
                    }
                    else{
                        return ``;
                    }
                }, width: 30, header: '' },
                { id: 'First_Name', header: 'First Name', fillspace: 1 },
                { id: 'Surname', header: 'Surname', fillspace: 1 },
                { id: 'Job_Title', header: 'Job Title', fillspace: 2},
                { id: 'Start_Date', header: 'Start Date', fillspace: 1,
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'Email', header: 'Email', fillspace: 2 },
                { id: 'cell_no', header: 'Mobile/Cell', fillspace: 1 },
            ];
            return workGroupMembers_columns;
        },
    },
    methods:{
        ...peopleMethods,
        ...profileMethods,
        handleTableModeUpdate(new_mode){ 
            this.new_view_mode = new_mode
        },
        allUsers(){ 
            if (this.levelEmployeeData == null) {
                this.loadEmployeesData(this.selected_level.id)
                .then(()=>{
                    this.$nextTick(()=>{
                        this.processTableData(this.levelEmployeesData)
                    })
                }).catch((err) => {
                    console.log(err)
                    this.is_loading = false;
                })
            }
        },
        loadMembers(data, employees) {
            this.prevent_edit_ids_list = [];
            if (data == null) {
              data = [];
            }
            else {
                const filtered_team = data.filter(value => value.id == this.selected_team_id);
                if(filtered_team.length != 0) {
                    this.headerName = filtered_team[0].name
                    let team_members = filtered_team[0].team_members;
                    team_members.forEach((element)=>{
                        this.ids.push(element.user_id)
                        if(element.lead_user == true) {
                            this.prevent_edit_ids_list.push(element.user_id)
                        }
                    })
                    const filtered = employees.filter((item)=>{
                        return this.ids.includes(item.id)
                    });
                    this.processTableData(filtered);
                }
            }
            this.is_loading = false;
        },
        hideEdit() {
            // eslint-disable-next-line vue/no-mutating-props
            this.selectedEdit = false;
        },
        processTableData(data) {
            const tableData = [];
           
            if (data == null) {
              data = [];
            }
            else {
                // const id = 0;
                data.forEach((element) => {
                    const member = {};
                    member['id'] = element.id;
                    member['First_Name'] = element.name;
                    member['Surname'] = element.last_name;
                    if (element.current_job.title){
                        member['Job_Title'] = element.current_job.title
                    } else {
                        member['Job_Title'] = "no job title";
                    }
                    member['Start_Date'] = element.created_at;
                    member['Email'] = element.email;
                    member['cell_no'] = element.cell_no;
                    if(this.ids != null && this.ids.includes(element.id)) {
                        member['selected'] = true;                       
                    } else {
                        member['selected'] = false;
                    }
                    if(this.prevent_edit_ids_list && this.prevent_edit_ids_list.includes(element.id)) {
                        member['lead_user'] = true
                    } else {
                        member['lead_user'] = false
                    }
                    
                    
                    tableData.push(member);
                });
            }
            data = _.orderBy(tableData, ['selected', 'lead_user'], ['desc', 'desc']);
            this.workGroupMembers_data = data;
            this.is_loading = false;
        },
        saveTable(){
            this.is_loading = true;
            let data = window.webix.$$(this.table_uid).serialize(true);
            let members_to_save = [];
            data.forEach( row => {
                const member = {};
                if(!row.is_empty){
                    if (row.selected == true) {
                        member['user_id'] = row.id
                        if(this.prevent_edit_ids_list != null && this.prevent_edit_ids_list.includes(row.id)) {
                            member['lead_user'] = true;                       
                        } else {
                            member['lead_user'] = false;
                        }
                         members_to_save.push(member);
                    }
                }
            })
            let params = {
                teams: [{"level_id": this.selected_level.id,"id": this.selected_team_id,"name": this.headerName,"members": members_to_save}]
            }
            this.is_loading = true;
            this.saveLevelTeams(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view'
                    })
                })
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        setTableMode() {
            return this.$refs[this.table_uid].table_mode = 'view'
        },
    },
    mounted(){
        this.headerName = this.selected_level.name
        this.table_uid = `${this.table_uid}-${this.$route.name}`;
        this.has_mounted = true;
       
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>