<template>
    <b-modal id="objectivePlanModal" hide-footer :title="payload.text" style="height: 100%;">
        <template #modal-header>
            <div class="w-100 d-flex" >
                <span style="font-weight: bold; margin-left: 2px">{{ payload.name }}</span>
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('objectivePlanModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>

            </div>
        </template>
        
        <!-- <div class="modal-title">
           Details   
        </div> -->
        <div>
                <div class="pb-1">  
                    <b-button @click="change_objective_mode = false" variant="lightr" size="sm">
                        <font-awesome-icon icon="fa-arrow-left"/>
                    </b-button>
                    <span class="ms-1">Link to Objective Plan</span>
                </div>
                <b-overlay :show="is_loading">
                <!-- <div class="d-flex">
                    <b-form-input v-model.lazy="filter_input" placeholder="Search Targets"></b-form-input>
                    <b-button class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                </div> -->
                <div class="d-flex">
                    <div class="modal-title m-1">
                        <span v-show="selectedName != ''">Selected {{ selectedType }} : </span> 
                        <span v-show="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                        <span v-show="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                        <!-- <span class="badge-item badge-item-objective mr-1"></span> -->
                        <span style="font-weight: bold" class="text-primary">{{selectedName}}</span>
                    </div>
                </div>
                <!-- <vue-typeahead-bootstrap
                        ref="targetOrObjectiveSearch"
                        :data="searchList"
                        v-model="query"
                        placeholder="Search Target or Objective"
                        showOnFocus
                        @hit="selectedItemEvent"
                        @input="search"
                        prepend="Targets & Objectives:"
                    ></vue-typeahead-bootstrap> -->
                    
                <webix-ui style="height: 100%;" :config="ui" v-model="level_data" ></webix-ui>
                <b-button v-if="changesMade" type="submit" class='ms-auto mt-2' style="float: right" variant='primary' @click="saveChanges">Save Changes</b-button>
                </b-overlay>
            </div>
    </b-modal>
</template>

<script>
import dateMixin from '@/mixins/dateMixin.js'
//mport draggable from 'vuedraggable';
import { levelComputed, activityComputed, activityMethods, profileComputed, profileMethods, ganttComputed, ganttMethods, performanceComputed, performanceMethods, peopleComputed, peopleMethods } from '@/state/helpers';
import { cloneDeep} from 'lodash'
import _ from 'lodash'
export default {
mixins: [dateMixin],
// components: {
//     draggable
// },
props:{
    payload:{
        required: true,
    },
},
data:() => ({
    is_loading: false,
    is_loading_all_linkable_plans: false,
    linkable_plans_data: [],
    show_all_linkable_plans: false,
    filter_input: '',
    users_example:[],
    combined_targets_array: [],
    selectedPlainId: 0,
    combined_objectives_array: [],
    change_objective_mode: false,
    canSearch: false,
    parent_name: '',
    notes: '',
    changesMade: false,
    selectedName: '',
    selectedType: '',
    viewChildren: false,
}),
watch:{
    change_objective_mode: {
        handler() {
            if(this.change_objective_mode == true) {
                console.log("1")
                this.is_loading = true
                console.log('building the tree')
                this.setTreeData();
                this.initflippedModal();
                
            } else {
                console.log("2")
                window.webix.$$('ObjectivesTree').destructor();
            }
        }
    },
    
    selectedPlainId: {
        handler(newValue, oldValue){
            console.log("!!!!!!");
            console.log(newValue)
            console.log(oldValue)
            let tree = window.webix.$$('ObjectivesTree');
            //Remove the styling for previous item (we only have the plain id of the previous item)
            let objective_options = this.goal_objectives_tree;                  
                let objective_index = _.findIndex(objective_options, {id: Number(oldValue)}); 
                if(objective_index != -1){
                    let objective = objective_options[objective_index]
                    let oldValueId = `${this.payload.level_id}|objective-${objective.id}`
                    if(oldValueId && tree.exists(oldValueId)){
                        tree.removeCss(oldValueId, 'selected_style_details_modal')
                    }
                } else {
                    let target_options = this.targets;                  
                    let target_index = _.findIndex(target_options, {id: Number(oldValue)});
                    if(target_index != -1){
                        let target = target_options[target_index];
                        let oldValueId = `${this.payload.level_id}|target-${target.id}`
                        if(oldValueId && tree.exists(oldValueId)){
                            tree.removeCss(oldValueId, 'selected_style_details_modal')
                        }
                    } else {
                        if(oldValue && tree.exists(oldValue)){
                            tree.removeCss(oldValue, 'selected_style_details_modal')
                        }
                    }
                }
            //set the new styling 
            if(this.selectedPlainId){
                if(tree){//* will be und
                    if(this.selectedType == 'objective') {
                        let value = `${this.payload.level_id}|objective-${newValue}`
                        if(tree.exists(value)){
                           tree.addCss(value, 'selected_style_details_modal');
                        }
                        
                    } else if(this.selectedType == 'target') {
                        let value = `${this.payload.level_id}|target-${newValue}`
                        console.log(value)
                        if(tree.exists(value)){
                            console.log("getting in here")
                           tree.addCss(value, 'selected_style_details_modal');
                        }
                        let oldValueId = `${this.payload.level_id}|target-${oldValue}`
                        if(oldValueId && tree.exists(oldValueId)){
                            tree.removeCss(oldValueId, 'selected_style_details_modal')
                        }
                    } else {
                        let value = newValue
                        if(tree.exists(value)){
                           tree.addCss(value, 'selected_style_details_modal');
                        }
                    }
                    
                }
                /*if(window.webix.$$('userLevelsTree').exists)
                window.webix.$$('userLevelsTree').addCss(newValue, 'selected_tree_item')
                if(oldValue != undefined){
                    window.webix.$$('userLevelsTree').removeCss(oldValue, 'selected_tree_item')
                }*/
            }
        }
    },
},
computed:{
    ...levelComputed,
    ...peopleComputed,
    ...activityComputed,
    ...profileComputed,
    ...ganttComputed,
    ...performanceComputed,
    ui(){
            let el = this;
            return{
                id: 'ObjectivesTree',
                view: 'tree',
                css: 'levelTree',
                scroll: true,
                drag: false,
                select: true,
                // //data: this.level_data,
                template: (obj, common) => {
                    let id = obj.id
                    if(isNaN(id)) {
                        console.log(obj)
                        if(id.includes('target')) {
                            return common.icon(obj, common) + `<span class="badge-item badge-item-target mr-1"></span><span class="normal-text tree_styles"> ${obj.name} - <span style="font-weight: bold">${obj.owner_name}</span></span>`;
                        } else {
                            return common.icon(obj, common) + `<span class="badge-item badge-item-objective mr-1"></span><span class="normal-text tree_styles"> ${obj.name}  - <span style="font-weight: bold">${obj.owner_name}</span></span>`;
                        }                       
                    } else {
                        return common.icon(obj, common) + `<span class="normal-text tree_styles">${obj.name}`;
                    }
                },
                //maxHeight: 500,
                filterMode:{
                    showSubItems:false,
                },
                ready(){
                        // For now we using the level Objectives 
                        let objectives_array = cloneDeep(el.teams.linkable_plans);
                        let combined_objectives = []
                        objectives_array.forEach((objective)=>{
                            //if(objective.id.includes('objective')) {
                                let branch_id = objective.level_id
                                if(this.exists(branch_id)){
                                    let mutated_objective = cloneDeep(objective);
                                    // if(mutated_objective.$parent != 0) {
                                    //     let objective = el.current_objective_details.objectives.find( objective => { return objective.id == mutated_objective.plain_id; })
                                    //     if(objective) {
                                    //         mutated_objective.owner_name = objective.owner_name
                                    //     }
                                    // } else {
                                    //     mutated_objective.user_name = 'No Owner'
                                    // }
                                    mutated_objective.owner_name = objective.owner_name
                                    mutated_objective.id = `${branch_id}|objective-${mutated_objective.id}`
                                    console.log(mutated_objective.id);
                                    combined_objectives.push(objective)
                                    this.add(mutated_objective, 0,branch_id);
                                }
                            //}
                        })
                        if(el.combined_objectives_array.length == 0) {
                            let value = el.combined_objectives_array
                            el.combined_objectives_array = value.concat(combined_objectives);
                            console.log(el.combined_objectives_array)
                        }
                },
                on:{
                    onItemClick(id){
                        console.log(id)
                        //let el = this.$scope.$parent;
                        if(id.includes('objective')) {
                            console.log(id);
                            let name = ''
                            let mainId = id.split('|')[1];
                            let level_id = id.split('|')[0];
                            let objective_id = mainId.split('-')[1]
                            //set to combined array
                            el.goal_objectives_tree.forEach(item => {
                                if(item.id == objective_id) {
                                    name = item.name
                                }
                            })
                            console.log(name)
                            el.selectedType = 'objective'
                            el.selectedName = name
                            el.selectedPlainId = objective_id
                            el.changesMade = true
                            el.selectedId = `${level_id}|objective-${objective_id}`
                            console.log(el.selectedId)
                        }
                    },
                    onBeforeOpen(id) {
                        let num = Number(id)
                        if(num != isNaN) {
                           console.log(id)
                           el.level_to_load = Number(id)
                        }
                    }
                },
            }
        },
},
methods:{
    ...activityMethods,
    ...profileMethods,
    ...ganttMethods,
    ...performanceMethods,
    ...peopleMethods,
    saveChanges() {
        let data = {newId: this.selectedPlainId, newType: this.selectedType}
        this.$emit('selectedNewID', data);
        this.$bvModal.hide('objectivePlanModal')
    },
    setTreeData(){
        let temp_level_data = _.cloneDeep(this.levels);
        //temp_level_data = _.orderBy(temp_level_data, ['item_order']);
        this.level_data = this.processLevels(temp_level_data);
    },
    processLevels(levels){
            this.show = false;
            var flat_array = levels != null ? _.cloneDeep(levels) : [];
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            if(flat_array.length == 0){
                this.show = true;
                return [];
            }
            pre_processed_array = _.uniqBy(flat_array, 'id');
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = item;
                // give element the required fields
                new_item["parent"] = new_item.parent_id; //used for webix related stuff
                new_item["value"] = new_item.name; //used for webix related stuff
                new_item['data'] = []; // array of children for the node
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            this.show = true;
            return nestedArray;
    },
    initflippedModal(){
        this.is_loading = true
        setTimeout(() => {
            let matches = []
                //if(this.selectedId.includes('target') || this.selectedId.includes('objective')) {
            matches.push(this.selectedId);
            if(matches.length > 0){
                let tree = window.webix.$$('ObjectivesTree');
                    matches.forEach( match => {
                        if(tree.exists(match)) {
                            let promises = [ this.loadDataFromParents(matches) ];
                            Promise.all(promises)
                            .then(()=>{
                                setTimeout(() => {
                                    tree.addCss(matches[0], 'selected_style_details_modal');
                                    this.openTreeNode(matches, true);
                                    this.is_loading = false
                                    //this.resetTypeahead();
                                }, 1500) 
                                
                            })
                        } else {
                            this.is_loading = false
                        }
                    })
                
            }
        }, 800)
    },
    loadDataFromParents(id_array) {
        let tree = window.webix.$$('objectivePlanModal');
        let data = []
        if(tree){
            let item = tree.getItem(id_array[0]);
            while(item.$parent != 0){
                data.push(item.$parent);
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
        }
        console.log(data)
        // data.forEach(itemID => {
        //     this.loadPerformanceTargets(itemID).then(()=>{
        //         this.buildTree();
        //         this.populateTargetList()
        //         this.show_add_target = false;
        //     })
        // })
    },
    initModal(){
    },
    setUsers() {
        let user = { id: 1, src: this.payload.src };
        this.users_example.push(user)
    },
    // setColor() {
    //     switch(this.payload.priority) {
    //         case 'low':
    //             return 'low_color';
    //         case 'medium':
    //             return 'medium_color';
    //         case 'high':
    //             return 'high_color';
    //         default:
    //             return 'low_color';
    //     }
    // },
    openTreeNode(id_array, searched){
        console.log(id_array)
        let tree = window.webix.$$('objectivePlanModal');
        if(tree){
            let item = tree.getItem(id_array[0]);
            tree.closeAll()
            while(item.$parent != 0){
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
            this.is_loading = false
            if(searched) {
                tree.showItem(id_array[0]);
            }
        }
    },
    formatKanbanHistoryDate(date_value){
        return this.getSimpleDate(date_value);
    },
},
beforeDestroy() {
    this.modal_payload = {}
},
mounted(){
    console.log("1")
    this.is_loading = true
    console.log('building the tree')
    this.initModal();
    this.setUsers();
    this.setTreeData();
    
    
    console.log(JSON.parse(JSON.stringify(this.payload)))
    if(this.payload.modal_type == 'objective') {
        this.selectedPlainId = this.payload.linkable_id
        this.selectedId = `${this.payload.level_id}|objective-${this.payload.linkable_id}`
    }
    this.selectedName = this.payload.name    
    this.selectedType = this.payload.modal_type

    this.initflippedModal();
    //this.populateList();
    
},
}
</script>
<style>
#kanbanModal___BV_modal_header_ {
    background-color: #EBEDF0;
}
.low_color {
    background-color: rgb(107, 168, 203) !important;
}
.medium_color {
    background-color: #f1734f !important;
}
.high_color {
    background-color: red !important;
}
.label {
    font-weight: bold;
}

.selected_style_details_modal  {
    /* border: 2px black !important; */
    color: #0275d8 !important;
    font-weight: bold;
}

#movementHistoryList.verti-timeline .event-list {
    padding: 0px 0px 10px 30px;
}
</style>