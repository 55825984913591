<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { menuMethods, profileMethods, profileComputed } from "@/state/helpers";

import OrganisationChart from '@/components/widgets/graphs/organisation-chart.vue'
import ProfileView from '@/components/profile/profile-page.vue';
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "model",
    },
    components: {
        Layout,
        //eslint-disable-next-line
        PageHeader,
        OrganisationChart,
        ProfileView,
    },
    data() {
        return {
            title: "model",
            items: [
                /*{
                    text: "Home",
                    href: "/",
                },
                {
                    text: "Activity",
                    active: true,
                },*/
            ],
            expand_all: false,
        };
    },
    computed: {
        ...profileComputed
    },
    methods:{
        ...menuMethods,
        ...profileMethods
    },
    beforeDestroy() {
        console.log('firing here')
        this.hideOrgChart(false)
    },
    mounted() {
        setTimeout(()=>{
        this.setLoading(false);
        }, 500)
    },
};
</script>

<template>
    <Layout>
        <!--<PageHeader :title="title" :items="items" /> -->
        <OrganisationChart v-if="hiddenOrgChart"/>
        <ProfileView v-if="!hiddenOrgChart"/>
    </Layout>
</template>
