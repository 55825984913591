<template>
    <b-modal title="Level Linking" id="levelLinkingModal" hide-footer>
        <b-overlay :show="!hasMounted">
            {{alert_msg_text}}
            <webix-ui  style="height: 100%;" v-model="available_levels" :config="ui" ></webix-ui>
            <div class="d-flex">
                <b-button @click="saveNewLevelSelection" class="ms-auto mt-2" variant="success">Save</b-button>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import { adminComputed, levelComputed,} from '@/state/helpers';
import _ from 'lodash';
export default {
    props:{
        current_row:{
            required: true,
        }
    },
    data:() => ({
        available_levels:[],
        hasMounted: false,
        alert_msg_text: '',
    }),
    watch:{
        available_levels(newValue){
            if(newValue.length > 0){
                this.hasMounted = true;
            }
        },
    },
    computed:{
        ...levelComputed,
        ...adminComputed,
        ui(){
            let el = this;
            return {
                id: 'levelLinkingTree',
                view: 'tree',
                css: 'levelTree',
                drag: false,
                tooltip: true,
                data: this.level_data,
                template: "{common.icon()} {common.checkbox()} #value#" ,
                scroll: false,
                on:{
                },
                ready(){
                    //let el = this.$scope.$parent;
                    console.log(el.current_row)
                    console.log('level ids -> ',el.current_row.level_ids)
                    el.current_row.level_ids.forEach( id => {
                        if(this.exists(id)){
                            this.checkItem(id);
                            let item = this.getItem(id);
                            while(item.$parent != 0){
                                //console.log('WHILE LOG z')
                                this.open(item.$parent);
                                item = this.getItem(item.$parent);
                            }
                        }
                    })
                }
            }
        }
    },
    methods:{
        saveNewLevelSelection(){
            this.hasMounted = false
            let tree = window.webix.$$('levelLinkingTree');
            
            if(tree){
                this.$emit('updatedLevels', tree.getChecked());
            }
            
        },
        
        processTree(data){
            data.forEach((element) => {
                // give element the required fields
                element["parent"] = element.parent_id; //used for webix related stuff
                element["value"] = element.name; //used for webix related stuff
                element['data'] = []; // array of children for the node
                element['checkbox_selection'] = 1;
            });

            data.forEach((ele) => {
                let parent_id = ele.parent_id;
                if (parent_id == null) {
                //* Parent item -- do nothing
                //TODO - remove old empty block and replace statement with parent_id != null
                }
                else {
                //* If element is a child element, push to children array.
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            ele['flagged'] = true;
                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else{
                            //TODO - verify this fixed duplicates not being removed
                            let index = _.findIndex(data, {id: d.parent_id});
                            if(index == -1){
                                d.parent_id = null ;
                            }
                        }
                    });
                }
            });
            //* Remove the leftover / duplicate elements
            //data = data.filter((ele) => ele.parent_id != ele.id);
            //data = data.filter((ele) => ele.parent_id == null);
            //* Remove duplicate elements
            data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
            //* fix for activity-other objectives not showing children as a tree
            data = data.filter( (ele) => ele.flagged == undefined );
            return data;
        },
        setAvailableLevels(){
            if(this.$hasUserRole('Power User') || this.$hasPerm('all-structures')){
                //* Power user has access to all levels
                let temp_level_data = _.cloneDeep(this.levels);
                this.available_levels = this.processTree(temp_level_data);
                this.alert_msg_text = "Showing levels for all companies";
            }
            else if(this.$hasPerm('your-structure-only')){
                let temp_level_data = _.cloneDeep(this.levels);
                let user_levels = this.core.user_levels.map( item => { return item.id} );
                temp_level_data = temp_level_data.filter( item => {
                    return _.includes(user_levels, item.id)
                })
                this.available_levels = this.processTree(temp_level_data);
                this.alert_msg_text = "Only showing levels that you belong to";
            }
            else{
                //* use the levels assigned through permissions
                // loop through the allowed_levels id's to find the matching level object
                let temp_level_data = [];
                this.core.allowed_levels.forEach( level_id => {
                    let level = this.levels.find( e => e.id == level_id);
                    if(level){
                        temp_level_data.push(level);
                    }
                })
                this.available_levels = this.processTree(temp_level_data);
                this.alert_msg_text = "Only showing levels that you have permission to view";
            }
            /*
            let temp_level_data = _.cloneDeep(this.levels);
        this.available_levels = this.processTree(temp_level_data);

            */
        },
    },
    mounted(){
        console.log(JSON.parse(JSON.stringify(this.current_row)))
        //init tree
        this.setAvailableLevels();

    },
}
</script>

<style>

</style>