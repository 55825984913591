<template>
    <b-row>
        <b-col cols="12" style="margin-right: 5px !important; padding-right: 5px !important;">
            <b-card class="procedure-card" id="pro-card">
                <Table
                    :columns="columns"
                    :simple_columns="simple_columns"
                    :uid="table_uid"
                    :id="table_uid"
                    :ref="table_uid"
                    :data="procedure_data"
                    :is_loading="is_loading"
                    :default_values="default_values"
                    :pager_config=5
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    only_editable_columns
                    :active_id="active_id"
                    :button_methods="button_methods"
                    @selectedRowEvent="selectedRowEvent"
                    @deleteRows="deleteProceduresTable"
                    :validation="table_validation_rules" 
                    @onDataUpdate="onChangesMade"
                    :hide_action_buttons="!isCompanyLevel"
                    :hide_clear_column="true"
                    :toggle_search="toggle_search"
                    @onDeleteArrayUpdate="onDeleteArrayUpdate"
                >
                    <template #title>
                        <div style="display: flex;justify-content: space-between;">
                            <span class="item-title">
                                Procedure Steps
                            </span> 
                            <span v-show="procedures_view_mode == 'view'" class="item-title close-table-icon cursor-pointer">
                                <font-awesome-icon title="Close Table" :icon="['fas', 'xmark']" @click="closeTable" />
                            </span> 
                        </div>
                    </template>
                </Table>
                <systemsModal
                    v-if="show_systems_modal"
                    :payload="modal_payload"
                    @selectedNewID="handlePlanChangedEvent"
                />
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/processMapTable.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods, webixTableState, adminComputed, adminMethods } from '@/state/helpers'
import systemsModal from '../../objectives/components/misc/structureModals/systemsModal.vue'
import dateMixin from '@/mixins/dateMixin.js'
import _ from 'lodash';
import Swal from 'sweetalert2'
export default {
    mixins:[ dateMixin ],
    name: 'procedure',
    components: {
        Table,
        systemsModal
    },
    props:{
        procedures_view_mode:{
            default: 'view',
        },
        procedure_delete: {
            default: false
        },
        procedure_save: {
            default: false
        },
        toggle_search: {
            default: false
        },
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'procedure',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        disableEdit: false,
        show_systems_modal: false,
        step_order: 0
    }),
    watch: {
        procedures_view_mode() {
            console.log("THIS IS HEARING THE CHANGE");
            if(this.procedures_view_mode == 'edit'){
                this.$refs[this.table_uid].table_mode = 'edit'
            }
            if(this.procedures_view_mode == 'view'){
                this.$refs[this.table_uid].table_mode = 'view'
            }
            if(this.procedures_view_mode == 'delete'){
                this.$refs[this.table_uid].table_mode = 'delete'
            }
        },
        procedure_delete() {
            console.log("DELETING HERE")
            this.deleteProceduresTable(this.delete_array)
        },
        selected_process_map_process_steps: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    document.getElementById("pro-card").scrollIntoView({ block: "end", inline: "nearest" });
                })
            }
        },
        procedure_save() {
            let data = window.webix.$$('procedure').serialize(null, true);
            this.saveProceduresTable(data)
        },
        delete_array () {
            console.log(this.delete_array)
            if(this.delete_array.length > 1) {
                //disable checkboxes
            }
        },
        canShowHeaderFilters() {
            this.$nextTick(() => {
                //* check if ref is valid (should be)
                if (this.$refs[this.table_uid]) {
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                    
                }
            })
        },
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        ...webixTableState,
        ...adminComputed,
        //* table computed methods
        columns(){
            let el = this;
            //* get ref of current scope
            let values_columns = [
                { id: 'step_order', header: 'Order', editor: 'text', fillspace: 0.1, visibility: ['edit'], template:function(item) {
                    if(item.step_order == undefined) {
                        return ''
                    } else {
                        el.step_order = item.step_order
                        return item.step_order
                    }
                }},
                { id: 'name', editor: 'text', fillspace: 0.1, searchable: true,  header: el.canShowHeaderFilters ? ["Procedure Steps", { content: "textFilter" }] : ["Procedure Steps"]},
                { id: "owner_user_id", editor: "combo", minWidth: 120, options: this.userList,
                    header: 'Procedure Owner',
                    tooltip: '',
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                { id: 'consult_user_id', header: 'Consulted', editor: "combo", minWidth: 120, options: this.userList,
                    tooltip: '',
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                { id: 'inform_user_id', header: 'Informed',  editor: "combo", minWidth: 120, options: this.userList,
                    tooltip: '',
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                { id: 'data_field_name', header: 'Data Field Name', editor: 'text', fillspace: 0.1,},
                // { id: 'system_id', header: 'System', editor: "combo", minWidth: 120, options: this.systems_data,
                //     tooltip: '',
                //     suggest:{
                //         view:"suggest",
                //         filter:function(item, value){
                //             let text = value.toLowerCase();
                //             let name = item.value.toLowerCase();
                //             if(name.includes(text)){
                //                 return true;
                //             }
                //             else{
                //                 return false;
                //             }
                //         },
                //         body:{
                //             view:"list",
                //             data:this.systems_data,
                //             template:"#value#",
                //             yCount:10
                //         }
                //     },
                // },
                { id: 'system_id', header: 'System', minWidth: 120, editor: true,
                    template: function (obj) {
                        let options = el.teams.systems;                  
                        let index = _.findIndex(options, {id: Number(obj.system_id)});
                        console.log(index)
                            let name = '' 
                            if(index != -1){
                               name = options[index].name;
                            } else if (obj.system_id) {
                                name = obj.target_graph.name
                            }

                            if(name == '' && !obj.is_empty) {
                              name = "No System Selected"
                            }
                        if (el.procedures_view_mode == 'edit' && !obj.is_empty) {
                            return `
                                    <span class="show_hierarchy_text">
                                        <i class="me-1 show_systems_modal fas fa-pen cursor-pointer"></i>
                                        ${name}
                                    </span>`
                        }
                        else {
                            return `
                                    <span>
                                        ${name}
                                    </span>`
                        }
                    }
                },
                { id: 'system_section', header: 'System Section', editor: 'text', fillspace: 0.1,},
                { id: 'system_sub_section', header: 'System Sub Section', editor: 'text', fillspace: 0.1,}
            ];
            return values_columns;
        },
        simple_columns(){
            let el = this;
            //* get ref of current scope
            let values_columns = [
                { id: 'name', editor: 'text', fillspace: 0.1, searchable: true,  header: el.canShowHeaderFilters ? ["Procedure Steps", { content: "textFilter" }] : ["Procedure Steps"]},
                { id: "owner_user_id", minWidth: 120, header: 'Procedure Owner', 
                    template:function(obj){
                        if(obj.owner_user_id != undefined){
                            let index = _.findIndex(el.all_users, {id: obj.owner_user_id });
                            let name = 'No User Linked';
                            if(index != -1){
                                name = el.levelEmployeesData[index].name;
                            }

                            return `<span>${name}</span>`
                        }
                        else{
                            if(!obj.is_empty) {
                                return 'No User Linked'
                            } else {
                                return ''
                            }
                            
                        }
                    },
                },
                { id: "consult_user_id", minWidth: 120, header: 'Consulted', 
                    template:function(obj){
                        if(obj.consult_user_id != undefined){
                            let index = _.findIndex(el.all_users, {id: obj.consult_user_id });
                            let name = 'No User Linked';
                            if(index != -1){
                                name = el.levelEmployeesData[index].name;
                            }

                            return `<span>${name}</span>`
                        }
                        else{
                            if(!obj.is_empty) {
                                return 'No User Linked'
                            } else {
                                return ''
                            }
                        }
                    },
                },
                { id: 'inform_user_id', header: 'Informed', minWidth: 120,
                    template:function(obj){
                        if(obj.inform_user_id != undefined){
                            let index = _.findIndex(el.all_users, {id: obj.inform_user_id });
                            let name = 'No User Linked';
                            if(index != -1){
                                name = el.levelEmployeesData[index].name;
                            }

                            return `<span>${name}</span>`
                        }
                        else{
                            if(!obj.is_empty) {
                                return 'No User Linked'
                            } else {
                                return ''
                            }
                        }
                    },
                },
                { id: 'data_field_name', header: 'Data Field Name', editor: 'text', fillspace: 0.1,
                    template: function(obj) {
                        console.log(obj)
                        if(obj.data_field_name) {
                            return obj.data_field_name
                        } else {
                            if(obj.is_empty) {
                                return ''
                            } else {
                                return 'No Data Field Name'
                            }
                            
                        }
                    }},
                // { id: 'system_id', header: 'System', editor: "combo", minWidth: 120, options: this.systems_data,
                //     tooltip: '',
                //     suggest:{
                //         view:"suggest",
                //         filter:function(item, value){
                //             let text = value.toLowerCase();
                //             let name = item.value.toLowerCase();
                //             if(name.includes(text)){
                //                 return true;
                //             }
                //             else{
                //                 return false;
                //             }
                //         },
                //         body:{
                //             view:"list",
                //             data:this.systems_data,
                //             template:"#value#",
                //             yCount:10
                //         }
                //     },
                // },
                { id: 'system_id', header: 'System', minWidth: 120, editor: true,
                    template: function (obj) {
                        let options = el.teams.systems;                  
                        let index = _.findIndex(options, {id: Number(obj.system_id)});
                        console.log(index)
                            let name = '' 
                            if(index != -1){
                               name = options[index].name;
                            } else if (obj.system_id) {
                                name = obj.target_graph.name
                            }

                            if(name == '' && !obj.is_empty) {
                              name = "No System Selected"
                            }
                        if (el.procedures_view_mode == 'edit' && !obj.is_empty) {
                            return `
                                    <span class="show_hierarchy_text">
                                        <i class="me-1 show_systems_modal fas fa-pen cursor-pointer"></i>
                                        ${name}
                                    </span>`
                        }
                        else {
                            return `
                                    <span>
                                        ${name}
                                    </span>`
                        }
                    }
                },
                { id: 'system_section', header: 'System Section', editor: 'text', fillspace: 0.1,  template: function(obj) {
                        console.log(obj)
                        if(obj.system_section) {
                            return obj.system_section
                        } else {
                            if(obj.is_empty) {
                                return ''
                            } else {
                                return 'No System Section'
                            }
                            
                        }
                    }},
                { id: 'system_sub_section', header: 'System Sub Section', editor: 'text', fillspace: 0.1, template: function(obj) {
                        console.log(obj)
                        if(obj.system_sub_section) {
                            return obj.system_sub_section
                        } else {
                            if(obj.is_empty) {
                                return ''
                            } else {
                                return 'No System Sub Section'
                            }
                            
                        }
                    }}
            ];
            return values_columns;
        },
        default_values(){
            let el = this;
            return [    
                { id : "step_order", value: el.step_order + 1 },
            ]
        },
        isCompanyLevel(){
           return this.company_id == this.selected_level.id;
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_systems_modal':function(e, id){
                    let item = this.getItem(id);
                    console.log(item);
                    el.showSystemsModal(item);
                },
            }
            return onClick;
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        systems_data(){
            let systems = [];
            _.forEach(this.teams.systems, function(value) {
                systems.push({id: value.id, value: value.name});
            });
            return systems;
        },
        procedure_data(){
            if(this.processMapData.length != 0 && this.processMapData != undefined) {
                let procedures = []
                this.processMapData.forEach(item => {
                    console.log(item)
                    if(item.id == this.selected_process_map_process) {
                        item.steps.forEach(step => {
                            console.log(JSON.parse(JSON.stringify(step)))
                            step.procedure_steps.forEach(procedure => {
                                if(procedure.process_step_id == this.selected_process_map_process_steps) {
                                    console.log('hitting here')
                                    procedures.push(procedure)
                                }
                            })
                        })
                    }
                })
                procedures = _.orderBy(procedures, ['step_order'], ['asc', 'desc']);
                return procedures
            } else {
                return []
            }
        },
        table_validation_rules(){
            return {
                "step_order": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "name": function(item, obj){
                    
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "owner_user_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
        canShowHeaderFilters() {
            console.log(JSON.parse(JSON.stringify(this.table_states)))
            if (this.table_states['procedure'] == undefined) {
                return false;
            }
            else {
                return this.table_states['procedure'].show_datatable_filters;
            }
        },
    },
    methods:{
        ...profileMethods,
        ...adminMethods,
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        //* table event handlers
        selectedRowEvent(row){
            console.log(row)
            //* set selected row id  in your vuex store
            // if(row !== null){
            //     this.setProcessMapProcedure(row.id);
                
            // } else {
            //     this.setProcessMapProcedure(-1);
            // }
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
            if(value) {
                this.$emit('changes')
            } 
        },
        handlePlanChangedEvent(newID){
            console.log(newID)
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['system_id'] = Number(newID);
            console.log(this.modal_payload.id);
            console.log(item);
            table.updateItem(this.modal_payload.id, item);
            this.$bvModal.hide('systemsModal')
        },
        showSystemsModal(payload){
                this.modal_payload = _.cloneDeep(payload);
                console.log(payload);
                if(payload.id) {
                    let options = this.teams.systems;
                    let index = _.findIndex(options, {id: Number(payload.system_id)});
                    if(index != -1){
                        let system = options[index];
                        this.modal_payload['system_name'] = system.name
                        this.modal_payload['level_id'] = system.level_id
                    } else {
                        this.modal_payload['system_name'] = 'No System Selected'
                        this.modal_payload['level_id'] = this.selected_level.id
                    }
                } else {
                    this.modal_payload['system_name'] = 'No System Selected'
                    this.modal_payload['level_id'] = this.selected_level.id
                }
               
                this.modal_payload['modal_type'] = 'Systems'
                //this.modal_payload['parent_id'] = payload.target_id;
                this.show_systems_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('systemsModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "systemsModal") {
                            this.show_systems_modal = false;
                            this.modal_payload = null;
                        }
                    });
                })
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        deleteProceduresTable(data) {
            let promises = [];
            data.forEach(item => {
                promises.push(this.deleteProcedures({ step_id: item.id }));
            })
            this.is_loading = true;
            Promise.all(promises)
                .then(() => {
                    this.$emit('deleted');
                    this.is_loading = false;
                })
                .catch(() => {
                    this.is_loading = false;
                })
        },
        saveProceduresTable(data){
            let result = window.webix.$$(this.table_uid).validate();
            console.log(result)
            if(result != true){
                Swal.fire("Invalid rows found.", "", "warning")
            } else {
            this.is_loading = true;
            let rows_to_save = [];
            data.forEach( row => {
                
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        step_order: Number(row.step_order),
                        name: row.name,
                        owner_user_id: row.owner_user_id,
                        inform_user_id: row.inform_user_id ? Number(row.inform_user_id) : null,
                        consult_user_id: row.consult_user_id ? Number(row.consult_user_id) : null,
                        data_field_name: row.data_field_name,
                        system_id: row.system_id,
                        system_section: row.system_section,
                        system_sub_section: row.system_sub_section
                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                process_step_id: this.selected_process_map_process_steps,
                procedure_steps: rows_to_save,
            }
            this.is_loading = true;
            console.log(params);
            this.saveProcedures(params).then(()=>{
                    let params = {
                        user_id: this.selected_user_id, 
                        level_output_id: this.selected_process_map_item_id
                    }
                    this.loadProcessMapData(params).then(() => {
                        this.is_loading = false;
                            window.webix.$$('procedure').table_mode = 'view'
                            this.$emit('saved')
                            this.changes_made_flag = false
                    })                    
                })
            }
        },
        closeTable() {
            this.setProcessMapProcessSteps(-1);
        }
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
        
    },
    mounted(){
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
/* .procedure-card .card-body {
    margin-left: 0 !important;
    padding-left: 0 !important;
} */

.procedure-card .card-header-text {
    min-height: 24px
}

/* .procedure-card .tableEditMode .webix_dtable {
    box-shadow: -1.8px 0px 3px 1px dodgerblue !important;
} */
</style>