<template>
    <div>
        <b-card class="w-100">
        <CardHeader>
                <template #title>
                    Product/Service/Outcome
                    <span v-if="selected_process_map_item_id != -1">
                        &nbsp;>&nbsp;
                    </span>
                    <span class="text-primary">{{ getItemName }}</span>
                    <span v-if="selected_process_map_process != -1">
                        &nbsp;>&nbsp;
                    </span>
                    <span class="text-primary">{{ getProcessName }}</span>
                    <span v-if="selected_process_map_process_steps != -1">
                        &nbsp;>&nbsp;
                    </span>
                    <span class="text-primary">{{ getProcessStepName }}</span>
                    
                </template>
        
                <template #buttons>
                    <span>
                        <b-button @click="resetView()" size="sm" variant="primary" v-if="selected_process_map_item_id != -1" right class="me-2 dropdown-menu-end">
                            Reset View
                        </b-button>
                        <b-button @click="saveTableRows()" size="sm" variant="primary" v-if="changes_made_flag" right class="me-2 dropdown-menu-end">
                            Save Changes
                        </b-button>
                        <b-button size="sm" @click="showDiscardPrompt()" variant="danger" v-if="changes_made_flag" class="me-2">
                            Discard Changes
                        </b-button>
                        <b-button @click="showDeletePrompt" size="sm" variant="danger" v-if="table_view_mode ==='delete'" right class="me-2 dropdown-menu-end">
                            <strong>Delete Selected Rows</strong>
                        </b-button>
                        <span class="table-actions-btn-group btn-group">
                            <b-button ref="viewButton" @click="setMode('view')" size="sm" class="no-focus" :variant="pretty_mode == false && table_view_mode ==='view' ? 'info':'outline-light'" v-b-tooltip.hover.bottomright title="View Table">
                                    <font-awesome-icon icon="fa-solid  fa-table" />
                            </b-button>
                            <!-- <b-button ref="editButton" @click="setSingleMode('edit')" size="sm" class="no-focus" :variant="pretty_mode == false && table_view_mode ==='edit' ? 'primary':'outline-light'" v-b-tooltip.hover.bottomright title="Edit Table">
                                    <font-awesome-icon icon="fa-solid  fa-pen" />
                            </b-button>
                            <b-button ref="deleteButton" @click="setSingleMode('delete')" size="sm" class="no-focus" :variant="pretty_mode == false && table_view_mode ==='delete' ? 'danger':'outline-light'" v-b-tooltip.hover.bottomright title="Delete">
                                    <font-awesome-icon icon="fa-solid  fa-trash" />
                            </b-button>  -->
                            <b-button ref="searchButton" @click="searchHeaders()" size="sm" class="no-focus" :variant="canShowHeaderFilters ? 'success':'outline-light'" v-b-tooltip.hover.bottomright title="Search">
                                    <font-awesome-icon icon="fa-solid  fa-search" />
                            </b-button>
                            <b-dropdown menu-class="dropdown-menu-end" no-caret class="no-focus" size="sm" variant="outline-light">
                                <template #button-content>
                                    <font-awesome-icon icon="fa-solid  fa-ellipsis-h" />
                                </template>
                                <!-- //* show mode related buttons for the table -->
                                <b-dropdown-item @click="setSingleMode('edit')" :variant="pretty_mode == false && table_view_mode ==='edit' ? 'primary':'outline-light'" title="Edit Table" class="text-info">
                                    <font-awesome-icon class="fa-fw me-1" icon="fa-solid  fa-pen" />
                                    Edit Mode
                                </b-dropdown-item>
                                <b-dropdown-item @click="setSingleMode('delete')" :variant="pretty_mode == false && table_view_mode ==='delete' ? 'danger':'outline-light'" class="text-danger">
                                    <font-awesome-icon class="fa-fw me-1" icon="fa-solid  fa-trash" />
                                    Delete Mode
                                </b-dropdown-item>
                        </b-dropdown>
                        </span>
                        
                    </span>
                    
                    
                </template>
            </CardHeader> 
        </b-card>
        <b-overlay :show="is_loading">
            <!-- <b-row>
                <b-col v-if="selected_process_map_item_id == -1" class="col-12">
                    <items 
                        :items_view_mode="items_view_mode" 
                        :columns_config="false" 
                        :item_delete="item_delete" 
                        :toggle_search="toggle_search" 
                        @changes="changesMade()" 
                        @deleted="refreshItems()"/>
                </b-col>
            </b-row> -->
            <b-row>
                <b-col v-bind:class="[selected_process_map_process == -1 ? 'col-6' : '', selected_process_map_process !== -1 ? 'col-4' : '', selected_process_map_item_id == -1 ? 'col-12' : '']">
                    <items 
                        :items_view_mode="items_view_mode" 
                        :item_delete="item_delete"
                        :item_save="item_save"  
                        :hidden_color="item_color_change" 
                        :toggle_search="toggle_search"
                        :column_config="column_config" 
                        @changes="changesMade()" 
                        @deleted="refreshItems()"
                        @saved="saved()"/>
                </b-col>
                <b-col v-if="selected_process_map_item_id !== -1" v-bind:class="[selected_process_map_process == -1 ? 'col-6' : '', selected_process_map_process !== -1 ? 'col-4' : '',]">
                    <process 
                        :process_view_mode="process_view_mode" 
                        :process_delete="process_delete"
                        :process_save="process_save" 
                        :toggle_search="toggle_search"
                        :hidden_color="process_color_change"  
                        @changes="changesMade()" 
                        @deleted="refreshData()"
                        @saved="saved()" />
                </b-col>
                <b-col v-if="selected_process_map_item_id !== -1 && selected_process_map_process !== -1" cols="4">
                    <processSteps 
                        :processSteps_view_mode="processSteps_view_mode" 
                        :process_steps_delete="process_steps_delete"
                        :process_steps_save="process_steps_save"
                        :hidden_color="process_steps_color_change" 
                        :toggle_search="toggle_search" 
                        @changes="changesMade()" 
                        @deleted="refreshData()"
                        @saved="saved()"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col v-if="selected_process_map_process_steps !== -1 && selected_process_map_process !== -1" cols="12">
                    <procedures 
                        :procedures_view_mode="procedures_view_mode" 
                        :procedure_delete="procedure_delete"
                        :procedure_save="procedure_save"  
                        :toggle_search="toggle_search"  
                        @changes="changesMade()"
                        @deleted="refreshData()"
                        @saved="saved()"
                    />
                </b-col>
            </b-row>
            <b-row>
                <!-- <b-col v-if="selected_process_map_item_id !== -1 && selected_process_map_process !== -1" cols="4">
                    <items 
                        :items_view_mode="items_view_mode" 
                        :columns_config="true" 
                        :item_delete="item_delete" 
                        :hidden_color="changeColor"
                        :toggle_search="toggle_search" 
                        @changes="changesMade()" 
                        @deleted="refreshItems()" />
                </b-col> -->
                <!-- <b-col v-if="selected_process_map_item_id !== -1 && selected_process_map_process !== -1" cols="4">
                    <process 
                        :process_view_mode="process_view_mode" 
                        :process_delete="process_delete" 
                        :hidden_color="changeColor"
                        :toggle_search="toggle_search" 
                        @changes="changesMade()" 
                        @deleted="refreshData()"/>
                </b-col>
                <b-col v-if="selected_process_map_item_id !== -1 && selected_process_map_process !== -1" cols="4">
                    <processSteps 
                        :processSteps_view_mode="processSteps_view_mode" 
                        :process_steps_delete="process_steps_delete" 
                        :hidden_color="changeColor && lastTableColorChange" 
                        :toggle_search="toggle_search" 
                        @changes="changesMade()" 
                        @deleted="refreshData()"
                    />
                </b-col> -->
            </b-row>
            <!-- <b-row>
                <b-col v-if="selected_process_map_process_steps !== -1 && selected_process_map_item_id !== -1 && selected_process_map_process == -1" cols="12">
                    <procedures 
                        :procedures_view_mode="procedures_view_mode" 
                        :procedure_delete="procedure_delete"
                        :toggle_search="toggle_search"  
                        @changes="changesMade()" 
                        @deleted="refreshData()"/>
                </b-col>
            </b-row> -->
            
        </b-overlay>
    </div>
    
</template>

<script>
import { levelComputed ,peopleComputed, profileComputed, profileMethods, adminComputed, adminMethods, levelMethods, activityComputed, activityMethods, webixTableState } from '@/state/helpers'
import items from '../processMapTables/PMitems.vue'
import processSteps from '../processMapTables/PMprocessSteps.vue'
import procedures from '../processMapTables/PMprocedures.vue'
import process from '../processMapTables/PMprocess.vue'
import dateMixin from '@/mixins/dateMixin.js'
import CardHeader from '@/components/widgets/cardHeader.vue';
import Swal from 'sweetalert2';
export default {
    mixins:[ dateMixin ],
    name: 'processMap',
    components: {
        items,
        processSteps,
        procedures,
        process,
        CardHeader
    },
    props:{
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'processMap',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        disableEdit: false,
        items_view_mode: 'view',
        processSteps_view_mode: 'view',
        procedures_view_mode: 'view',
        process_view_mode: 'view',
        pretty_mode: false,
        item_delete: false,
        item_save: false,
        process_delete: false,
        process_save: false,
        procedure_delete: false,
        procedure_save: false,
        process_steps_delete: false,
        process_steps_save: false,
        toggle_search: false,
        changeColor: false,
        item_color_change: false,
        process_color_change: false,
        process_steps_color_change: false,
        column_config: false
    }),
    watch: {
        selected_process_map_item_id() {
            if(this.selected_process_map_item_id !== -1) {
            this.column_config = true
            let params = {
                user_id: this.selected_user_id, 
                level_output_id: this.selected_process_map_item_id
            }
            this.loadProcessMapData(params).then(() => {
            })
            this.setProcessMapProcessSteps(-1);
            this.setProcessMapProcess(-1);
            } else {
                this.column_config = false
            }
            //this.column_config = true
            // if(window.webix.$$('items')) {
            //         window.webix.$$('items').refresh();
            //         this.column_config = true
            //     }
            //     if (this.$refs['items']) {
            //         //*call method from table component
            //         this.column_config = true
            //         this.$refs['items'].refreshVisibleColumns()
            //         this.column_config = true
            //     }
            this.setMode('view')
        },
        selected_level() {
            this.is_loading = true
            this.setProcessMapProcessSteps(-1);
            this.setMode('view');
            this.$nextTick(() => {
                if(window.webix.$$('items')) {
                    window.webix.$$('items').refresh();
                    this.is_loading = false
                }
            })
        }, 
        selected_process_map_process() {
            this.setProcessMapProcessSteps(-1);
            this.setMode('view');
            this.$nextTick(() => {
                if(window.webix.$$('items')) {
                    window.webix.$$('items').refresh();
                }
            })
        },
        canShowHeaderFilters() {
            this.$nextTick(() => {
                //* check if ref is valid (should be)
                if (this.$refs['items']) {
                    //*call method from table component
                    this.$refs['items'].refreshVisibleColumns()
                }
            })
        },
        
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        ...adminComputed,
        ...activityComputed,
        ...webixTableState,
        canShowHeaderFilters() {
            console.log(JSON.parse(JSON.stringify(this.table_states)))
            if (this.table_states['items'] == undefined) {
                return false;
            }
            else {
                return this.table_states['items'].show_datatable_filters;
            }
        },
        getItemName() {

            // if(this.selected_process_map_item_id != null) {
            //     let item = this.processMapData.forEach( item => {
            //         if(item.id == this.selected_process_map_item_id) {
            //             return item
            //         }    
            //     });
            //     return item.name
            // } else {
            //     return ''
            // }

            if(this.teams.outputs == undefined){
                return '';
            }
            else{
                if(this.selected_process_map_item_id != -1) {
                    let foundItem = null
                    let teams = this.teams.outputs
                    teams.forEach(item => {
                        if(item.id == this.selected_process_map_item_id) {
                            return foundItem = item
                        }   
                    })
                    
                    return foundItem.name
                } else {
                    return ''
                }
            }
        },
        getProcessName() {
            if(this.processMapData.length != 0 && this.selected_process_map_process != -1 && this.processMapData != undefined) {
                console.log(this.processMapData)
                let foundProcess = null
                this.processMapData.forEach(process => {
                    if(process.id == this.selected_process_map_process) {
                        return foundProcess = process
                    }
                })
                return foundProcess.name
            } else {
                return ''
            }
        },
        getProcessStepName() {
            if(this.processMapData.length != 0 && this.selected_process_map_process != -1 && this.selected_process_map_process_steps !=-1 && this.processMapData != undefined) {
                let steps = []
                this.processMapData.forEach(item => {
                    if(item.id == this.selected_process_map_process) {
                        steps = item.steps
                    }
                })
                let foundStep = null
                steps.forEach(step => {
                    if(step.id == this.selected_process_map_process_steps) {
                       return foundStep = step
                    }
                })
                
                return foundStep.name
            } else {
                return ''
            }
        },
    },
    methods:{
        ...profileMethods,
        ...adminMethods,
        ...levelMethods,
        ...activityMethods,
        saved() {
           this.setMode('view')
           this.changes_made_flag = false
        },
        setMode(mode) {
            this.item_color_change = false
            this.process_color_change = false
            this.process_steps_color_change = false
            this.items_view_mode = mode;
            this.processSteps_view_mode = mode;
            this.procedures_view_mode = mode;
            this.process_view_mode = mode;
            this.table_view_mode = mode;
            // window.webix.$$('items').table_mode = mode
           
        },
        setSingleMode(mode) {
            if(window.webix.$$('procedure')) {
                this.item_color_change = true
                this.process_color_change = true
                this.process_steps_color_change = true
                this.procedures_view_mode = mode;
                this.table_view_mode = mode;
            } else if(window.webix.$$('processSteps')) {
                this.item_color_change = true
                this.process_color_change = true
                this.processSteps_view_mode = mode;
                this.table_view_mode = mode;
            } else if(window.webix.$$('process')) {
                this.item_color_change = true
                this.process_view_mode = mode;
                this.table_view_mode = mode;
            } else if(window.webix.$$('items')) {
                this.items_view_mode = mode;
                this.table_view_mode = mode;
            }
            
        },
        showDiscardPrompt(){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    this.setMode('view');
                    this.$nextTick(() => {
                        //window.webix.$$('items').restoreTreeState();
                       // window.webix.$$('items').clearAll()
                    })
                    
                    this.changes_made_flag = false
                }
            });
        },
        changesMade() {
            this.changes_made_flag = true
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected Row?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    this.deleteTableRows()
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        deleteTableRows() {
            if(window.webix.$$('procedure')) {
                this.procedure_delete = !this.procedure_delete
            } else if(window.webix.$$('processSteps')) {
                this.process_steps_delete = !this.process_steps_delete
            } else if(window.webix.$$('process')) {
                this.process_delete = !this.process_delete
            } else if(window.webix.$$('items')) {
                this.item_delete = !this.item_delete
            }
            this.setMode('view')
        },
        saveTableRows() {
            if(window.webix.$$('procedure')) {
                this.procedure_save = !this.procedure_save
            } else if(window.webix.$$('processSteps')) {
                this.process_steps_save = !this.process_steps_save
            } else if(window.webix.$$('process')) {
                this.process_save = !this.process_save
            } else if(window.webix.$$('items')) {
                this.item_save = !this.item_save
            }
           
        },
        resetView() {
            
            if(window.webix.$$('process')) {
                window.webix.$$('process').refresh();
            }
            if(window.webix.$$('processSteps')) {
                window.webix.$$('processSteps').refresh();
            }
            if(window.webix.$$('procedure')) {
                window.webix.$$('procedure').refresh();
            }
            this.setMode('view');
            this.changes_made_flag = false
            this.setProcessMapProcessSteps(-1);
            this.setProcessMapProcess(-1);
            this.setProcessMapID(-1).then(() =>{
                this.$nextTick(() => {
                    if(window.webix.$$('items')) {
                        window.webix.$$('items').refresh();
                    }
                })
            });
        },
        refreshData() {
            if(this.selected_process_map_item_id != -1) {
                let params = {
                    user_id: this.selected_user_id, 
                    level_output_id: this.selected_process_map_item_id
                }
                this.loadProcessMapData(params).then(() => {
                this.$nextTick(()=> {
                    this.setMode('view')
                })
                this.changes_made_flag = false
                })   
            }    
        },
        refreshItems() {
            this.loadTeams(this.selected_level.id)
            .then(()=>{
                this.is_loading = false;
                window.webix.$$('items').table_mode = 'view'
                this.changes_made_flag = false       
            })
        },
        searchHeaders() {
            this.toggle_search = !this.toggle_search
            window.webix.$$('items').refresh()
        }
    },
    beforeDestroy() {
        this.resetView()
    },
    mounted(){
        this.loadPerformanceTargets(this.selected_level.id).then(()=>{
            this.getKeyResultUnitAPI().then(()=> {
            })
        })
    },
}



</script>

<style>
</style>