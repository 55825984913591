<template>
    <div>
        <Table
            :uid="table_uid"
            :ref="table_uid" 
            :is_loading="is_loading"
            :data="job_skills"
            :columns="columns"
            :simple_columns="simple_columns"
            :table_mode="table_view_mode"
            :context_menu_items="['View', 'Edit', 'Delete']"
            :pager_config=5
            :validation="table_validation_rules"
            @updateViewMode="updateViewMode"
            @selectedRowEvent="selectedRowEvent"
            @saveTable="saveTable"
            @onDeleteArrayUpdate="onDeleteArrayUpdate"
            @deleteRows="deleteRow"      
            >
            <template #title>
                Skills
                &nbsp;
                >
                &nbsp;
                <span class="text-primary">{{selectedJobName}}</span>
            </template>
            <!-- <template #buttons>
                    <b-button title="Add Skill Categories" class="m-0 me-1" size="sm" variant="primary" @click="showSkillCategoriesModal()">
                           Add Skill Categories
                    </b-button>
            </template> -->
        </Table>
        
    </div>
</template>

<script>
//import Table from '@/components/widgets/table.vue'
import Table from '@/components/widgets/table/table.vue'

//* misc imports
import Swal from 'sweetalert2';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

import {
    levelComputed,
    peopleMethods,
    peopleComputed,
    profileComputed,
    profileMethods
    } from '@/state/helpers'
export default {
    components:{
        Table,
    },
    data:() => ({
        //* Variables for the Table Component
        table_uid: 'jobSkillsTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        changes_made_flag: false, //* true if the table component detects a change in a cell value
        is_loading: false,
        //* ----
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        competency_required() {
            let values = ['1','2','3','4','5','6','7','8','9','10']
            return values
        },
        category_options(){
            let names = [];
            
            if(this.skillCategories != undefined){
                this.skillCategories.forEach( item =>{
                    names.push({id: item.id, value: item.name});
                })
            }   
            return names;
        },
        job_skills(){
            //* returns an array of all the skills for the selected job from the jobs table
            if(this.selectedJobId == -1 || this.companyJobs.length == 0){
                return [];
            } else {
                let item =  this.companyJobs.find( job => {
                    return job.id == this.activeJobId
                });
                if(item != undefined) {
                    return item.skills
                } else {
                    return []
                }
            }
        },

        selectedJobName(){
            //* get job name by using the activeJobId from vuex store ( peopleComputed -> activeJobId ) ;
            let job_title = '';
            let index = _.findIndex(this.companyJobs, { id: this.activeJobId});
            if(index != -1){
                job_title = this.companyJobs[index].title;
            }
            return job_title;
        },

        //* column config for table component
        columns(){
            let el=this
            return [
                { id: 'skill_name', header: 'Skill Name', fillspace: 1, editor: "text",},
                { id: 'category_id', header: 'Category', fillspace: 1, editor: "combo", options: el.category_options, 
                    suggest:{
                        view:"suggest",
                        body:{
                            view:"list",
                            data: el.category_options,
                            template:"#value#",
                            yCount:10
                        }
                    },
                },
                { id: 'competency_required', header: 'Competency Required', fillspace: 1, editor: "combo", options: el.competency_required, css: 'centerValues',
                    suggest:{
                        view:"suggest",
                        body:{
                            view:"list",
                            data: el.competency_required,
                            template:"#id#",
                            yCount:10
                        }
                    },
                },
                { id: 'skill_description', header: 'Description', fillspace: 1, editor: "text",},
            ]
        },
        simple_columns(){
            let el=this
            return [
                { id: 'skill_name', header: 'Skill Name', fillspace: 1, editor: "text",},
                { id: 'category_id', header: 'Category', fillspace: 1,
                        template:function(obj){
                        console.log(obj);
                        if(obj.category_id != undefined){
                            let index = _.findIndex(el.skillCategories, {id: obj.category_id });
                            let name = '';
                            if(index != -1){
                                name = el.skillCategories[index].name;
                            }
                            return name
                        }
                    },
                },
                { id: 'competency_required', header: 'Competency Required', fillspace: 1, css: 'centerValues'},
                { id: 'skill_description', header: 'Description', fillspace: 1, editor: "text",},
            ]
        },
        table_validation_rules(){
            return {
                "skill_name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "category_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "competency_required": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "skill_description": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            }
        }
    },
    methods:{
        ...peopleMethods,
        ...profileMethods,

        //* Table Component event handlers
        // updateViewMode(type){
        //     switch(type){
        //         case 'delete':{
        //             this.toggleDeleteMode();
        //             break;
        //         }
        //         case 'edit':{
        //             this.toggleEditMode();
        //             break;
        //         }
        //         case 'drag':{
        //             this.toggleDragMode();
        //             break;
        //         }
        //         case 'view':{
        //             this.toggleViewMode();
        //             break;
        //         }
        //     }
        // },
        updateViewMode(mode){
            this.table_view_mode = mode;
        },
        getCategoryOptions(){
            let names = [];
            
            if(this.skillCategories != undefined){
                this.skillCategories.forEach( item =>{
                    names.push({category_id: item.id, value: item.name});
                })
            }   
            return names;
        },
        showDiscardPrompt(type){
            //* ask a user if they would like to cancel unsaved changes
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        //* table events
        selectedRowEvent(row){
            console.log('Use me -> ', row)
            //* Event returns the selected row
            //* here we save the seelcted row object or id in the people vuex store
            /*let index = _.findIndex(this.formatedObjectiveData, { id: row.id });
            if(index != -1){
                let objective = _.cloneDeep(this.formatedObjectiveData[index]);
                this.$eventHub.$emit('selectedObjective', objective);
                this.setSelectedObjective(objective);
            }*/
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        saveTable(){
            //* handle validation/serialization
            let table = window.webix.$$(this.table_uid);//* get main table for component
            //table.editStop();//* close any editor cell and commit the changes to webix internal store
            this.$nextTick(()=>{
                let params = {
                    job_id: this.activeJobId,
                    skills: []
                }
                let rows = table.serialize(true); //* pass true to parse all data;
                rows.forEach( row => {
                    if(!row.is_empty){
                        params.skills.push({
                            id: row.is_new ? -1 : row.id,
                            category_id: Number(row.category_id),
                            competency_required: Number(row.competency_required),
                            skill_name: row.skill_name,
                            skill_description: row.skill_description,
                        })
                    }
                });
                //this.is_loading = true
                //let params1 = {"job_id":"7","skills":[{"id":"-1","category_id":"1","competency_required":"10","skill_name":"New Skill","skill_description":"Its a skill"}]}

                if(params.skills.length < 3) {
                    Swal.fire({
                        title: "Please ensure a job has 3 skills",
                        //text: "3 Skills are required",
                        icon: "warning",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        if (result.value) {
                            //* If user selects yes
                            //this.table_view_mode = type;
                        }
                    });
                } else {
                    this.saveJobSkill(params)
                    .then(()=>{
                        this.loadCompanyJobs(this.company_id)
                        .then(()=>{
                            this.table_view_mode = 'view'
                            this.$refs[this.table_uid].table_mode = 'view';
                        })
                    })
                }
            })
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected Skills?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(data){
            console.log(JSON.parse(JSON.stringify(data)))
            let table = window.webix.$$(this.table_uid);
            let current_length = 0
            let rows = table.serialize(true); //* pass true to parse all data;
                rows.forEach( row => {
                    if(!row.is_empty){
                       current_length = current_length +1
                    }
                });
            
            let delete_length = data.length
            let promises = [];
            console.log(current_length);
            console.log(delete_length)
            if((current_length - delete_length) < 3) {
                Swal.fire({
                    title: "Please ensure the job has 3 skills",
                    //text: "3 Skills are required",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        //this.table_view_mode = type;
                    }
                });
            } else {
                data.forEach( item => {
                promises.push(this.deleteJobSkill({'skill_id': item.id}));
                })
                this.is_loading = false;
                Promise.all(promises)
                .then(()=>{
                    this.delete_array = [];
                    //console.log('Action Plan table ref',this.$refs[this.table_uid])
                    this.loadCompanyJobs(this.company_id)
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.is_loading = false;
                })
            }
            
        },
    },
    beforeMount(){
        this.table_view_mode = 'view';
    },
    mounted(){
    },
}
</script>

<style>
.centerValues {
    text-align: center;
}
</style>