<template>
    <div>
        <Table
            :uid="table_uid" 
            :is_loading="is_loading"
            :data="offboarding_steps"
            :ref="table_uid"
            :columns="columns"
            :table_mode="table_view_mode"
            :validation="validation_rules"
            :only_editable_columns="true"
            :context_menu_items="['View', 'Edit', 'delete']"
            :table_modes="['view', 'edit', 'delete']"
            :pager_config=12
            @updateViewMode="updateViewMode"
            @selectedRowEvent="selectedRowEvent"
            @onDataUpdate="onChangesMade"
            @saveTable="saveTable"
            @deleteRows="deleteRow"
            >
        <template #title>
            Offboarding Steps
        </template>
        </Table>
    </div>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'

//* misc imports
import Swal from 'sweetalert2';
import _ from 'lodash';

import {
    levelComputed,
    peopleMethods,
    peopleComputed,
    } from '@/state/helpers'
export default {
    components:{
        Table,
    },
    data:() => ({
        //* Variables for the Table Component
        table_uid: 'offboardingTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        changes_made_flag: false, //* true if the table component detects a change in a cell value
        is_loading: false,
        //* ----
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,

        offboarding_steps(){
            //* return the offboarding steps for a job
            if(this.selectedJobDetails == null){
                return [];
            }
            else{
                return this.selectedJobDetails.offboarding_steps;
            }
        },

        //* column config for table component
        columns(){
            //* get current scope of component because webix has its own internal scope (this) that refers to itself
            let el = this;
            return [
                { header: 'Step', id: 'step_order', fillspace: 0.2, editor: "text",},
                { header: 'Onboarding Procedure', id: 'procedure', fillspace: 1, editor: "text",},
                {
                    header: 'Responsible Person', id: 'responsible_user_id', fillspace: 0.6, editor: "combo", options: el.userList,
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if(name.includes(text) || email.includes(text)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data: el.userList,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
            ]
        },
        activeJobID(){
            //* return the selected rows id from vuex store
            //* used to sync table selected row to the row in vuex
            // TODO - hook up to vuex
            return -1;
        },
        validation_rules(){
            return {
                'step_order'(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            if(isNaN(item) == false){
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    }
                },
                'procedure'(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                'responsible_user_id'(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            }
        },
        //* misc computed
        userList(){
            //* return a list of users with their name, email and id (the dropdown column to
            //* search users will search the value and email fields)
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
    },
    methods:{
        ...peopleMethods,

        //* Table Component event handlers
        updateViewMode(type){
            switch(type){
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'drag':{
                    this.toggleDragMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleDragMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('drag')
            }
            else{
                this.table_view_mode = 'drag';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }

        },
        showDiscardPrompt(type){
            //* ask a user if they would like to cancel unsaved changes
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected items?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                    //TODO - add delete row method
                }
            });
        },
        //* table events
        selectedRowEvent(row){
            console.log(row)
            //* Event returns the selected row
            /*let index = _.findIndex(this.formatedObjectiveData, { id: row.id });
            if(index != -1){
                let objective = _.cloneDeep(this.formatedObjectiveData[index]);
                this.$eventHub.$emit('selectedObjective', objective);
                this.setSelectedObjective(objective);
            }*/
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        deleteRow(data){
            let promises = [];
            data.forEach( item => {
                let params = {check_id: item.id};
                promises.push(this.deleteOffboardingCheck(params));
            })

            this.is_loading = true;
            Promise.all(promises)
            .then(()=>{
                this.loadOffboardingChecksForJob(this.activeJobId)
                .then(()=>{
                    this.is_loading = false;
                    this.changes_made_flag = false;
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.toggleViewMode();
                })
            })
        },
        saveTable(){
            //* handle validation/serialization
            //* handle validation/serialization
            let table = window.webix.$$(this.table_uid);
            table.editStop();
            this.$nextTick(()=>{
                const all_data = table.serialize(true);

                let offboarding_checks_to_save = [];
                //let onboarding_tracking_to_save = [];

                all_data.forEach( row => {
                    if(!row.is_empty){
                        let newRow = row.is_new;

                        const offboardingChecksParams = {
                            id: newRow ? -1 : row.id,
                            step_order: row.step_order,
                            procedure: row.procedure,
                            responsible_user_id: row.responsible_user_id
                        };
                        offboarding_checks_to_save.push(offboardingChecksParams);
                    }
                })

                const offboarding_checks_params = {
                    job_id: this.activeJobId,
                    checks: offboarding_checks_to_save,
                }
                this.is_loading = true;
                this.saveOffboardingChecks(offboarding_checks_params)
                .then(()=>{
                    this.loadOffboardingChecksForJob(this.activeJobId)
                    .then(()=>{
                        this.is_loading = false;
                        this.changes_made_flag = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                        this.toggleViewMode();
                    })
                })
                .catch(()=>{
                    this.is_loading = false;
                })
            })
        },
    },
    mounted(){},
}
</script>

<style>

</style>