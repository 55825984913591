import store from '@/state/store'

export default {
    methods:{
        //* Sets the last used milestone as selected in the 'pinned milestone' dropdown menu
        //* checks local storage for a last used milestone and then uses the pinned milestone as a fallback option
        restorePinnedMilestone(){
            return new Promise( resolve => {
                let admin_store = store.state.admin;
                //* get last used milestone from local storage
                const local_storage_milestone = window.localStorage.getItem('milestone_id');
                //* get default pinned milestone as fallback (if no previous milestone was set)
                const default_milestone = admin_store.default_milestone ? admin_store.default_milestone.id : -1;
                if(local_storage_milestone && local_storage_milestone != -1){
                    this.setPinnedMilestoneId(local_storage_milestone);
                    resolve();
                }
                else if(default_milestone != -1){
                    this.setPinnedMilestoneId(default_milestone);
                    resolve();
                }
                else{
                    resolve();
                }
            })
        }
    }
}