<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :id="table_uid"
                    :ref="table_uid"
                    :data="values_data"
                    :is_loading="is_loading"
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    only_editable_columns
                    column_autoheight_key="how"
                    :active_id="active_id"
                    :validation="table_validation_rules"
                    @selectedRowEvent="selectedRowEvent"
                    @saveTable="saveTable"
                    @deleteRows="deleteRow" 
                    :edit_permissions="{manager:'values'}"
                    :required_permissions="['values']"
                    :hide_action_buttons="!isCompanyLevel"   
                    guideline_key="values_table"           
                >
                    <template #title>
                        Values
                    </template>
                </Table>
            </b-card>
        </b-col>
        <b-col cols="12">
            
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods } from '@/state/helpers'
import dateMixin from '@/mixins/dateMixin.js'
import Swal from 'sweetalert2'
export default {
    mixins:[ dateMixin ],
    name: 'values',
    components: {
        Table,
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'values',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        disableEdit: false
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        //* table computed methods
        columns(){
            // let el = this;
            //* get ref of current scope
            let values_columns = [
                { id: 'value', header: 'Value', editor: 'text', fillspace: 1,},
                // { id: 'level_id', header: 'Linked Level', fillspace: 1,
                //     template:function(obj){
                //         if(obj.level_id != undefined){
                //             let index = _.findIndex(el.levels, {id: obj.level_id });
                //             let level_name = '';
                //             if(index != -1){
                //                 level_name = el.levels[index].name;
                //             }
                //             //if(el.table_view_mode === 'edit'){
                //             //    //* we only need to show the pencil icon in edit mode
                //             //    return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                //             //}
                //             //else{
                //                 return `<span>${level_name}</span>`
                //             //}
                //         }
                //         else{
                //             return ''
                //         }
                //     },
                // },
                { id: 'how', header: 'How', fillspace: 2, editor: 'textarea' },
            ];
            return values_columns;
        },
        isCompanyLevel(){
           return this.company_id == this.selected_level.id;
        },
        values_data(){
            if(this.teams.values == undefined){
                return [];
            }
            else{
                return this.teams.values;
            }
        },
        table_validation_rules(){
            return {
                "value": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                // "level_id": function(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(item === '' || item == undefined){
                //             return false;
                //         }
                //         else{
                //             return true;
                //         }
                //     }
                // },
                "how": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
    },
    methods:{
        ...profileMethods,
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.active_id = row.id;
            } else {
                this.active_id = -1
            }
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        saveTable(data){
            this.is_loading = true;
            let rows_to_save = [];
            data.forEach( row => {
                
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        level_id: this.selected_level.id,
                        value: row.value,
                        how: row.how,
                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                level_values: rows_to_save,
            }
            this.is_loading = true;
            this.saveValues(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view'
                    })
                })
        },
        deleteRow(data) {
            let promises = [];
            this.is_loading = true;
            data.forEach( item => {
                promises.push(this.deleteValues({id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.loadTeams(this.selected_level.id)
                .then(()=>{
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.is_loading = false;
                })
			})
            .catch(()=>{
                this.is_loading = false;
            })
        },
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
    },
    mounted(){
        //this.is_loading = true
        window.webix.editors.textarea = window.webix.extend({
        render:function(){
            return window.webix.html.create("div", {
                "class":"webix_dt_editor webix_custom_text"
            }, "<textarea>");
        }
        }, window.webix.editors.text);
        // window.webix.$$(this.table_uid).refresh()
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

</style>