<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <div class="d-flex">
                    <b-alert :show="communication_tools_data_check" class="w-100 me-1 ms-1 alert" style="display:flex; justify-content: space-between; align-items: center; height: 44px" variant="primary"><b>No Communication Tools Found</b>
                        <b-button size="sm" style="float: right;" variant="success" @click="showToolsModal">
                            <font-awesome-icon :icon="['fas', 'fa-wrench']" /> Add Tool
                        </b-button>
                    </b-alert>
                </div>
               
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :ref="table_uid"
                    :data="communication_data"
                    :simple_columns="simple_columns"
                    :is_loading="is_loading"
                    :pager_config=10
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    only_editable_columns
                    :active_id="active_id"
                    @updateViewMode="handleTableModeUpdate"
                    :validation="table_validation_rules"
                    :hide_action_buttons="communication_tools_data_check"
                    @selectedRowEvent="selectedRowEvent"
                    @saveTable="saveTable"
                    @deleteRows="deleteRow"
                    :edit_permissions="{manager:'providers-tools'}"
                    :required_permissions="['providers-tools']"
                    guideline_key="communication_tools_table"
                >
                    <template #title>
                        Communication Tools
                    </template>
                    <template #buttons>
                        <b-button v-show="tools_table_view_mode == 'view'" size="sm" variant="primary" title="Manage Tools" @click="showToolsModal" class="me-1" style="display: inline-block">
                                <font-awesome-icon :icon="['fas', 'fa-wrench']" />
                        </b-button>
                    </template>
                </Table>

                <toolsModal
                    v-if="show_tools_modal"
                    @saved="setOptions"
                />

                <!-- <toolTypesModal
                    v-if="show_tool_types_modal"
                    @selectedNewID="handlePlanChangedEvent"
                /> -->
            </b-card>
        </b-col>
        <b-col cols="12">
            
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods } from '@/state/helpers'
import toolsModal from '../../objectives/components/misc/structureModals/toolsModal.vue'
import dateMixin from '@/mixins/dateMixin.js'
import Swal from 'sweetalert2'
import { format, parseISO } from 'date-fns';
export default {
    mixins:[ dateMixin ],
    name: 'communicationTools',
    components: {
        Table,
        toolsModal
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'communicationTools',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        show_tools_modal: false,
        communication_tools_options: [],
        tools_table_view_mode: 'view'
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        //* table computed methods
        columns(){
            //* get ref of current scope
            let el = this;
            let communication_columns = [
                { id: 'client_tool_id', header: 'Tools Name', editor: 'combo', fillspace: 1, options: el.availableNames},
                { id: 'active_date', header: 'Active Date', fillspace: 1, editor: 'date',
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'expiry_date', header: 'Expiry Date', fillspace: 1, editor: 'date',
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
            ];
            return communication_columns;
        },
        simple_columns(){
            let communication_columns = [
                { id: 'name', header: 'Tools Name', fillspace: 1,},
                { id: 'vendor_name', header: 'Vendor', fillspace: 1,},
                { id: 'functionality', header: 'Functionality', fillspace: 1,},
                { id: 'active_date', header: 'Active Date', fillspace: 1,
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'expiry_date', header: 'Expiry Date', fillspace: 1,
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
            ];
            return communication_columns;
        },
        communication_data(){
            if(this.teams.communication_tools == undefined){
                return [];
            }
            else{
                return this.teams.communication_tools;
            }
        },
        communication_tools_data_check() {
            if(this.teams.client_tools == undefined){
                return true;
            }
            else{ 
                if(this.teams.client_tools.length == 0){
                    return true
                } else {
                    return false
                }
            }
        },
        availableNames(){
            if(this.teams.client_tools){
                return this.teams.client_tools.map( item => {
                    console.log(JSON.parse(JSON.stringify(item)));
                    return {
                        id: item.id,
                        value: item.name,
                    }
                })
            }
            else{
                return [];
            }
        },
        availableVendors(){
            if(this.teams.client_tools){
                return this.teams.client_tools.map( item => {
                    console.log(JSON.parse(JSON.stringify(item)));
                    return {
                        id: item.id,
                        value: item.vendor_name,
                    }
                })
            }
            else{
                return [];
            }
        },
        availableFunctionality(){
            if(this.teams.client_tools){
                return this.teams.client_tools.map( item => {
                    console.log(JSON.parse(JSON.stringify(item)));
                    return {
                        id: item.id,
                        value: item.functionality
                    }
                })
            }
            else{
                return [];
            }
        },
        table_validation_rules(){
            let el = this;
            return {
                "client_tool_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            let data = window.webix.$$(el.table_uid).serialize(null, true);
                            let value = false
                            data.forEach(row => {
                                console.log(obj)
                                if(row.client_tool_id == obj.client_tool_id && row != obj) {
                                    console.log("HITTING HERE")
                                    value = true
                                }
                            })
                            if(value) {
                                    let name = ''
                                    el.teams.client_tools.forEach(item => {
                                        if(Number(obj.client_tool_id) == item.id) {
                                            console.log("getting in here")
                                            name = "" + item.name + ""
                                        }
                                    })
                                    console.log(name)
                                    Swal.fire({
                                        title: 'Tool "' + name + '" is already in use on this level',
                                        icon: "warning",
                                    });
                                return false
                            } else {
                                return true
                            }
                        }
                    }
                },
                "active_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.active_date && obj.expiry_date){
                                return el.startDateCheck(obj.active_date, obj.expiry_date)
                            }
                            return true;
                        }
                    }
                },
                "expiry_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.active_date && obj.expiry_date){
                                return el.endDateCheck(obj.expiry_date, obj.active_date)
                            }
                            return true;
                        }
                    }
                },
            };
        },
    },
    methods:{
        ...profileMethods,
        handleTableModeUpdate(new_mode){ 
            this.tools_table_view_mode = new_mode
        },
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.active_id = row.id;
            } else {
                this.active_id = -1
            }
        },
        showToolsModal(){
            if(this.tools_table_view_mode == 'view') {
                this.show_tools_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('toolsModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "toolsModal") {
                            this.show_tools_modal = false;
                            // window.webix.$$(this.table_uid).getColumnConfig('name').collection.clearAll();
                            // window.webix.$$(this.table_uid).getColumnConfig('vendor_name').collection.clearAll();
                            // window.webix.$$(this.table_uid).getColumnConfig('functionality').collection.clearAll();
                            // this.availableNames.forEach((item)=>{
                            //     window.webix.$$(this.table_uid).getColumnConfig('name').collection.add(item);
                            // })
                            // this.availableVendors.forEach((item)=>{
                            //     window.webix.$$(this.table_uid).getColumnConfig('vendor_name').collection.add(item);
                            // })
                            // this.availableFunctionality.forEach((item)=>{
                            //     window.webix.$$(this.table_uid).getColumnConfig('functionality').collection.add(item);
                            // })
                            // window.webix.$$(this.table_uid).getColumnConfig('name').collection.refresh();
                            // window.webix.$$(this.table_uid).getColumnConfig('vendor_name').collection.refresh();
                            // window.webix.$$(this.table_uid).getColumnConfig('functionality').collection.refresh();
                            // window.webix.$$(this.table_uid).refreshColumns();
                            // window.webix.$$(this.table_uid).getColumnConfig('name').collection.refresh();
                            // window.webix.$$(this.table_uid).getColumnConfig('vendor_name').collection.refresh();
                            // window.webix.$$(this.table_uid).getColumnConfig('functionality').collection.refresh();
                            window.webix.$$(this.table_uid).refresh();
                        }
                    });
                })
            }
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        saveTable(data){
            this.is_loading = true;
            let rows_to_save = [];
            let providersArray = [];
            data.forEach( row => {
                if(!row.is_empty){
                    let client_tool = null
                    this.teams.client_tools.forEach(item => {
                        if(item.id == row.client_tool_id) {
                            client_tool = item
                        }
                    })
                    let lead = { 'user_id': row.lead_user, 'lead_user': true };
                    providersArray.push(lead);
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        level_id: this.selected_level.id,
                        client_tool_id: client_tool.id,
                        name: client_tool.name,
                        vendor_name: client_tool.vendor_name,
                        functionality: client_tool.functionality,
                        active_date: format(row.active_date, 'yyyy-MM-dd'),
                        expiry_date: format(row.expiry_date, 'yyyy-MM-dd'),

                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                communication_tools: rows_to_save,
            }
            this.is_loading = true;
            this.saveCommunicationTools(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.setOptions();
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view';
                        
                    })
                })
        },
        deleteRow(data) {
            let promises = [];
            this.is_loading = true;
            data.forEach( item => {
                promises.push(this.deleteCommunicationTools({id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.loadTeams(this.selected_level.id)
                .then(()=>{
                    this.setOptions();
                    this.is_loading = false;
                    this.$refs[this.table_uid].table_mode = 'view';
                })
			})
            .catch(()=>{
                this.is_loading = false;
            })
        },
        setOptions() {
            this.communication_tools_options = [];
            let data_array = []
            let data = this.teams.client_tools;
            if(data) {
                data.forEach(item => {
                    console.log(JSON.parse(JSON.stringify(item)))
                    data_array.push(item.name)
                })
                this.communication_tools_options = data_array
            }
        }
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
    },
    mounted(){
        this.setOptions()
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>