<template>
    <div>
        <Table
            :uid="table_uid" 
            :ref="table_uid"
            :is_loading="is_loading"
            :data="all_jobs"
            :columns="columns"
            :table_mode="table_view_mode"
            :only_editable_columns="true"
            :context_menu_items="['View', 'Edit', 'Delete']"
            :active_id="selectedJobId"
            :pager_config=8
            :validation="validation_rules"
            @updateViewMode="updateViewMode"
            @selectedRowEvent="selectedRowEvent"
            @onDataUpdate="onChangesMade"
            @saveTable="saveTable"
            @onDeleteArrayUpdate="onDeleteArrayUpdate"
            @deleteRows="deleteRow" 
        >
        <template #title>
                Company Jobs
        </template>
        </Table>
            
    </div>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'

//* misc imports
// eslint-disable-next-line
import { format, parseISO} from 'date-fns';
import Swal from 'sweetalert2';
import _ from 'lodash';

import {
    levelComputed,
    levelMethods,
    peopleMethods,
    peopleComputed,
    profileComputed,
    profileMethods
    } from '@/state/helpers'
export default {
    props:{
        company_level_id:{ required: true,}
    },
    components:{
        Table,
    },
    // watch:{

    // }
    data:() => ({
        //* Variables for the Table Component
        table_uid: 'jobsTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        changes_made_flag: false, //* true if the table component detects a change in a cell value
        is_loading: false,
        data: []
        //* ----
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        all_jobs(){
            //* returns an array of all the jobs loaded from the loadLevelJobsData store method
            //* we could map the object properties if column names differ
            if(this.companyJobs.length == 0) {
               return this.data
            } else {
                return this.companyJobs;
            }

        },
        validation_rules(){
            let el = this
            return {
                'title'(item, obj){
                    console.log(item);
                    console.log(obj)
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            let data = window.webix.$$(el.table_uid).serialize(null, true);
                            let check = false
                            data.forEach(job => {
                                console.log(job)
                                if(!job.is_empty) {
                                    if(job.title.toLowerCase() == obj.title.toLowerCase() && job != obj) {
                                    check = true
                                    }
                                }  
                            })
                            if(check) {
                                Swal.fire({
                                    title: 'Job title already in use.',
                                    icon: "warning",
                                });
                                return false
                            } else {
                                return true
                            }
                        }
                    }
                },
                //job_title: window.webix.rules.isNotEmpty,
            }
        },

        //* column config for table component
        columns(){
            //* get current scope of component because webix has its own internal scope (this) that refers to itself
            //let el = this;
            return [
                { id: 'title', header: 'Title', fillspace: 1, editor: "text",},
                { id: 'members_count', header: 'Team Members', fillspace: 0.6, css: 'centerValues'},
                { id: 'candidates_count', header: 'Candidates', fillspace: 0.6, css: 'centerValues'},
                { id: 'skills_count', header: 'Skills', fillspace: 0.3, css: 'centerValues',
                    template(obj){
                        if(obj.is_empty == undefined){
                            if(obj.skills_count == 0){
                                return `<span style="color: red;">${obj.skills_count}</span>`
                            }
                            else{
                                return obj.skills_count;
                            }
                        }
                        else{
                            return ''
                        }
                    }
                },
                { id: 'onboarding_count', header: 'Onboarding Steps', fillspace: 0.5, css: 'centerValues',
                    template(obj){
                        if(obj.is_empty == undefined){
                            if(obj.onboarding_count == 0){
                                return `<span style="color: red;">${obj.onboarding_count}</span>`
                            }
                            else{
                                return obj.onboarding_count;
                            }
                        }
                        else{
                            return ''
                        }
                    }
                },
                { id: 'offboarding_count', header: 'Offboarding Steps', fillspace: 0.5, css: 'centerValues',
                template(obj){
                        if(obj.is_empty == undefined){
                            if(obj.offboarding_count == 0){
                                return `<span style="color: red;">${obj.offboarding_count}</span>`
                            }
                            else{
                                return obj.offboarding_count;
                            }
                        }
                        else{
                            return ''
                        }
                    }
                },
            ]
        },
        selectJobId(){
            //* return the selected rows id from vuex store
            //* used to sync table selected row to the row in vuex
            return this.selectedJobId;
        },

        //* misc computed
        userList(){
            //* return a list of users with their name, email and id (the dropdown column to
            //* search users will search the value and email fields)
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        // getCompanyLevelId(){
        //     const tree = window.webix.$$('levelsTree');
        //     let item = tree.getItem(this.selected_level.id);
        //     let top_level_id = -1;
        //     if(item.parent_id === null){
        //         //* current level is company level
        //         top_level_id = item.id;
        //     }
        //     else{
        //         console.log(tree);
        //         console.log(this.selected_level.id);
        //         top_level_id = tree.getParentId(this.selected_level.id);
        //         console.log(top_level_id);
        //         let found = false;
        //         while(!found){
        //             let new_parnet_id = tree.getParentId(top_level_id);
        //             console.log(new_parnet_id)
        //             if(new_parnet_id === 0){
        //                 found = true;
        //             }
        //             else{
        //                 top_level_id = new_parnet_id;
        //             }
        //         }
        //     }
        //     return top_level_id;
        // },
        
    },
    methods:{
        ...peopleMethods,
        ...levelMethods,
        ...profileMethods,
        //* Table Component event handlers
        updateViewMode(type){
            switch(type){
                case 'delete':{
                    this.toggleDeleteMode();
                    break;
                }
                case 'edit':{
                    this.toggleEditMode();
                    break;
                }
                case 'drag':{
                    this.toggleDragMode();
                    break;
                }
                case 'view':{
                    this.toggleViewMode();
                    break;
                }
            }
        },
        toggleEditMode(){
            this.table_view_mode = 'edit';
        },
        toggleDeleteMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('delete');
            }
            else{
                this.table_view_mode = 'delete';
            }
        },
        toggleDragMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('drag')
            }
            else{
                this.table_view_mode = 'drag';
            }
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }
        },
        showDiscardPrompt(type){
            //* ask a user if they would like to cancel unsaved changes
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected items?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    
                    this.deleteRows();
                }
            });
        },
        //* table events
        selectedRowEvent(row){
            //* Event returns the selected row
            if(row) {
                if(row.id){
                    this.is_loading = true;
                    let id = Number(row.id);
                    this.setActiveJobId(id);
                    this.setSelectedJobId(id)
                    this.loadJobDetails(id)
                    .then(()=>{
                        this.is_loading = false;
                    })
                }  
            }  
        },
        onChangesMade(value){
            //* update flag when the user makes changes to the datatable
            this.changes_made_flag = value;
        },
        
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        deleteRow(){
            let promises = [];
            this.delete_array.forEach( item => {
                let job = this.all_jobs.find( job => { return item.id == job.id });
                console.log(job);
                promises.push(this.deleteJob({job_id: item.id}));
            })

            this.is_loading = true;
            Promise.all(promises)
            .then(()=>{
                this.loadCompanyJobs(this.company_level_id)
                .then(()=>{
                    this.is_loading = false;
                    this.delete_array = [];
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.toggleViewMode();
                })
            })
        },
        saveTable(){
            //* force close any open editors in the table (this commits the change to the internal wevix store)
            window.webix.$$(this.table_uid).editStop();

            this.$nextTick(()=>{
                //* Validate rows with the optional validation rules
                let table = window.webix.$$(this.table_uid);
                let result = window.webix.$$(this.table_uid).validate();
                if(result != true){
                    Swal.fire("Invalid rows found.", "", "warning")
                }
                else{
                    this.is_loading = true;
                    const all_data = table.serialize(true);
                    let rows_to_save = [];
                    all_data.forEach( row => {
                        //* Each row that contains no date (empty) has the 'is_empty' flag.
                        //* If a row is not empty, continue
                        if(!row.is_empty){
                            var params = {
                                //! IMPORTANT
                                //* each newly created row has a 'is_new'. is_new rows must have an id of -1
                                id: row.is_new ? -1 : row.id,
                                title: row.title,
                                level_id: this.company_level_id, //* return the top most hierarchy id
                            };
                            rows_to_save.push(params);
                        }
                    })
                    this.saveHiringJob(rows_to_save)
                    .then(()=>{
                        this.loadCompanyJobs(this.company_level_id)
                        .then(()=>{
                            this.is_loading = false;
                            this.changes_made_flag = false;
                            this.$refs[this.table_uid].table_mode = 'view';
                            this.toggleViewMode();
                            this.$refs[this.table_uid].table_mode = 'view';
                            //* used to refresh reports_to_user_id;. 
                            this.loadAllUsers();
                        })
                    })
                    
                    
                }
            })
            
        },
    },
    mounted(){
        this.data = this.companyJobs
        //this.resetJobTab();
    },
}
</script>

<style>
.multiline{   

  line-height: 20px !important;       
  position: relative;
  top: 24px;
    
}

.centerValues {
    text-align: center;
}
</style>